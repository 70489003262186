import {Component, OnDestroy, OnInit} from '@angular/core';
import { FileLoaderModel } from '../../file-loader/file-loader.model';
import { InputModel } from '../../input/input.model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ButtonModel } from '../../button/button.model';
import { SelectModel } from '../../select/select.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FIRST_NAME,
  LAST_NAME,
  E_MAIL,
  PHONE,
  PRESENCE,
  SUBMIT_BUTTON,
  SELECT_ACTION_JOIN,
  DEPARTMENT,
  POSITION,
  FILE_LOADER,
} from '../modal.config';
import { FormNames } from 'src/app/services/models/form.models';
import { RnplModalService } from 'src/app/services/rnpl-modal.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FormService } from '../../../services/forms.service';

@Component({
  selector: 'app-join-team',
  templateUrl: './join-team.component.html',
  styleUrls: ['./join-team.component.scss'],
})
export class JoinTeamComponent implements OnInit, OnDestroy {
  public fileLoaderConf: FileLoaderModel = FILE_LOADER;
  public first: InputModel = FIRST_NAME;
  public last: InputModel = LAST_NAME;
  public eMail: InputModel = E_MAIL;
  public tel: InputModel = PHONE;
  public socialPresence: InputModel = PRESENCE;
  public submitButton: ButtonModel = SUBMIT_BUTTON;
  public selectAction: SelectModel = SELECT_ACTION_JOIN;
  public department: SelectModel = DEPARTMENT;
  public desiredPosition: SelectModel = POSITION;

  public joinUs = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl(''),
    desiredDepartment: new FormControl('', Validators.required),
    position: new FormControl('', Validators.required),
    professionalWebPresence: new FormControl(''),
    fileLoader: new FormControl('', Validators.required),
    reCaptcha: new FormControl(''),
  });

  public formNames = FormNames;

  constructor(
    public activeModal: NgbActiveModal,
    private modal: RnplModalService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private formService: FormService,
  ) {}

  ngOnInit(): void {
    this.formService.checkOutValidForm();
  }

  public preSubmittingFormHandler(): void {
    this.formService.checkValidForm();
    this.formService.getActiveForm(FormNames.JOIN);

    this.recaptchaV3Service.execute('submitForm')
      .subscribe((token) => {
        this.captchaToken.setValue(token);
        this.submitForm();
      });
  }

  public submitForm() {
    if (this.joinUs.valid) {
      this.modal.submitFormModal(
        this.joinUs.getRawValue(),
        FormNames.JOIN,
        this.activeModal
      );
    }
  }

  ngOnDestroy() {
    this.formService.checkOutValidForm();
  }

  get firstName(): FormControl {
    return this.joinUs.get('firstName') as FormControl;
  }
  get lastName(): FormControl {
    return this.joinUs.get('lastName') as FormControl;
  }
  get email(): FormControl {
    return this.joinUs.get('email') as FormControl;
  }
  get phone(): FormControl {
    return this.joinUs.get('phone') as FormControl;
  }
  get professionalWebPresence(): FormControl {
    return this.joinUs.get('professionalWebPresence') as FormControl;
  }
  get desiredDepartment(): FormControl {
    return this.joinUs.get('desiredDepartment') as FormControl;
  }
  get position(): FormControl {
    return this.joinUs.get('position') as FormControl;
  }
  get fileLoader(): FormControl {
    return this.joinUs.get('fileLoader') as FormControl;
  }
  get captchaToken(): FormControl {
    return this.joinUs.get('reCaptcha') as FormControl;
  }
}
