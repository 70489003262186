import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { isArray } from 'rxjs/internal-compatibility';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';

import { BlogApiService } from '../services/blog-api.service';
import { BLOG_CATEGORIES_LIST_CONFIG } from '../blog-data.config';
import { CategoryModel, SelectCategoryModel } from '../../../models/blog-list.model';
import { LocaleService } from '../../../locale/locale.service';

@Component({
  selector: 'app-lexicon',
  templateUrl: './lexicon-list.component.html',
  styleUrls: ['./lexicon-list.component.scss']
})
export class LexiconListComponent implements OnInit, OnDestroy {

  public blogsCategoriesListConfig = BLOG_CATEGORIES_LIST_CONFIG;
  public activeCategories: SelectCategoryModel[] = [
    {
      id: '0',
      label: 'BLOG.ALL_CATEGORIES',
    }
  ];
  public selectedCategory: string = '0';

  public lexiconListNav: any[] = [];
  public lexiconListData: {[key: string]: any[]};
  public lexiconList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public selectedFilter: string;

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly basicCategory: SelectCategoryModel = {
    id: '0',
    label: 'BLOG.ALL_CATEGORIES',
  };

  public currentCategory: SelectCategoryModel;

  private readonly destroyed$: Subject<boolean> = new Subject();

  constructor(
    private blogApiService: BlogApiService,
    private meta: Meta,
    private router: Router,
    public localeService: LocaleService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.selectedCategory = !isPlatformServer(this.platformId)
      ? sessionStorage?.getItem('selectedCategoryLexiconList')
      : '0';
  }

  ngOnInit() {
    this.localeService.currentLocalePrefix$.subscribe(data => {
      if (data === null) {
        this.meta.updateTag({
          property: 'robots',
          content: 'noindex, nofollow'
        });
      }
    });

    this.isLoading$.next(true);

    if (!!this.selectedCategory && this.selectedCategory !== '0') {
      this.getLexiconListByCategory();
    } else {
      this.getLexiconList();
    }
  }


  public changeSelectCategories(event): void {
    this.isLoading$.next(true);
    if (!isPlatformServer(this.platformId)) {
      sessionStorage?.setItem('selectedCategoryLexiconList', event);
    }

    this.selectedCategory = event;

    if (event !== '0') {
      this.getLexiconListByCategory();
    } else {
      this.getLexiconList();
    }
  }

  public getActiveCategoriesList(category: {[key: number]: CategoryModel }): void {
    this.activeCategories = [this.basicCategory];

    for (const key in category) {
      if (category.hasOwnProperty(key)) {
        this.activeCategories = [
          ...this.activeCategories, {
            id: key,
            label: category[key].name,
            avatar: category[key].icon
          }
        ];
      }
    }
    this.currentCategory = this.activeCategories
      .filter(itm => itm.id === this.selectedCategory)[0]
      || this.basicCategory;
  }

  public getLexiconListNav(lexicon: {[key: string]: any[] }): void {
    this.lexiconListNav = [];

    for (const key in lexicon) {
      if (lexicon.hasOwnProperty(key)) {
        this.lexiconListNav = [
          ...this.lexiconListNav,
          {
            title: key,
            isOpen: true
          }
        ];
      }
    }
  }

  public prepareLexiconList(filter?: string): void {
    if (isArray(this.lexiconListData) && !this.lexiconListData.length) {
      this.router.navigate([this.localeService.rootRoute]);
    }
    let lexiconData = [];

    if (!filter) {
      for (const key in this.lexiconListData) {
        if (this.lexiconListData.hasOwnProperty(key)) {
          lexiconData = [
            ...lexiconData,
            {
              title: key,
              isOpen: false,
              data: this.lexiconListData[key]
            }
          ];
        }
      }
    } else {
      lexiconData = [{
        title: filter,
        isOpen: true,
        data: this.lexiconListData[filter]
      }];
    }

    if (lexiconData.length) {
      lexiconData[0].isOpen = true;
    }

    this.lexiconList$.next(lexiconData);
  }

  public getLexiconList(): void {
    this.blogApiService.lexiconList$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (!!response) {
          this.getActiveCategoriesList(response.category);
          this.lexiconListData = response;
          this.prepareLexiconList();
          this.getLexiconListNav(response);
          this.isLoading$.next(false);
        }
      }, () => {
        this.isLoading$.next(false);
      });
  }

  public getLexiconListByCategory(): void {
    this.blogApiService.getLexiconListByCategory(+this.selectedCategory)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.getActiveCategoriesList(response.category);
        this.lexiconListData = response;
        this.prepareLexiconList();
        this.getLexiconListNav(response);
        this.isLoading$.next(false);

        if (!!this.blogApiService.lexiconList$.value.category) {
          this.currentCategory = {
            id: this.selectedCategory,
            label: this.blogApiService.lexiconList$.value.category[this.selectedCategory].name,
            avatar: this.blogApiService.lexiconList$.value.category[this.selectedCategory].icon
          };
        }
      });
  }

  public selectFilter(filter: string): void {
    this.selectedFilter = filter;
    this.prepareLexiconList(filter);
  }

  public clearFilter(): void {
    this.selectedFilter = '';
    this.prepareLexiconList();
  }

  public openPanel(index) {
    this.lexiconList$.value[index].isOpen = !this.lexiconList$.value[index].isOpen;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.meta.removeTag('name="robots"');
  }

}
