import { Component } from '@angular/core';

@Component({
  selector: 'app-author-skeleton',
  templateUrl: './author-skeleton.component.html',
  styleUrls: ['./author-skeleton.component.scss']
})
export class AuthorSkeletonComponent {

}
