import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ACTION_CARDS } from './content-data/action-card.config';
import { RnplModalService } from 'src/app/services/rnpl-modal.service';
import { FormNames } from 'src/app/services/models/form.models';
import { MODALS } from 'src/app/services/constants';
import { BUTTONS } from './content-data/buttons.config';
import { getBusinessDirectionInfo } from './content-data/home-page.config';
import { RequestDemoModalComponent } from '../../ui-components/modal/request-demo-modal/request-demo-modal.component';
import { LocaleService } from '../../locale/locale.service';
import { RoutesEnum } from '../../locale/routes.enum';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements AfterViewInit, OnDestroy {
  public businessDirectionInfo = [];
  public actionCards = ACTION_CARDS;
  public buttons = BUTTONS;

  private modalsList = MODALS;

  public countdownTimer;
  public isDisplayNarrowScreen;

  private modalData = {
    title: 'REQUEST_DEMO.REQUEST_DEMO',
    descr: 'REQUEST_DEMO.DEMO_MODAL_DESCR'
  };

  private ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  constructor(
    private cd: ChangeDetectorRef,
    private rnplModalService: RnplModalService,
    public translate: TranslateService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private modal: RnplModalService,
    private modalService: NgbModal,
    public localeService: LocaleService,
  ) {
    // detect screen size changes
    this.breakpointObserver.observe([
      '(max-width: 481px)'
    ]).subscribe((result: BreakpointState) => {
        this.isDisplayNarrowScreen = result.matches;
    });

    this.localeService.currentLocalePrefix$.subscribe(() => {
      this.businessDirectionInfo = getBusinessDirectionInfo(
        this.localeService.getLink(RoutesEnum.RETAIL),
        this.localeService.getLink(RoutesEnum.TRADE),
        this.localeService.getLink(RoutesEnum.ECOMMERCE),
        this.localeService.getLink(RoutesEnum.SERVICES),
      );
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
    const windowObj: any = window.window;
    const trustboxRef = document.getElementById('trustboxHome');

    windowObj?.Trustpilot?.loadFromElement(trustboxRef);
  }

  ngOnDestroy() {
    clearInterval(this.countdownTimer);
  }

  public openAskQuestionModal() {
    this.rnplModalService.openContactUsModal(
      this.modalsList,
      FormNames.QUESTION
    );
  }

  public openEarlyAccessModal() {
    this.rnplModalService.openContactUsModal(
      this.modalsList,
      FormNames.EARLY_ACCESS
    );
  }

  public gotToGetStarted() {
    this.router.navigate([this.localeService.getLink(RoutesEnum.GET_STARTED)]);
  }

  public openRequestDemoModal(): void {
    this.modal.setModalData(this.modalData);
    this.modalService.open(RequestDemoModalComponent, this.ngbModalOptions);
  }
}
