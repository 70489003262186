import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormService } from '../../services/forms.service';

@Component({
  selector: 'rnpl-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent {
  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public control: FormControl;

  @Input()
  public formName: string;

  constructor(public formService: FormService) {
  }

  get checkedValidControl(): boolean {
    return (this.formService.activeForm === this.formName && this.formService.isCheckValidForm);
  }
}
