import { Component, OnInit } from '@angular/core';
import { ButtonColor, ButtonModel } from '../../button/button.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RequestDemoModalComponent } from '../../modal/request-demo-modal/request-demo-modal.component';
import { RnplModalService } from '../../../services/rnpl-modal.service';

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.scss']
})
export class RequestDemoComponent implements OnInit {

  private modalData = {
    title: 'REQUEST_DEMO.REQUEST_DEMO',
    descr: 'REQUEST_DEMO.DEMO_MODAL_DESCR'
  };

  private ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  public button: ButtonModel = {
    title: 'REQUEST_DEMO.REQUEST_DEMO',
    iconName: 'phone-call',
    styles: {
      height: 48,
      bgColor: ButtonColor.WHITE_BLUE,
      textColor: ButtonColor.BLUE
    }
  };

constructor(
  private modalService: NgbModal,
  private modal: RnplModalService,
) { }

  ngOnInit(): void {
  }

  public openRequestDemoModal(): void {
    this.modal.setModalData(this.modalData);
    this.modalService.open(RequestDemoModalComponent, this.ngbModalOptions);
  }

}
