<div class="info-block-container">
  <ul class="info-block-list">
    <li *ngFor="let item of topInfoBlock" class="info-block-item">

      <img src="{{item?.img | imagesByLocale: 'home'}}" [alt]="item?.alt | translate">
      <span class="description" [innerText]="item?.description | translate"></span>
      <span class="description device-375" [innerText]="item?.description375 | translate"></span>
    </li>
  </ul>
</div>
