<div
  class="select-wrapper"
  [ngClass]="styles?.type ? styles?.type : ''"
  [class.select-icon]="!!labelIcon"
  [class.disabled]="disabled"
  [class.with-label]="!!labelText && styles?.type === 'white'"
  [class.select-lang]="selectLangType"
  [class.opened]="select.isOpen"
>
  <div *ngIf="!!labelIcon" class="select-icon">
    <i class="icon icon-{{ labelIcon }}"></i>
  </div>

  <div class="select-box">
    <label class="select-box-label" *ngIf="labelText" [for]="labelText">{{ labelText | translate }}</label>
    <ng-select
      #select
      [dropdownPosition]="'auto'"
      [items]="selectItems"
      [(ngModel)]="selectedItem"
      [searchable]="searchable"
      [labelForId]="labelText"
      [virtualScroll]="true"
      (change)="onSelectChange($event)"
      [class.with-label-box]="!!labelText"
      [disabled]="disabled"
      [placeholder]="placeholder | translate"
    >
      <ng-template ng-label-tmp let-item="item">
        <div class="d-flex align-items-center">
          <img *ngIf="item.avatar" [src]="item.avatar" alt="" />
          <i *ngIf="item.icon" class="icon icon-{{item.icon}} mr-8"></i>
          <span>{{ item.label | translate }}</span>
        </div>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index">
        <img *ngIf="item.avatar" [src]="item.avatar" alt=""/>
        <i *ngIf="item.icon" class="icon icon-{{item.icon}} mr-8"></i>
        <span>{{ item.label | translate }}</span>
      </ng-template>
    </ng-select>
  </div>
</div>
