<div class="page">
  <div class="page-item page-text page-text-top">
    <h1 *ngIf="!isDisplayNarrowScreen" class="page-text-title hyphens-none" [lang]="translate.currentLang" [innerText]="'HOME.MAIN_TITLE' | translate"></h1>
    <h1 *ngIf="isDisplayNarrowScreen" class="page-text-title hyphens-none" [lang]="translate.currentLang" [innerText]="'HOME.MAIN_TITLE_375' | translate"></h1>

<!--    <div class="button-container">-->
<!--      <rnpl-button-->
<!--        [title]="buttons[3].title"-->
<!--        [styles]="buttons[3].styles"-->
<!--        [iconName]="buttons[3].iconName"-->
<!--        (click)="gotToGetStarted()">-->
<!--      </rnpl-button>-->

<!--      <rnpl-button-->
<!--        [title]="buttons[4].title"-->
<!--        [styles]="buttons[4].styles"-->
<!--        [iconName]="buttons[4].iconName"-->
<!--        (click)="openRequestDemoModal()">-->
<!--      </rnpl-button>-->
<!--    </div>-->
  </div>

  <div class="page-item">
    <rnpl-our-certificate></rnpl-our-certificate>
  </div>
<!--  <div class="page-slider">-->
<!--    <app-slider-carousel [selectorName]="'carousel-home'"></app-slider-carousel>-->
<!--  </div>-->

<!--  <app-detail-info-block></app-detail-info-block>-->

<!--  <div *ngIf="!isDisplayNarrowScreen" class="page-item">-->
<!--    <app-info-cards-block></app-info-cards-block>-->
<!--  </div>-->

<!--  <div class="page-item">-->
<!--    <h2 *ngIf="!isDisplayNarrowScreen" class="page-text-title" [innerText]="'HOME.BUILT_IN' | translate"></h2>-->
<!--    <h2 *ngIf="isDisplayNarrowScreen" class="page-text-title" [innerText]="'HOME.BUILT_IN_375' | translate"></h2>-->

<!--    <ul class="info-block-list business-direction">-->
<!--      <li *ngFor="let item of businessDirectionInfo"-->
<!--          class="info-block-item"-->
<!--          [ngClass]="item?.class"-->
<!--          [class.disabled]="item?.disabled"-->
<!--      >-->
<!--        <div class="img-container">-->
<!--          <img *ngIf="!!item?.backgroundImage"-->
<!--               src="{{item?.backgroundImage| imagesByLocale: 'home'}}" [alt]="item?.alt | translate" width="268" height="419">-->
<!--        </div>-->

<!--        <div class="content">-->
<!--          <h6 class="title">{{item?.title | translate}}</h6>-->
<!--          <span class="content-link">-->
<!--            {{ 'HOME.DISCOVER_MORE' | translate }}-->
<!--            <i class="icon icon-arrow-right-small"></i>-->
<!--          </span>-->
<!--        </div>-->

<!--        <rnpl-info-rectangle *ngIf="!!item?.infoRectangleData"-->
<!--                             [config]="item.infoRectangleData">-->
<!--        </rnpl-info-rectangle>-->

<!--        <a *ngIf="!!item?.link && !item?.disabled" [routerLink]="item?.link" [attr.aria-label]="item?.title" class="link"></a>-->

<!--      </li>-->
<!--    </ul>-->
<!--  </div>-->

  <app-channels-block></app-channels-block>

<!--  <div class="page-item">-->
<!--    <app-review-us-block>-->
<!--      <div trustbox class="trustbox">-->
        <!-- TrustBox widget - Micro Review Count -->
<!--        <div id="trustboxHome"-->
<!--             class="trustpilot-widget"-->
<!--             data-locale="de-AT"-->
<!--             data-template-id="5419b6a8b0d04a076446a9ad"-->
<!--             data-businessunit-id="618a84591f7e0196fd74d8d5"-->
<!--             data-style-height="24px"-->
<!--             data-style-width="100%"-->
<!--             [attr.data-theme]="'light'"-->
<!--             data-min-review-count="10"-->
<!--        >-->
<!--          <a href="https://at.trustpilot.com/review/runple.at" target="_blank" rel="noopener">Trustpilot</a>-->
<!--        </div>-->
        <!-- End TrustBox widget -->
<!--      </div>-->
<!--    </app-review-us-block>-->
<!---->
<!--  </div>-->

  <div class="page-item">
    <rnpl-our-kunden></rnpl-our-kunden>
  </div>

<!--  <div class="page-item start-steps">-->

<!--    <h2 *ngIf="!isDisplayNarrowScreen" class="page-text-title" [innerText]="'HOME.GET_STARTED_WITH_RUNPLE' | translate"></h2>-->
<!--    <h2 *ngIf="isDisplayNarrowScreen" class="page-text-title" [innerText]="'HOME.GET_STARTED_WITH_RUNPLE_325' | translate"></h2>-->

<!--    <app-start-steps-block class="mb-24"></app-start-steps-block>-->

<!--    <rnpl-early-access-banner [title]="'CTA.FINAL_CTA' | translate"></rnpl-early-access-banner>-->
<!--  </div>-->

</div>
