import { NavItemsModels } from './nav-Items.models';

export function navItemsData(
  accountingRouterLink: string,
  warehouseRouterLink: string,
  productsRouterLink: string,
  partnersRouterLink: string,
  teamRouterLink: string,
  analyticsRouterLink: string,
  systemRouterLink: string,
  priceRouterLink: string,
  aboutRouterLink: string,
  homeRouterLink: string,
): Array<NavItemsModels> {
  return [
    // {
    //   title: 'NAV.FEATURES',
    //   children: [
    //     {
    //       title: 'NAV.ACCOUNTING',
    //       icon: 'calculator',
    //       iconColor: 'violet',
    //       routerLink: accountingRouterLink
    //     },
    //     {
    //       title: 'NAV.WAREHOUSE',
    //       icon: 'warehouse',
    //       iconColor: 'orange',
    //       routerLink: warehouseRouterLink,
    //     },
    //     {
    //       title: 'NAV.PRODUCTS',
    //       icon: 'box',
    //       iconColor: 'purple',
    //       routerLink: productsRouterLink,
    //     },
    //     {
    //       title: 'NAV.PARTNERS',
    //       icon: 'heart',
    //       iconColor: 'brown',
    //       routerLink: partnersRouterLink,
    //     },
    //     {
    //       title: 'NAV.TEAM',
    //       icon: 'people',
    //       iconColor: 'sky',
    //       routerLink: teamRouterLink,
    //     },
    //     {
    //       title: 'NAV.ANALYTICS',
    //       icon: 'business-intelligence',
    //       iconColor: 'mint',
    //       routerLink: analyticsRouterLink,
    //     },
    //     {
    //       title: 'NAV.SYSTEM',
    //       img: 'runple-logo-white.svg',
    //       iconColor: 'black',
    //       imgHover: 'runple-logo-blue.svg',
    //       routerLink: systemRouterLink,
    //     },
    //   ],
    // },
    {
      title: 'NAV.HOME',
      routerLink: homeRouterLink,
    },
    {
      title: 'NAV.ABOUT_RUNPLE',
      routerLink: aboutRouterLink,
    },
    {
      title: 'Teamviewer Windows',
      href: 'https://download.teamviewer.com/download/TeamViewerQS_x64.exe',
    },
    {
      title: 'Teamviewer Mac',
      href: 'https://download.teamviewer.com/download/TeamViewerQS.dmg',
    },
  ];
}

export function getSalesChannelList(
  tradeRouterLink: string,
  servicesRouterLink: string,
  ecoRouterLink: string,
  retailRouterLink: string,
): Array<NavItemsModels> {
  return [
    {
      title: 'HEADER.TRADE',
      descr: 'HEADER.TRADE_DESCR',
      icon: 'cash',
      iconColor: 'yellow',
      routerLink: tradeRouterLink,
      isSaleChannel: true,
    },
    {
      title: 'PRODUCTS.SERVICES',
      descr: 'HEADER.SERVICES_DESCR',
      icon: 'checklist',
      iconColor: 'green',
      routerLink: servicesRouterLink,
      isSaleChannel: true,
    },
    {
      title: 'HOME.E_COMMERCE',
      descr: 'HEADER.E_COMMERCE_DECR',
      icon: 'shopping-cart',
      iconColor: 'red',
      routerLink: ecoRouterLink,
      isSaleChannel: true,
    },
    {
      title: 'NAV.RETAIL',
      descr: 'HEADER.RETAIL_DESCR',
      icon: 'shop',
      iconColor: 'blue',
      routerLink: retailRouterLink,
      label: 'HOME.AVAILABLE_IN_2023',
      isSaleChannel: true,
    },
  ];
}

export function getAccountingList(
  accountingRouterLink: string,
  warehouseRouterLink: string,
  analyticsRouterLink: string,
): Array<NavItemsModels> {
  return [
    {
      title: 'NAV.ACCOUNTING',
      icon: 'calculator',
      iconColor: 'violet',
      descr: 'HEADER.ACCOUNTING_DESCR',
      routerLink: accountingRouterLink,
    },
    {
      title: 'NAV.WAREHOUSE',
      icon: 'warehouse',
      iconColor: 'orange',
      descr: 'HEADER.WAREHOUSE_DESCR',
      routerLink: warehouseRouterLink,
    },
    {
      title: 'HEADER.ANALYTICS',
      icon: 'business-intelligence',
      iconColor: 'mint',
      descr: 'HEADER.ANALYTICS_DESCR',
      routerLink: analyticsRouterLink,
    },
  ];
}

export function getManagementList(
  productsRouterLink: string,
  partnersRouterLink: string,
  teamRouterLink: string,
): Array<NavItemsModels> {
  return [
    {
      title: 'NAV.PRODUCTS',
      icon: 'box',
      iconColor: 'purple',
      descr: 'HEADER.PRODUCTS_DESCR',
      routerLink: productsRouterLink,
    },
    {
      title: 'HEADER.PARTNERS',
      icon: 'heart',
      iconColor: 'brown',
      descr: 'HEADER.PARTNERS_DESCR',
      routerLink: partnersRouterLink,
    },
    {
      title: 'NAV.TEAM',
      icon: 'people',
      iconColor: 'sky',
      descr: 'HEADER.TEAM_DESCR',
      routerLink: teamRouterLink,
    },
  ];
}


export function getSubMenuItems(
  tradeRouterLink: string,
  servicesRouterLink: string,
  ecoRouterLink: string,
  retailRouterLink: string,
  accountingRouterLink: string,
  warehouseRouterLink: string,
  analyticsRouterLink: string,
  productsRouterLink: string,
  partnersRouterLink: string,
  teamRouterLink: string,
): Array<NavItemsModels> {
  return [
    {
      title: 'PRICING.DISTRIBUTION_CHANNELS',
      children: getSalesChannelList(
        tradeRouterLink,
        servicesRouterLink,
        ecoRouterLink,
        retailRouterLink,
      )
    },
    {
      title: 'Accounting',
      children: getAccountingList(
        accountingRouterLink,
        warehouseRouterLink,
        analyticsRouterLink,
      )
    },
    {
      title: 'FORM.MANAGEMENT',
      children: getManagementList(
        productsRouterLink,
        partnersRouterLink,
        teamRouterLink,
      )
    },
  ];
}
