<div class="input-wrapper">
  <div *ngIf="!!label && !hideLabel" class="label-wrapper">
    <label>{{ label | translate }}</label>
    <p *ngIf="isOptional" class="optional">
      {{ "INPUT.OPTIONAL" | translate }}
    </p>
  </div>
  <div [ngClass]="{ tail: domenName }" class="input-container">
    <input
      #input
      [ngClass]="{ withtail: domenName }"
      [class.error]="
        control?.invalid &&
        ((control?.touched && control?.dirty) || checkedValidControl) &&
        ((!control?.errors?.email &&
        !form?.errors?.validateConfirmPassword) || form?.errors?.validateConfirmPassword || control?.errors?.email)"
      [class.has-value]="!!input.value"
      placeholder="{{ placeholder | translate }}"
      [type]="type === 'password' ? showPassword ? 'text' : 'password' :  type"
      [formControl]="control"
      mask="{{ inputMask }}"
    />

    <div *ngIf="domenName" class="domen-name">{{ domenName }}</div>

    <div
      class="error-message"
      *ngIf="
      control?.invalid &&
      ((control?.touched && control?.dirty) || checkedValidControl) &&
      !form?.errors?.validateConfirmPassword &&
      !control?.errors?.email">
      <i class="icon icon-alert-circle"></i>
      <span>
      {{
        (control?.errors?.error && control?.errors?.error[0])
          ? control?.errors?.error[0] :
          emptyControlError ?
            emptyControlError
            : ('INPUT.ERROR_DEFAULT' | translate: {attribute: (label | translate)})
        }}
    </span>
    </div>

    <div
      class="error-message confirm-password"
      *ngIf="((control?.touched && control?.dirty) || checkedValidControl) && form?.errors?.validateConfirmPassword">
      <i class="icon icon-alert-circle"></i>
      <span> {{ errorConfirmdMessage | translate }} </span>

    </div>

    <div class="error-message" *ngIf="((control?.touched && control?.dirty) || checkedValidControl) && control?.errors?.email">
      <i class="icon icon-alert-circle"></i>
      <span> {{ emailError | translate }} </span>
    </div>

    <span class="icon toggle-password"
          *ngIf="showPasswordIcon"
          [ngClass]="{'icon-eye': !showPassword, 'icon-eye-off': showPassword}"
          (click)="toggleShowPassword()"></span>

    <i *ngIf="control?.valid && !!control?.value && !form?.errors?.validateConfirmPassword && !control?.errors?.email && validIcon"
       class="icon icon-checkmark-circle"></i>

  </div>

</div>
