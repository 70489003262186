import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { isArray } from 'rxjs/internal-compatibility';

import { getFooterNavItems, getLegalInfoList } from './nav-items.config';
import { SELECT_REGION_CONFIG } from './selects.config';
import { NavItemsModels } from '../header/nav-Items.models';
import { MODALS } from 'src/app/services/constants';
import { AppService } from 'src/app/app.service';
import { AvailableCountryISOEnum } from 'src/app/enums/language.enum';
import { SelectItemModel } from '../select/select.model';
import { BlogApiService } from '../../pages/blog/services/blog-api.service';
import { LocaleService } from '../../locale/locale.service';
import { RoutesEnum } from '../../locale/routes.enum';
import { RnplModalService } from '../../services/rnpl-modal.service';
import { FormNames } from '../../services/models/form.models';
import { LocalePrefixesEnum } from '../../locale/locale-prefixes.enum';

@Component({
  selector: 'rnpl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  public navItems: Array<NavItemsModels> = [];
  public selectRegionConfig = SELECT_REGION_CONFIG;
  // public selectLangConfig = SELECT_LANG_CONFIG;
  public legalNavList = [];
  public currentYear = new Date().getFullYear();
  public isOpen: boolean;

  readonly selectedRegionValue$: BehaviorSubject<SelectItemModel>
    = new BehaviorSubject<SelectItemModel>(this.selectRegionConfig.selectItems[0]);

  // readonly selectedLanguage$: BehaviorSubject<SelectItemModel>
  //   = new BehaviorSubject<SelectItemModel>(this.selectLangConfig.selectItems[0]);

  public selectedItem: string = '';

  public hasPosts: boolean;
  public hasLexicons: boolean;

  private modalsList = MODALS;

  private readonly destroyed$: Subject<boolean> = new Subject();

  constructor(
    private rnplModalService: RnplModalService,
    private appService: AppService,
    public translate: TranslateService,
    private blogApeService: BlogApiService,
    public localeService: LocaleService,
  ) {
    this.localeService.currentLocalePrefix$.subscribe((prefix: LocalePrefixesEnum) => {
      this.getNavItems();
      this.legalNavList = getLegalInfoList(
        this.localeService.getLink(RoutesEnum.LEGAL),
        this.localeService.getLink(RoutesEnum.TERMS_OF_SERVICE),
        this.localeService.getLink(RoutesEnum.PRIVACY_POLICY),
        this.localeService.getLink(RoutesEnum.COOKIE_POLICY),
      );

      prefix
        ? this.setRegion(AvailableCountryISOEnum[prefix?.toUpperCase()])
        : this.setRegion(AvailableCountryISOEnum.OTHER);
    });
    // this.setLanguage();
  }

  ngOnInit() {
    this.getUserInfo();
    this.blogApeService.articleList$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(data => {
        if (!!data) {
          this.hasPosts = !!data.posts && !!data.posts.length;
          this.getNavItems();
        }
      });

    this.blogApeService.lexiconList$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(data => {

        if (isArray(data) && !data.length) {
          this.hasLexicons = false;
        }

        for (const key in data) {
          if (data.hasOwnProperty(key) && data[key].length && key !== 'category') {
            this.hasLexicons = true;
          }
        }

        this.getNavItems();
      });
  }

  private getNavItems(): void {
    this.navItems = getFooterNavItems(
      !this.hasPosts,
      !this.hasLexicons,
      this.localeService.getLink(RoutesEnum.TRADE),
      this.localeService.getLink(RoutesEnum.SERVICES),
      this.localeService.getLink(RoutesEnum.ECOMMERCE),
      this.localeService.getLink(RoutesEnum.RETAIL),
      this.localeService.getLink(RoutesEnum.ACCOUNTING),
      this.localeService.getLink(RoutesEnum.WAREHOUSE),
      this.localeService.getLink(RoutesEnum.PRODUCTS),
      this.localeService.getLink(RoutesEnum.PARTNERS),
      this.localeService.getLink(RoutesEnum.TEAM),
      this.localeService.getLink(RoutesEnum.ANALYTICS),
      this.localeService.getLink(RoutesEnum.PRICING),
      this.localeService.getLink(RoutesEnum.GET_STARTED),
      this.localeService.getLink(RoutesEnum.LEXICON),
      this.localeService.getLink(RoutesEnum.ABOUT),
      this.localeService.getLink(RoutesEnum.BLOG),
      this.localeService.getLink(RoutesEnum.LEGAL),
      this.localeService.getLink(RoutesEnum.TERMS_OF_SERVICE),
      this.localeService.getLink(RoutesEnum.PRIVACY_POLICY),
      this.localeService.getLink(RoutesEnum.COOKIE_POLICY),
    );
  }

  public openSubMenu(item: NavItemsModels): void {
    if (item.children) {
      if (item.title !== this.selectedItem) {
        this.selectedItem = item.title;
        this.isOpen = true;
      } else {
        this.isOpen = !this.isOpen;
      }
    } else {
      this.isOpen = false;
    }
  }

  public onClick(funcName) {
    if (this[funcName]) {
      this[funcName]();
    }
  }

  private openAskQuestionModal() {
    this.rnplModalService.openContactUsModal(
      this.modalsList,
      FormNames.QUESTION
    );
  }

  private getUserInfo(): void {
    // this.appService.isLoading$
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe(() => {
    //     // if (this.appService.currentCountryIso) {
    //     //   this.setRegion(AvailableCountryISOEnum[this.appService.currentCountryIso] || AvailableCountryISOEnum.OTHER);
    //     // }
    //     // this.setLanguage();
    //   });

    // this.appService.getUserInfo()
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe(response => {
    //     if (response && response.isoCode) {
    //       this.setRegion(AvailableCountryISOEnum[response.isoCode] || AvailableCountryISOEnum.OTHER);
    //     }
    //     this.setLanguage();
    //   });
  }

  private setRegion(isoCode: AvailableCountryISOEnum): void {
    const newRegion = this.selectRegionConfig.selectItems.find((item) => item.isoCode === isoCode);
    if (newRegion) {
      this.selectedRegionValue$.next(newRegion);
    }
  }

  public changeRegion(e): void {
    if (e && e.isoCode && (<any>Object).values(LocalePrefixesEnum).includes(e.isoCode.toLowerCase())) {
      this.localeService.changeLocale(e.isoCode.toLowerCase());
    } else {
      this.localeService.changeLocale();
    }
  }

  // public changeLanguage(lang) {
  //   if (lang === 'Deutsch') {
  //     this.translate.use('de');
  //     localStorage.setItem('lang', 'de');
  //   } else {
  //     this.translate.use('en');
  //     localStorage.setItem('lang', 'en');
  //   }
  // }

  // private setLanguage() {
  //   const localStorageLang = localStorage.getItem('lang');
  //
  //   if (localStorageLang === 'de') {
  //     this.selectedLanguage$.next(this.selectLangConfig.selectItems[1]);
  //   } else {
  //     this.selectedLanguage$.next(this.selectLangConfig.selectItems[0]);
  //   }
  // }

  public closeMenu(): void {
    this.isOpen = false;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
