<div class="loader-wrapper">
  <div class="loader-label">{{ label | translate }}</div>
  <label
    class="loader"
    [ngClass]="{ error: errorMessage }"
    rnplDnd
    (fileDropped)="onFileDropped($event)">
    <div class="loader-icon">
      <i class="icon icon-{{ icon }}"></i>
    </div>
    <div class="loader-description">{{ description | translate }}</div>
    <input
      type="file"
      multiple
      (change)="fileBrowseHandler($event.target.files)"/>
  </label>
  <div *ngIf="errorMessage" class="error">{{ errorMessage | translate }}</div>
<!--  <div *ngIf="!errorMessage" class="format">{{ format | translate }}</div>-->
  <div class="file-list">
    <div class="file-container" *ngFor="let file of files; let i = index">
      <div class="delete" (click)="deleteFile($event, i)">
        <i class="icon icon-trash-2"></i>
      </div>
      <div class="file-icon">
        <i class="icon icon-file-text"></i>
      </div>
      <p class="file-name mb-0">{{ file?.name }}</p>
    </div>
  </div>
</div>
