import { Component, HostListener, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';

import { ButtonColor, ButtonStylesModel } from '../button/button.model';
import { getSubMenuItems, navItemsData } from './nav-items.config';
import { NavItemsModels } from './nav-Items.models';
import { FormNames } from '../../services/models/form.models';
import { RnplModalService } from '../../services/rnpl-modal.service';
import { MODALS } from '../../services/constants';
import { StickyAnchorService } from '../../services/sticky-anchor.service';
import { LocaleService } from '../../locale/locale.service';
import { LocalePrefixesEnum } from '../../locale/locale-prefixes.enum';
import { RoutesEnum } from '../../locale/routes.enum';

@Component({
  selector: 'rnpl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public isHide: boolean = false;
  public isOpenNav: boolean = false;
  public hideMenu: boolean = false;
  public selectedItem: string = '';
  public navItems: Array<NavItemsModels> = [];
  public subMenuItems: Array<NavItemsModels> = [];
  public isScrolled: boolean = false;
  public isTablet: boolean;
  public isOpenSubMenu: boolean;
  public isDisplayStickyAnchor: boolean;
  public routesEnum = RoutesEnum;

  public buttonConfig: ButtonStylesModel = {
    height: 48,
  };
  public buttonConfigNav: ButtonStylesModel = {
    height: 64,
  };

  public buttonToggleConfig: ButtonStylesModel = {
    height: 48,
    width: 48,
    bgColor: ButtonColor.WHITE,
    iconCenter: true,
    iconColor: ButtonColor.BLACK,
  };

  private modalsList = MODALS;

  constructor(
    private rnplModalService: RnplModalService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    public stickyAnchorService: StickyAnchorService,
    public localeService: LocaleService,
  ) {
    this.localeService.currentLocalePrefix$.subscribe((locale: LocalePrefixesEnum) => {
      this.navItems = navItemsData(
        this.localeService.getLink(RoutesEnum.ACCOUNTING),
        this.localeService.getLink(RoutesEnum.WAREHOUSE),
        this.localeService.getLink(RoutesEnum.PRODUCTS),
        this.localeService.getLink(RoutesEnum.PARTNERS),
        this.localeService.getLink(RoutesEnum.TEAM),
        this.localeService.getLink(RoutesEnum.ANALYTICS),
        this.localeService.getLink(RoutesEnum.SYSTEM),
        this.localeService.getLink(RoutesEnum.PRICING),
        this.localeService.getLink(RoutesEnum.ABOUT),
        this.localeService.getLink(RoutesEnum.HOME),
      );

      this.subMenuItems = getSubMenuItems(
        this.localeService.getLink(RoutesEnum.TRADE),
        this.localeService.getLink(RoutesEnum.SERVICES),
        this.localeService.getLink(RoutesEnum.ECOMMERCE),
        this.localeService.getLink(RoutesEnum.RETAIL),
        this.localeService.getLink(RoutesEnum.ACCOUNTING),
        this.localeService.getLink(RoutesEnum.WAREHOUSE),
        this.localeService.getLink(RoutesEnum.ANALYTICS),
        this.localeService.getLink(RoutesEnum.PRODUCTS),
        this.localeService.getLink(RoutesEnum.PARTNERS),
        this.localeService.getLink(RoutesEnum.TEAM),
      );
    });
  }

  @HostListener('document:scroll', ['$event'])
  trackScrollHandler() {
    this.isScrolled = !!window?.scrollY;
    // this.isDisplayStickyAnchor = this.stickyAnchorService.displayAnchorSticky;
  }

  ngOnInit(): void {
    this.isDisplayStickyAnchor = false;
    this.isTablet = this.deviceService.isTablet();
  }

  public openSubMenu(item: NavItemsModels): void {
    if (item.children) {
      this.selectedItem = item.title;
      this.isHide = !this.isHide;
    }
  }

  public openMenu(): void {
    this.isOpenNav = !this.isOpenNav;
    if (this.isOpenNav) {
      this.isHide = false;
    }
    this.displayMenuHandler();
  }

  public closeMenu(event?): void {
    this.isOpenNav = false;
    if (!!event) {
      event.stopPropagation();
    }
  }

  // public closeSubMenu(): void {
  //   this.isHide = true;
  // }

  public hideMenuHandler(): void {
    this.hideMenu = true;
    this.closeMenu();
  }


  public displayMenuHandler(): void {
    this.hideMenu = false;
  }

  public openEarlyAccessModal() {
    this.rnplModalService.openContactUsModal (
      this.modalsList,
      FormNames.EARLY_ACCESS,
    );
  }

  public openSubMenuHandler(): void {
    this.isOpenSubMenu = true;
  }

  public closeSubMenuHandler(): void {
    this.isOpenSubMenu = false;
  }

  get displayButtonTryForFree(): boolean {
    return this.router.url !== this.localeService.getLink(RoutesEnum.GET_STARTED);
  }

  openAskQuestionModal() {
      this.rnplModalService.openContactUsModal(
        this.modalsList,
        FormNames.QUESTION
      );
  }
}
