import { Component } from '@angular/core';

import { PartnersLogoModel } from './our-kunden.model';

@Component({
  selector: 'rnpl-our-kunden',
  templateUrl: './our-kunden.component.html',
  styleUrls: ['./our-kunden.component.scss']
})

export class OurKundenComponent {

  public items: Array <PartnersLogoModel> = [
    {
      logo: 'Gmundner Keramik.png',
      url: 'https://www.gmundner.at/',
      name: 'Gmundner Keramik'
    },
    {
      logo: 'Amnesty International.png',
      url: 'https://www.amnesty.at/',
      name: 'Amnesty International'
    },
    {
      logo: 'Heubach.png',
      url: 'https://www.heubach.com/',
      name: 'Heubach'
    },
    {
      logo: 'POV.png',
      url: 'https://www.pov.at/',
      name: 'POV'
    },
    {
      logo: 'AP Diskont.png',
      url: 'https://www.diskont.at/',
      name: 'AP Diskont'
    },
    {
      logo: 'Mag. Aleksander Hofstaetter.png',
      url: 'https://www.hofstaetter.at/',
      name: 'Mag. Aleksander Hofstaetter'
    },
    {
      logo: 'Mondsee Treuhand.png',
      url: 'https://www.mondsee-treuhand.at/',
      name: 'Mondsee Treuhand'
    },
    {
      logo: 'Zobl.Bauer.png',
      url: 'https://www.zobl-bauer.at/',
      name: 'Zobl.Bauer'
    },
    {
      logo: 'Juwelier Holzhammer.png',
      url: 'https://www.juwelier-holzhammer.eu/',
      name: 'Juwelier Holzhammer'
    },
    {
      logo: 'MeÃner und Kunst Steuerberatung.png',
      url: 'https://mk-steuerberatung.at/',
      name: 'MeÃner und Kunst Steuerberatung'
    },
    {
      logo: 'Tekpoint.png',
      url: 'https://tekpoint.com/',
      name: 'Tekpoint'
    },
    {
      logo: 'Wladika.png',
      url: 'https://www.wladika.at/',
      name: 'Wladika'
    },
    {
      logo: 'Wolfgangsee Treuhand.png',
      url: 'https://www.wolfgangsee.or.at/',
      name: 'Wolfgangsee Treuhand'
    },
    {
      logo: 'DDr. Astrid Schroecker.png',
      url: 'https://www.ddr-schroecker.at/',
      name: 'DDr. Astrid Schroecker'
    },
    {
      logo: 'Tax Solution.png',
      url: 'https://www.taxsolution.at/',
      name: 'Tax Solution'
    },
    {
      logo: 'Autriche.png',
      url: 'https://www.autriche.com/',
      name: 'Autriche'
    },
    {
      logo: 'Cashfinder.png',
      url: 'https://www.cashfinder-group.com/',
      name: 'Cashfinder'
    },
    {
      logo: 'Bano.png',
      url: 'https://www.bano.at/de/',
      name: 'Bano'
    },
    {
      logo: 'Winzerhof Fam. Dockner.png',
      url: 'https://www.dockner.at/',
      name: 'Winzerhof Fam. Dockner'
    },
    {
      logo: 'Primas Consulting.png',
      url: 'https://www.primas.at/',
      name: 'Primas Consulting'
    },
    {
      logo: 'CARE.png',
      url: 'https://www.care.at/',
      name: 'CARE'
    },
    {
      logo: 'Aerzte ohne Grenzen.png',
      url: 'https://www.aerzte-ohne-grenzen.at/',
      name: 'Aerzte ohne Grenzen'
    },
    {
      logo: 'Zach Antriebe.png',
      url: 'https://www.zachantriebe.at/',
      name: 'Zach Antriebe'
    },
    {
      logo: 'GSV Tax Steuerberatung.png',
      url: 'http://www.vib-steuerberatung.at/',
      name: 'GSV Tax Steuerberatung'
    },
    {
      logo: 'Roxin.png',
      url: 'https://www.roxin.at/',
      name: 'Roxin'
    },
    {
      logo: 'Kinner Korenjak.png',
      url: 'https://www.kinner-korenjak.law/',
      name: 'Kinner Korenjak'
    },
    {
      logo: 'Alignum.png',
      url: 'https://www.alignum.com/',
      name: 'Alignum'
    },
    {
      logo: 'Hotel Ambassador Wien.png',
      url: 'https://www.ambassador.at/',
      name: 'Hotel Ambassador Wien'
    },
    {
      logo: 'Fruhwuerth Steuerberaterin.png',
      url: 'https://www.fruhwuerth.at/',
      name: 'Fruhwuerth Steuerberaterin'
    },
    {
      logo: 'Schulmeister Consulting.png',
      url: 'https://www.schulmeister-consulting.com/',
      name: 'Schulmeister Consulting'
    },
    {
      logo: 'Knoll Logistik.png',
      url: 'https://www.knoll-logistik.at/',
      name: 'Knoll Logistik'
    },
    {
      logo: 'Kanzlei Friedrich.png',
      url: 'https://www.kanzlei-friedrich.at/',
      name: 'Kanzlei Friedrich'
    },
    {
      logo: 'FRAPAG.png',
      url: 'https://www.frapag.com/',
      name: 'FRAPAG'
    },
    {
      logo: 'Doll.png',
      url: 'https://www.doll-salzburg.at/',
      name: 'Doll Kunstgärt nerei Salzburg'
    },
    {
      logo: 'STB Hofer.png',
      url: 'https://www.stb-hofer.at/',
      name: 'STB Hofer'
    },
    {
      logo: 'B4C Consulting.png',
      url: 'https://www.b4cconsulting.com/',
      name: 'B4C Consulting'
    },
  ];

}
