<h2 class="info-blocks-title" [innerText]="'HOME.PROBLEM_TITLE' | translate"></h2>

<div class="info-blocks-list">
  <div class="block-item" *ngFor="let item of infoBlockData">
    <div class="img">
      <img src="{{item?.img | imagesByLocale: 'home'}}" [alt]="item?.alt | translate">
    </div>
    <h4 class="title">{{item?.title | translate}}</h4>
    <p class="descr">{{item?.descr | translate}}</p>
  </div>
</div>
