import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { InfoRectangleColor, RectangleModel } from './info-rectangle.model';

@Component({
  selector: 'rnpl-info-rectangle',
  templateUrl: './info-rectangle.component.html',
  styleUrls: ['./info-rectangle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoRectangleComponent {

  @Input()
  public config: RectangleModel;
  @Input()
  public textCapitalize: boolean = false;

  constructor() {}
}
