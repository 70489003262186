import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subject, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { TitleService } from './services/title.service';
import { COOKIES_AGREEMENT_NAME, COOKIES_EXPIRE_TIME } from './constants';
import { CanonicalService } from './services/canonical.service';
import { AppService } from './app.service';
import { BlogApiService } from './pages/blog/services/blog-api.service';
import { LocaleService } from './locale/locale.service';
import { LocalePrefixesEnum } from './locale/locale-prefixes.enum';
import { RoutesEnum } from './locale/routes.enum';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public isCookiesAgree = false;
  public isExternalPage = true;
  public isGetStartedPage: boolean;

  private subscriptions: Subscription[] = [];
  private readonly destroyed$: Subject<boolean> = new Subject();

  constructor(
    private translate: TranslateService,
    private titleService: TitleService,
    private cookieService: CookieService,
    private canonicalService: CanonicalService,
    private appService: AppService,
    private blogApeService: BlogApiService,
    private router: Router,
    private localeService: LocaleService,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (this.cookieService.get(COOKIES_AGREEMENT_NAME)) {
      this.isCookiesAgree = true;
    }

    translate.setDefaultLang('en');
    // const localStorageLang = localStorage.getItem('lang');
    //
    // if (localStorageLang) {
    //   translate.use(localStorageLang);
    // } else {
    //   translate.use('en');
    // }

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe((event: RouterEvent) => {
        { // set current locale prefix if it exists
          const urlArr = event.url.split('/').filter(segment => !!segment);
          if (Object.values(LocalePrefixesEnum).includes(urlArr[0] as LocalePrefixesEnum)) {
            this.localeService.setCurrentLocalePrefix(urlArr[0] as LocalePrefixesEnum);
          } else {
            this.localeService.setCurrentLocalePrefix(null);
          }
        }
        this.isExternalPage = event.url.includes('external') ||
          event.url.includes('trade-offer') ||
          event.url.includes('web-shop-inactive');
        this.isGetStartedPage = event.url.includes(this.localeService.getLink(RoutesEnum.GET_STARTED));

        if (this.localeService.isLocaleAT) {
          translate.use('at');
          localStorage.setItem('lang', 'at');
          this.document.documentElement.lang = 'de-AT';
        } else if (this.localeService.isLocaleDE) {
          translate.use('de');
          localStorage.setItem('lang', 'de');
          this.document.documentElement.lang = 'de-DE';
        } else {
          translate.use('en');
          localStorage.setItem('lang', 'en');
          this.document.documentElement.lang = 'en';
        }
        this.titleService.updateMeta();

        if (
          this.localeService.currentLocalePrefix === null
          || this.localeService.isLocaleAT
          || this.localeService.isLocaleDE
        ) {
          this.titleService.addMetaRobotsFollowTag();
        } else {
          this.titleService.addMetaRobotsNoFollowTag();
        }
      });

    this.localeService.currentLocalePrefix$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
      )
      .subscribe((locale: LocalePrefixesEnum) => {
        this.subscriptions.forEach(s => s.unsubscribe());

        // const blogRequest: Subscription  = this.blogApeService.getArticleList()
        //   .pipe(takeUntil(this.destroyed$))
        //   .subscribe(data => {
        //     this.blogApeService.saveArticleList(data);
        //   });
        //
        // const lexiconRequest: Subscription = this.blogApeService.getLexiconList()
        //   .pipe(takeUntil(this.destroyed$))
        //   .subscribe(data => {
        //     this.blogApeService.saveLexiconList(data);
        //   });

        // this.subscriptions.push(blogRequest);
        // this.subscriptions.push(lexiconRequest);
      });
  }

  ngOnInit() {
    this.titleService.boot();
    this.canonicalService.setCanonicalURL();
    // this.appService.getUserInfo()
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe();
  }

  public policyAgreement(): void {
    this.isCookiesAgree = true;
    document.cookie = `${COOKIES_AGREEMENT_NAME}=true; Max-Age=${COOKIES_EXPIRE_TIME}; SameSite=Strict`;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
