import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { ButtonColor, ButtonModel } from '../../button/button.model';
import {FormService} from '../../../services/forms.service';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-request-accepted',
  templateUrl: './request-accepted.component.html',
  styleUrls: ['./request-accepted.component.scss'],
})
export class RequestAcceptedComponent {
  public resendEmailButton: ButtonModel = {
    title: 'BUTTON.RESEND_EMAIL',
    iconName: 'arrow-circle',
    styles: {
      bgColor: ButtonColor.GREY,
      textColor: ButtonColor.GREY,
      iconColor: ButtonColor.GREY,
      height: 48,
    },
  };
  private redirection: string;
  private modalData: any;

  constructor(
    private activeModal: NgbActiveModal,
    private appService: AppService,
    private router: Router,
    public formService: FormService,
  ) {}

  public closeModal() {
    this.activeModal.dismiss('Close');
    if (this.redirection) {
      this.router.navigate([this.redirection]);
    }
  }

  public resendEmail(): void {
    this.appService.emailResend(this.modalData.verification_token)
      .subscribe();
  }

}
