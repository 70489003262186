import {Component, Inject, OnInit, PLATFORM_ID, QueryList, ViewChildren} from '@angular/core';
import {SLIDER_DATA} from '../../pages/home/content-data/home-page.config';
import {isPlatformBrowser} from '@angular/common';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-review-us-block',
  templateUrl: './review-us-block.component.html',
  styleUrls: ['./review-us-block.component.scss']
})
export class ReviewUsBlockComponent implements OnInit {

  private xDown = null;
  private yDown = null;

  public sliderData = SLIDER_DATA;
  public showNextSlideProgress: boolean = false;
  private slidePosition: number = 0;
  public activeSlideIndex: number = 1;
  public countdownTimer;

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChildren('slideElement') slideElement: QueryList<any>;
  @ViewChildren('rnplSlider') rnplSlider: QueryList<any>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.isLoading$.next(true)
    if (isPlatformBrowser(this.platformId)) {
      this.startTimer();
    }
  }

  public nextSlide(): void {
    this.showNextSlideProgress = false;

    this.startTimer();

    const slideElementWidth = this.slideElement.first.nativeElement.offsetWidth;
    const sliderElement = this.rnplSlider.first.nativeElement;
    const visibleSlideCounter = Math.round(sliderElement.offsetWidth / slideElementWidth);

    if (this.slidePosition <= (-slideElementWidth * (this.sliderLength - visibleSlideCounter))) {
      this.slidePosition = 0;
      this.activeSlideIndex = 1;
      sliderElement.style.marginLeft = '0px';
    } else {
      this.slidePosition -= slideElementWidth * visibleSlideCounter;
      this.slidePosition = Math.max(this.slidePosition, -slideElementWidth * (this.sliderLength - visibleSlideCounter));
      this.activeSlideIndex ++;
    }

    sliderElement.style.marginLeft = this.slidePosition + 'px';
    // this.getTotalSliderCount();
  }

  public prevSlide(): void {
    this.showNextSlideProgress = false;

    const slideElementWidth = this.slideElement.first.nativeElement.offsetWidth;
    const sliderElement = this.rnplSlider.first.nativeElement;
    const visibleSlideCounter = Math.round(sliderElement.offsetWidth / slideElementWidth);

    this.slidePosition += slideElementWidth * visibleSlideCounter;

    this.slidePosition = Math.min(this.slidePosition, 0);
    sliderElement.style.marginLeft = this.slidePosition + 'px';

    if (this.activeSlideIndex > 1) {
      this.activeSlideIndex --;
    }

    // this.getTotalSliderCount();
    this.startTimer();
  }

  public startTimer(): void {
    clearInterval(this.countdownTimer);
    let sliderTimer = 180;

    this.countdownTimer = setInterval( () => {
      sliderTimer -= 1;

      if (!this.showNextSlideProgress) {
        this.showNextSlideProgress = true;
      }

      if (sliderTimer <= 0) {
        this.nextSlide();
        this.showNextSlideProgress = false;
      }
    }, 100);

  }

  public getCountStar(num): any[] {
    if (!num) { return []; }
    return new Array(num);
  }

  get sliderLength(): number {
    if (!this.sliderData && this.sliderData.length === 0) { return 0; }

    return this.sliderData.length;
  }

  public handleTouchStart(evt) {
    const firstTouch = this.getTouches(evt)[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  };

  public handleTouchMove(evt) {
    if ( !this.xDown || !this.yDown ) { return;}

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = this.xDown - xUp;
    let yDiff = this.yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
      xDiff > 0  ? this.nextSlide() :  this.prevSlide();
    }

    this.xDown = null;
    this.yDown = null;
  };

  private getTouches(evt) {
    return evt.touches;
  }

}
