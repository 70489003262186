import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurKundenComponent } from './our-kunden.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [OurKundenComponent],
  exports: [
    OurKundenComponent
  ],
    imports: [
        CommonModule,
        TranslateModule
    ]
})
export class OurKundenModule { }
