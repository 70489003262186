<div class="page">
  <div class="lexicon-list-container">
    <div class="lexicon-list-head">
      <h1 *ngIf="!selectedCategory || selectedCategory === '0'" class="title">
        {{ 'BLOG.LEXICON' | translate }}
      </h1>

      <h1 *ngIf="!!selectedCategory && selectedCategory !== '0'" class="title">
        {{ 'BLOG.LEXICON' | translate }}: {{ currentCategory?.label !== 'BLOG.ALL_CATEGORIES' ? currentCategory?.label : '' }}
      </h1>

      <div class="control">
        <rnpl-select
          [searchable]="false"
          [styles]="blogsCategoriesListConfig.styles"
          [selectItems]="activeCategories"
          [selectedValue]="currentCategory"
          (changeSelect)="changeSelectCategories($event)"
          [bindValue]="'id'"
        >
        </rnpl-select>
      </div>
    </div>

    <div class="lexicon-body" *ngIf="!(isLoading$ | async); else skeleton">
      <div class="lexicon-nav-list">
        <ul class="lexicon-list">
          <li class="lexicon-list-item all"
              [class.active]="!selectedFilter"
              (click)="clearFilter()"
          >
            {{ 'BLOG.ALL' | translate }}
          </li>
          <ng-container *ngFor="let item of lexiconListNav">
            <li *ngIf="item?.title?.length === 1"
                class="lexicon-list-item"
                [class.active]="selectedFilter === item?.title"
                (click)="selectFilter(item?.title)"
            >
              {{ item?.title || 'A' }}
            </li>
          </ng-container>
        </ul>
      </div>

      <div class="lexicon-content-list-container">
        <div class="lexicon-content-list">
          <ng-container *ngFor="let item of (lexiconList$ | async); let index = index">
            <div *ngIf="item?.data && item?.data?.length"
                class="lexicon-content-list-item"
                [class.open]="item?.isOpen">

              <div class="lexicon-content-list-item-head" (click)="openPanel(index)">
                <h4 class="title">
                  {{ item?.title }}
                </h4>

                <i class="icon icon-arrow-micro-up ml-auto"></i>
              </div>

              <ul class="posts-list">
                <li *ngFor="let lexicon of item?.data" class="post-item">
                  <a [routerLink]="lexicon?.lexicon_url" class="link">
                    {{ lexicon?.title }}
                  </a>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #skeleton>
  <app-lexicon-list-skeleton></app-lexicon-list-skeleton>
</ng-template>

