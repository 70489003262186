<div class="question-block-container">
  <div class="icon">
    <i class="icon icon-question-mark-circle"></i>
  </div>

  <h5 class="title">{{ 'FOOTER.DO_YOU_HAVE_A_QUESTIONS' | translate }}</h5>
  <p class="descr">{{ 'FOOTER.WE_ARE_HAPPY' | translate }}</p>
  <p class="descr">Mo-Fr – 08:00 bis 18:00 Uhr</p>

  <button class="btn btn-questions">
    <a class="link" href="mailto:support@it-work.at">{{ 'NAV.CONTACT_US' | translate }}</a>
  </button>
</div>
