<div class="start-steps-container">
  <div *ngFor="let item of startSteps" class="start-step-wrapper">
    <div class="start-step">
      <div class="title-container">
        <div class="count">
          <span>{{item?.count}}</span>
        </div>

        <h3 *ngIf="!isDisplayNarrowScreen || !item?.title_375" class="title" [innerText]="item?.title | translate"></h3>
        <h3 *ngIf="isDisplayNarrowScreen && !!item?.title_375" class="title" [innerText]="item?.title_375 | translate"></h3>

        <div *ngIf="item?.icon || item?.additionalInfo" class="icon-container">
          <i class="icon icon-{{item?.icon}}"></i>
          <span class="descr">{{item?.additionalInfo | translate}}</span>
        </div>
      </div>

      <div class="img-container mt-auto">
        <img *ngIf="item?.img" src="{{item?.img | imagesByLocale: 'home'}}" [alt]="item?.alt | translate" width="256" height="292">
      </div>

    </div>
  </div>
</div>
