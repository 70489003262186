import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BlogApiService } from '../services/blog-api.service';
import { AuthorModel, PostModel } from '../../../models/blog-list.model';
import { Meta } from '@angular/platform-browser';
import { LocaleService } from '../../../locale/locale.service';
import { RoutesEnum } from '../../../locale/routes.enum';
import {TitleService} from '../../../services/title.service';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss']
})
export class AuthorComponent implements OnInit, OnDestroy {

  public authorProfile: AuthorModel;

  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly destroyed$: Subject<boolean> = new Subject();

  constructor(
    private blogApiService: BlogApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private meta: Meta,
    public localeService: LocaleService,
    public titleService: TitleService,
  ) {}

  ngOnInit(): void {
    this.localeService.currentLocalePrefix$.subscribe(data => {
      if (data === null) {
        this.meta.updateTag({
          property: 'robots',
          content: 'noindex, nofollow'
        });
      }
    })

    this.isLoading$.next(true);
    const authorName = this.activatedRoute.snapshot.paramMap.get('name');

    this.blogApiService.getAuthorProfile(authorName)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(response => {
        this.authorProfile = this.prepareAuthorProfile(response);
        this.isLoading$.next(false);
      }, (error) => {
        if (error.status === 404) {
          this.router.navigate([this.localeService.rootRoute + '/404']);
        } else {
          this.router.navigate([this.localeService.rootRoute]);
        }
      });
  }

  private prepareAuthorProfile(profile: AuthorModel): AuthorModel {
    return {
      ...profile,
      createdDate: profile.created_at * 1000,
      posts: this.preparePostsList(profile?.posts)
    };
  }

  private preparePostsList(posts: PostModel[]): PostModel[] {
    if (!posts) { return null; }

    const localePrefix = this.localeService.currentLocalePrefix
      ? '/' + this.localeService.currentLocalePrefix
      : '';

    return posts.map( post => {
      return {
        ...post,
        createdDate: post.created_at * 1000,
        routerLink: localePrefix + `/blog/${post.post_url}`
      };
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.meta.removeTag('name="robots"');
  }

}
