import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailInfoBlockComponent } from './detail-info-block.component';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../../pipes/pipes.module';



@NgModule({
  declarations: [
    DetailInfoBlockComponent
  ],
  exports: [
    DetailInfoBlockComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        PipesModule
    ]
})
export class DetailInfoBlockModule { }
