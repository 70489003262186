import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG, TransferState } from '@angular/platform-browser';
import { APP_INITIALIZER, Injectable, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe, LOCATION_INITIALIZED, ViewportScroller } from '@angular/common';
import { RouterModule, Router, Scroll } from '@angular/router';
// import * as Hammer from 'hammerjs';
import * as Hammer from 'hammerjs';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { filter, takeUntil } from 'rxjs/operators';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TitleService } from './services/title.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ApiInterceptor } from './api.interceptor';
import { ModalContainerComponent } from './ui-components/modal-container/modal-container.component';
import { environment } from '../environments/environment';
import { HeaderModule } from './ui-components/header/header.module';
import { FooterModule } from './ui-components/footer/footer.module';
import { PrivacyAgreementModule } from './ui-components/privacy-agreement/privacy-agreement.module';
import { HomeComponent } from './pages/home/home.component';
import { ButtonModule } from './ui-components/button/button.module';
import { ActionCardModule } from './ui-components/action-card/action-card.module';
import { InfoRectangleModule } from './ui-components/info-rectangle/info-rectangle.module';
import { EarlyAccessBannerModule } from './ui-components/early-access-banner/early-access-banner.module';
import { NgxMaskModule } from 'ngx-mask';
import { ExitModalModule } from './ui-components/exit-modal/exit-modal.module';
import { RequestDemoModule } from './ui-components/request-demo/request-demo.module';
import { translateBrowserLoaderFactory } from './services/translate-browser.loader';
import {TrustboxModule} from './ui-components/trustbox/trustbox.module';
import {DetailInfoBlockModule} from './ui-components/detail-info-block/detail-info-block.module';
import {InfoCardsBlockModule} from './ui-components/info-cards-block/info-cards-block.module';
import {ChannelsBlockModule} from './ui-components/channels-block/channels-block.module';
import {ReviewUsModule} from './ui-components/review-us/review-us.module';
import {StartStepsBlockModule} from './ui-components/start-steps-block/start-steps-block.module';
import {SliderCarouselModule} from './ui-components/slider-carousel/slider-carousel.module';
import { LocaleService } from './locale/locale.service';
import {PipesModule} from './pipes/pipes.module';
import {OurPartnersModule} from "./ui-components/our-partners/our-partners.module";
import {OurCertificateModule} from "./ui-components/our-certificate/our-certificate.module";
import {OurKundenModule} from "./ui-components/our-kunden/our-kunden.module";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en';
      translate.setDefaultLang('en');
      translate.use(langToSet).subscribe(() => {

      }, err => {

      }, () => {
        resolve(null);
      });
    });
  });
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: 'horizontal' },
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ModalContainerComponent,
  ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient, TransferState]
            }
        }),
        RouterModule,
        RecaptchaV3Module,
        // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ServiceWorkerModule.register('ngsw-worker.js'),
        HammerModule,
        NgxTypedJsModule,
        NgxSliderModule,
        HeaderModule,
        FooterModule,
        OurPartnersModule,
        OurCertificateModule,
        PrivacyAgreementModule,
        ButtonModule,
        InfoRectangleModule,
        ActionCardModule,
        EarlyAccessBannerModule,
        NgxMaskModule.forRoot(),
        ExitModalModule,
        RequestDemoModule,
        TrustboxModule,
        DetailInfoBlockModule,
        InfoCardsBlockModule,
        ChannelsBlockModule,
        ReviewUsModule,
        StartStepsBlockModule,
        SliderCarouselModule,
        PipesModule,
        OurKundenModule
    ],
  providers: [
    CookieService,
    DatePipe,
    TitleService,
    TransferState,
    LocaleService,
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LfVERgdAAAAAM7JVfVywbnbxQJwaYSaAyCrIQl7'},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    {provide: SwRegistrationOptions, useFactory: () => ({enabled: environment.production})},
    // {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: MyHammerConfig,
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .subscribe((e) => {
        viewportScroller.setOffset([0, 128]);

        if (e.position) {
          // backward navigation
          setTimeout(() => {
            viewportScroller.scrollToPosition(e.position);
          }, 0);
        } else if (e.anchor) {
          // anchor navigation
          setTimeout(() => {
            viewportScroller.scrollToAnchor(e.anchor);
          }, 0);
        } else {
          // forward navigation
          setTimeout(() => {
            viewportScroller.scrollToPosition([0, 0]);
          }, 0);
        }
      });
  }
}
