import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StickyAnchorService {
  public displayAnchorSticky: boolean = false;
  public isOpenHeadNav: boolean = false;

  public displayAnchorStickyHandler(isDisplay): void {
    this.displayAnchorSticky = isDisplay;
  }
}
