<div
  class="info-rectangle"
  [class.small]="config?.small"
  [ngClass]="config?.bgColor"
  [class.colored]="!!config?.bgColor"
>
  <i *ngIf="!!config?.iconName" class="icon icon-{{ config?.iconName }}"></i>
  <img *ngIf="!!config?.img" src="assets/img/{{ config?.img }}" alt="" />
  <span class="title" [ngClass]="config?.textColor" [class.text-uppercase]="!textCapitalize">{{ config?.title | translate}}<sup *ngIf="!!config?.index">{{config?.index}}</sup></span>
</div>
