export enum RoutesEnum {
  HOME = '/',
  GET_STARTED = 'get-started',
  GET_STARTED_FREE = 'get-started',
  PRICING = 'pricing',
  SYSTEM = 'system',
  ACCOUNTING = 'services',
  SERVICES = 'services',
  TRADE = 'trade',
  WAREHOUSE = 'warehouse',
  PRODUCTS = 'products',
  ANALYTICS = 'analytics',
  PARTNERS = 'partners',
  TEAM = 'team',
  ECOMMERCE = 'e-commerce',
  RETAIL = 'retail',
  PRIVACY_POLICY = 'agb',
  TERMS_OF_SERVICE = 'terms-of-service',
  COOKIE_POLICY = 'cookie-policy',
  SLA = 'sla',
  SUB_PROCESSORS = 'sub-processors',
  GDPR = 'gdpr',
  DATA_PROCESSING = 'data-processing-addendum',
  USER_NOTICE = 'user-notice',
  SERVICE_LEVEL_AGREEMENT = 'service-level-agreement',
  ABOUT = 'about',
  LEGAL = 'legal',
  LEXICON = 'lexicon',
  BLOG = 'blog',
  EXTERNAL = 'external',
  WEBSHOP = 'web-shop-inactive',
  TRADE_OFFER = 'trade-offer',
  ERROR = '404',
}
