<div class="modal-wrapper">
  <div class="header cursor-pointer">
    <div class="btn-container" (click)="closeModal()">
      <i class="icon icon-close"></i>
    </div>
  </div>
  <div class="content d-flex flex-column">
    <div class="modal-img">
      <img src="assets/img/accepted-request.png" alt="modal's image" />
    </div>
    <h4 class="modal-title">{{ "MODAL.THANK_YOU_FOR_REQUEST" | translate }}</h4>
    <p class="modal-descr">{{ "MODAL.THANK_YOU_FOR_REQUEST_MSG" | translate }}</p>

    <rnpl-button
      class="continue-btn"
      [title]="continueButton.title"
      [styles]="continueButton.styles"
      (click)="closeModal()"
    >
    </rnpl-button>
  </div>
</div>
