import { Component, OnInit } from '@angular/core';
import {LocaleService} from '../../locale/locale.service';

@Component({
  selector: 'app-info-cards-block',
  templateUrl: './info-cards-block.component.html',
  styleUrls: ['./info-cards-block.component.scss']
})
export class InfoCardsBlockComponent implements OnInit {

  public infoBlockData: any[] = [
    {
      img: {
        en: 'simple_image.png',
        de: 'Einfaches_ERP-System.png',
        at: 'Einfaches_ERP-System.png'
      },
      title: 'HOME.PROBLEM_1_TITLE',
      descr: 'HOME.PROBLEM_1',
      alt: 'IMAGES_ALT.ABOUT.ALT_15',
    },
    {
      img: {
        en: '02_image.png',
        de: '02_image.png',
        at: '02_image.png'
      },
      title: 'HOME.PROBLEM_2_TITLE',
      descr: 'HOME.PROBLEM_2',
      alt: '',
    },
    {
      img: {
        en: 'right_image.png',
        de: 'Richtige_Entscheidungen.png',
        at: 'Richtige_Entscheidungen.png'
      },
      title: 'HOME.PROBLEM_3_TITLE',
      descr: 'HOME.PROBLEM_3',
      alt: 'IMAGES_ALT.ABOUT.ALT_14',
    },
  ]

  constructor(
    public localeService: LocaleService,
  ) { }

  ngOnInit(): void {
  }

}
