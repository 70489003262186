import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormService } from './forms.service';
import { ErrorModalComponent } from '../ui-components/modal/error/error.modal.component';
import { RequestAcceptedComponent } from '../ui-components/modal/request-accepted/request-accepted.component';
import { FormNames } from './models/form.models';
import { RequestAcceptedPrimaryComponent } from '../ui-components/modal/request-accepted-primary/request-accepted-primary.component';
import { RequestDemoAcceptedComponent } from '../ui-components/modal/request-demo-accepted/request-demo-accepted.component';

@Injectable({
  providedIn: 'root',
})
export class RnplModalService {
  private ngbModalOptions: NgbModalOptions = {
    backdrop : 'static',
    keyboard : false,
  };

  private ngbModalOptionsErrorSuccess: NgbModalOptions = {
    backdrop : true,
    keyboard : false,
  };

  public modalData: { [key: string]: string } = {
    title: '',
    descr: '',
  };

  constructor(private http: FormService, private modalService: NgbModal) {}

  public openContactUsModal(components: object, type: string) {
    const closeActionTitle = 'Close';
    const componentRef = this.modalService.open(components[type], this.ngbModalOptions);
    if (componentRef.componentInstance) {
      componentRef.componentInstance.activeComponentType = type;
    }
    componentRef.result.then(null, (value) => {
      if (value === 0 || value === closeActionTitle) {
        this.modalService.dismissAll();
      } else {
        this.openContactUsModal(components, value);
      }
    });
  }

  public submitFormModal(formData, formName, activeModalRef?, redirection?, modalOptions?: NgbModalOptions) {
    this.http.sendRequest(formData, formName).subscribe(
      () => {
        if (activeModalRef) {
          activeModalRef.close('submited');
        } else {
          this.modalService.dismissAll();
        }
        this.openAcceptedModal(null, this.ngbModalOptionsErrorSuccess, formName);
      },
      () => {
        if (activeModalRef) {
          activeModalRef.close('submited');
        } else {
          this.modalService.dismissAll();
        }
        this.openErrorModal(formData, formName, activeModalRef, redirection);
      }
    );
  }

  public openErrorModal(formData, formName, activeModalRef?, redirection?) {
    const modalRef = this.modalService.open(ErrorModalComponent, this.ngbModalOptionsErrorSuccess);
    const self = this;
    modalRef.componentInstance.redirection = redirection;
    modalRef.result.then(
      () => {
        self.submitFormModal(formData, formName, activeModalRef, redirection, this.ngbModalOptionsErrorSuccess);
      },
      () => {}
    );
  }

  public openAcceptedModal(redirection?: string, modalOptions?: NgbModalOptions, formName?: string, data?: any) {
    let modalByFormType;

    switch (formName) {
      case FormNames.GET_STARTED:
        modalByFormType = RequestAcceptedComponent;
        break;
      case FormNames.DEMO:
        modalByFormType = RequestDemoAcceptedComponent;
        break;
      default:
          modalByFormType = RequestAcceptedPrimaryComponent
        break;
    }

    const succesModalRef = this.modalService.open(modalByFormType, modalOptions || this.ngbModalOptionsErrorSuccess);
    if (modalByFormType === RequestAcceptedComponent) {
      succesModalRef.componentInstance.modalData = data;
    }
    if (redirection) {
      succesModalRef.componentInstance.redirection = redirection;
    }
  }

  public setModalData(data: { [key: string]: string }): void {
    this.modalData = data;
  }

  public getModalData(): { [key: string]: string } {
    return this.modalData;
  }
}
