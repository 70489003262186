import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import {AuthorModel, BlogsListModel, CategoryModel, PostModel} from '../../../models/blog-list.model';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BlogApiService {

  public articleList$: BehaviorSubject<BlogsListModel> = new BehaviorSubject<BlogsListModel>({} as BlogsListModel);
  public lexiconList$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor( private http: HttpClient) { }

  public getArticleList(): Observable<BlogsListModel> {
    return this.http.get<any>('/blog/list');
  }

  public getCategoryList(): Observable<{[key: number]: CategoryModel }> {
    return this.http.get<any>('/blog/list')
      .pipe(
        map(response => response.category)
      );
  }

  public getArticleListByCategory(id: number): Observable<BlogsListModel> {
    return this.http.get<any>(`/blog/list/category/${id}`);
  }

  public getAuthorProfile(name: string): Observable<AuthorModel> {
    return this.http.get<any>(`/authors/authors-page/${name}`);
  }

  public getPost(name: string): Observable<PostModel> {
    return this.http.get<any>(`/blog/post/${name}`);
  }

  public getReadAlso(postId: number): Observable<BlogsListModel> {
    return this.http.get<any>(`/blog/read-also/${postId}`);
  }

  public getLexiconList(): Observable<any> {
    return this.http.get<any>(`/lexicon/list`);
  }

  public getLexicon(name: string): Observable<PostModel> {
    return this.http.get<any>(`/lexicon/post/${name}`);
  }

  public getLexiconListByCategory(id: number): Observable<BlogsListModel> {
    return this.http.get<any>(`/lexicon/list/category/${id}`);
  }

  public saveArticleList(articleList: BlogsListModel): void {
    this.articleList$.next(articleList);
  }

  public saveLexiconList(lexiconList: any): void {
    this.lexiconList$.next(lexiconList);
  }
}
