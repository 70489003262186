import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    declarations: [RequestDemoComponent],
    exports: [
        RequestDemoComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        TranslateModule
    ]
})
export class RequestDemoModule { }
