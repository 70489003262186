import { ButtonModel } from '../button/button.model';

export interface ActionCardModel {
  title: string;
  title_375?: string;
  description?: string;
  styles?: ActionCardStylesModel;
  buttonConfig: ButtonModel;
  img?: string;
}

export interface ActionCardStylesModel {
  backgroundColor?: ActionCardColor;
  textColor?: TextColor;
  alignment?: ActionCardAlignment;
  color?: ActionCardColor;
  height?: number;
}

export enum ActionCardColor {
  BLACK = 'black',
  WHITE = 'white',
  GREEN = 'green',
}

export enum ActionCardAlignment {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum TextColor {
  WHITE = 'text-white',
  BLACK = 'text-black',
}
