<div class="action-card-wrapper">
  <div *ngIf="img" class="action-card-img"
       [style.backgroundImage]="'url(' + ({} | imagesByLocale: '': img) + ')'"
       [class.black]="styles.backgroundColor === 'black'"></div>
  <div
    class="action-card {{ styles?.alignment }}"
    [ngClass]="!!styles.backgroundColor ? styles.backgroundColor : ''"
    [style.minHeight.px]="styles?.height">
    <div class="action-card-container" [class.flex-row]="!!imgRight">

      <div *ngIf="img" class="action-card-img"
           [style.backgroundImage]="'url(' + ({} | imagesByLocale: '': img) + ')'"
           [class.black]="styles.backgroundColor === 'black'"></div>

      <div class="action-card-body">
        <div
          class="action-card-descr"
          [ngClass]="!!styles.textColor ? styles.textColor : ''">
          <h4 class="title" [class.device-depended]="title_375" [innerText]="title | translate"></h4>
          <h4 *ngIf="!!title_375" class="title device-375" [innerText]="title_375 | translate"></h4>
          <p *ngIf="!!description" class="descr" [innerText]="description | translate"></p>
        </div>

        <div class="action-card-button">
          <rnpl-button
            [title]="buttonConfig.title"
            [styles]="buttonConfig.styles"
            [iconName]="buttonConfig.iconName"
            (click)="openAskQuestionModal()">
          </rnpl-button>
        </div>
      </div>

      <div *ngIf="!!imgRight" class="img-container">
        <!-- TODO: get width and height from Input params -->
        <img src="{{ {} | imagesByLocale: 'banners': imgRight}}" [alt]="imgAlt | translate">
      </div>
    </div>

<!--    <div-->
<!--      class="gradient-container gradient-container-left"-->
<!--      [ngClass]="-->
<!--      styles.color === 'black'-->
<!--        ? 'gradient-container-sm'-->
<!--        : 'gradient-container-big'-->
<!--    "-->
<!--    >-->
<!--      <div class="gradient"></div>-->
<!--    </div>-->

<!--    <div *ngIf="styles.alignment === actionCardAlignment.VERTICAL"-->
<!--         class="gradient-container gradient-container-right">-->
<!--      <div class="gradient"></div>-->
<!--    </div>-->
  </div>
</div>

