import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserInfoResponse } from './models/user-Info.model';
import { ResponseModel } from './services/models/response.model';
import { map, tap } from 'rxjs/operators';
import { SystemUpdatedModel } from './pages/external/system-updated.model';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public currentCountryIso;

  // public readonly isLoading$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
  public readonly isLoading$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  private readonly showWithCountryList: string[] = [
    'AT',
    // 'UA'
  ];

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  public getUserInfo(): Observable<UserInfoResponse> {
    if (isPlatformServer(this.platformId)) {
      this.currentCountryIso = 'DE';
      this.isLoading$.next(false);
      return of(null);
    }
    return this.http.get<UserInfoResponse>('/get-info/ip')
      .pipe(
        tap((data) => {
          this.currentCountryIso = data.isoCode;
          this.isLoading$.next(false);
        })
      );
  }

  public emailSubscription(email, type): Observable<any> {
    const params = {
      email: email,
      type: type
    };

    return this.http.post<ResponseModel<any>>('/get-started/email-subscription', params);
  }

  public emailVerification(vet: string): Observable<any> {
    return this.http.get<ResponseModel<any>>( `/get-started/email-verify/${vet}`);
  }

  public emailResend(vet: string): Observable<any> {
    return this.http.get<ResponseModel<any>>( `/get-started/resend-email-verify/${vet}`);
  }

  public getWorkspaceUpdatingStatus(): Observable<SystemUpdatedModel> {
    return this.http.get<ResponseModel<any>>('/hq/v1/status')
      .pipe(
        map((response: ResponseModel<SystemUpdatedModel>) => {
          return response.data;
        })
      );
  }

  public showWithCountry(currentCountryIso: string): boolean {
   return this.showWithCountryList
     .some(countryIso => (countryIso && currentCountryIso) && countryIso.toLowerCase() === currentCountryIso.toLowerCase());
  }
}
