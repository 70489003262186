import {
  ActionCardAlignment,
  ActionCardColor,
  ActionCardModel,
  TextColor
} from '../../../ui-components/action-card/action-card.model';
import { BUTTONS } from './buttons.config';

const buttons = BUTTONS;

export const ACTION_CARDS: Array<ActionCardModel> = [
  {
    title: 'ACTION_CARD.READY_TO_IMPROVE',
    description:
      'ACTION_CARD.THE_SIMPLEST_ONE_MINUTES',
    styles: {
      alignment: ActionCardAlignment.HORIZONTAL,
      textColor: TextColor.WHITE,
    },
    buttonConfig: {
      title: buttons[0].title,
      styles: buttons[0].styles,
      iconName: buttons[0].iconName
    },
    img: 'Contact.svg',
  },
  {
    title: 'ACTION_CARD.NEED_ASSISTANCE',
    description:
      'ACTION_CARD.WE_ARE_READY_TO_HELP',
    styles: {
      backgroundColor: ActionCardColor.WHITE,
      alignment: ActionCardAlignment.HORIZONTAL,
      textColor: TextColor.BLACK,
    },
    buttonConfig: {
      title: buttons[1].title,
      styles: buttons[1].styles,
    },
    img: '1.15.png',
  },
];
