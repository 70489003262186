import { NavItemsModels } from '../header/nav-Items.models';

export function getFooterNavItems(
  hideBlog: boolean,
  hideLexicon: boolean,
  tradeRouterLink: string,
  servicesRouterLink: string,
  ecoRouterLink: string,
  retailRouterLink: string,
  accountingRouterLink: string,
  warehouseRouterLink: string,
  productsRouterLink: string,
  partnersRouterLink: string,
  teamRouterLink: string,
  analyticsRouterLink: string,
  priceRouterLink: string,
  getStartedRouterLink: string,
  lexiconRouterLink: string,
  aboutRouterLink: string,
  blogRouterLink: string,
  legalRouterLink: string,
  termsRouterLink: string,
  privacyRouterLink: string,
  cookieRouterLink: string,
): Array<NavItemsModels> {
  return [
    {
      title: 'NAV.FEATURES',
      children: [
        // {
        //   title: 'NAV.SYSTEM',
        //   routerLink: '/system',
        // },
        // {
        //   title: 'FOOTER.WHOLESALE',
        //   routerLink: tradeRouterLink,
        // },
        // {
        //   title: 'NAV.SERVICES',
        //   routerLink: accountingRouterLink,
        // },
        {
          title: 'NAV.ABOUT_RUNPLE',
          routerLink: aboutRouterLink,
        },
        {
          title: 'NAV.CONTACT_US',
          onClick: 'openAskQuestionModal',
        },
        // {
        //   title: 'FOOTER.ECOMMERCE',
        //   routerLink: ecoRouterLink,
        // },
        // {
        //   title: 'NAV.RETAIL',
        //   routerLink: retailRouterLink,
        // },
        // {
        //   title: 'FOOTER.ACCOUNTING',
        //   icon: 'calculator',
        //   routerLink: accountingRouterLink,
        // },
        // {
        //   title: 'FOOTER.WAREHOUSE',
        //   icon: 'warehouse',
        //   routerLink: warehouseRouterLink,
        // },
        // {
        //   title: 'FOOTER.PRODUCTS',
        //   icon: 'box',
        //   routerLink: productsRouterLink,
        // },
        // {
        //   title: 'FOOTER.PARTNERS',
        //   icon: 'people',
        //   routerLink: partnersRouterLink,
        // },
        // {
        //   title: 'FOOTER.TEAM',
        //   icon: 'people',
        //   routerLink: teamRouterLink,
        // },
        // {
        //   title: 'FOOTER.ANALYTICS',
        //   icon: 'business-intelligence',
        //   routerLink: analyticsRouterLink,
        // },
      ],
    },
    // {
    //   title: 'NAV.DISTRIBUTION',
    //   children: [
    //     {
    //       title: 'NAV.CUSTOMER_CARE',
    //       routerLink: '/customer-care',
    //     },
    //     {
    //       title: 'NAV.PRICING',
    //       onClick: 'openEarlyAccessModal',
    //     },
    //     {
    //       title: 'NAV.GET_STARTED',
    //       onClick: 'openEarlyAccessModal',
    //     },
    //   ],
    // },
    // {
    //   title: 'NAV.DISTRIBUTION',
    //   children: [
    //     // {
    //     //   title: 'NAV.CUSTOMER_CARE',
    //     //   routerLink: '/customer-care',
    //     // },
    //     {
    //       title: 'NAV.PRICING',
    //       routerLink: priceRouterLink,
    //     },
    //     {
    //       title: 'NAV.GET_STARTED',
    //       routerLink: getStartedRouterLink
    //     },
    //     // {
    //     //   title: 'NAV.RELEASE_NOTES',
    //     //   routerLink: '/release-notes'
    //     // routerLink: '/versionshinweise'
    //     // },
    //     {
    //       title: 'BLOG.LEXICON',
    //       routerLink: lexiconRouterLink,
    //       hide: hideLexicon,
    //     },
    //   ],
    // },
    // {
    //   title: 'NAV.COMPANY',
    //   children: [
    //     {
    //       title: 'NAV.CONTACT_US',
    //       onClick: 'openAskQuestionModal',
    //     },
    //     // {
    //     //   title: 'BLOG.BLOG',
    //     //   routerLink: blogRouterLink,
    //     //   hide: hideBlog,
    //     // },
    //   ],
    // },
    {
      title: 'NAV.LEGAL',
      children: [
        {
          title: 'NAV.LEGAL_NOTICE',
          routerLink: legalRouterLink,
        },
        {
          title: 'NAV.PRIVACY_POLICY',
          routerLink: privacyRouterLink,
        },
        {
          title: 'COOKIES.COOKIES_POLICY',
          routerLink: cookieRouterLink,
        },
        // {
        //   title: 'Facebook',
        //   externalLink: 'https://www.facebook.com/runple.gmbh',
        //   img: 'facebook'
        // },
        // {
        //   title: 'Twitter',
        //   externalLink: 'https://twitter.com/runple_gmbh',
        //   img: 'twitter'
        // },
        // {
        //   title: 'Instagram',
        //   externalLink: 'https://www.instagram.com/runple.gmbh',
        //   img: 'instagram'
        // },
        // {
        //   title: 'LinkedIn',
        //   externalLink: 'https://www.linkedin.com/company/runple',
        //   img: 'linkedin'
        // },
        // {
        //   title: 'XING',
        //   externalLink: 'https://www.xing.com/pages/runple-gmbh',
        //   img: 'xing'
        // },
      ]
    },
  ];
}

export function getLegalInfoList(
  legalRouterLink: string,
  termsRouterLink: string,
  privacyRouterLink: string,
  cookieRouterLink: string,
): Array<NavItemsModels> {
  return [
    // {
    //   title: 'NAV.LEGAL_NOTICE',
    //   routerLink: legalRouterLink,
    // },
    // {
    //   title: 'NAV.TERMS_OF_SERVICE',
    //   routerLink: termsRouterLink,
    // },
    // {
    //   title: 'NAV.PRIVACY_POLICY',
    //   routerLink: privacyRouterLink,
    // },
    // {
    //   title: 'COOKIES.COOKIES_POLICY',
    //   routerLink: cookieRouterLink,
    // },
  ];
}
