import { Component, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EarlyAccessComponent } from '../modal/early-access/early-access.component';

@Component({
  selector: 'app-modal-container',
  template: ''
})
export class ModalContainerComponent implements OnDestroy {
  currentDialog = null;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router
  ) {
    route.params.pipe(takeUntil(this.destroy$)).subscribe(() => {
      router.navigateByUrl('/');

      this.currentDialog = this.modalService.open(EarlyAccessComponent, {backdrop: 'static'});
      if (this.currentDialog.componentInstance) {
        this.currentDialog.componentInstance.activeComponentType = 'earlyAccess';
      }

      this.currentDialog.result.then(result => {
        router.navigateByUrl('/');
      }, reason => {
        router.navigateByUrl('/');
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
