import { Component, Input } from '@angular/core';

import { ButtonColor } from '../button/button.model';
import { ActionCardAlignment, ActionCardModel, TextColor } from '../action-card/action-card.model';
import { LocaleService } from '../../locale/locale.service';


@Component({
  selector: 'rnpl-early-access-banner',
  templateUrl: './early-access-banner.component.html',
})
export class EarlyAccessBannerComponent {

  @Input() title: string;
  @Input() imgRight: string = 'Outgoing_invoice.png';
  @Input() imgRightAt: string = 'Outgoing_invoice.png';
  @Input() imgRightDe: string = 'Outgoing_invoice.png';
  @Input() description: string;

  public banner: ActionCardModel = {
    title: 'Ready? \n Let’s get started!',
    // description: 'We know how hard it is to earn every single euro, so you could try Runple for free and make sure it really helps you.',
    styles: {
      alignment: ActionCardAlignment.VERTICAL,
      textColor: TextColor.WHITE,
    },
    buttonConfig: {
      title: 'NAV.TRY_FOR_FREE',
      styles: {
        bgColor: ButtonColor.BLACK,
        height: 64,
        iconColor: ButtonColor.WHITE,
        textColor: ButtonColor.WHITE
      },
      iconName: 'rocket',
    },
  };

  constructor(public localeService: LocaleService) {
  }

}
