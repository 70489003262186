<div class="page">

  <div class="profile-container">
    <div class="profile">
      <div class="profile-avatar-skeleton skeleton-item-animated">
      </div>
      <div class="profile-name-container">

        <div class="name-skeleton skeleton-item-animated"></div>
        <div class="descr-skeleton skeleton-item-animated"></div>
      </div>
    </div>

    <div class="profile-content">

      <div class="profile-description-container">
        <div *ngFor="let post of [].constructor(4)" class="descr-skeleton skeleton-item-animated"></div>
      </div>
    </div>

    <div class="more-post-container">
      <div class="title-skeleton skeleton-item-animated"></div>

      <div class="posts-list">
        <div *ngFor="let post of [].constructor(6)" class="post">
            <span class="date-skeleton skeleton-item-animated"></span>
            <span class="title-skeleton skeleton-item-animated"></span>
        </div>
      </div>
    </div>
  </div>
</div>
