import { LoadChildren, Route, Routes } from '@angular/router';
import { Type } from '@angular/core';

import { RoutesEnum } from './routes.enum';
import { LOCALE_ROUTES_CONFIG } from './locale-routes.config';
import { LocalePrefixesEnum } from './locale-prefixes.enum';
import { BlogPageComponent } from '../pages/blog/blog-page/blog-page.component';
import { AuthorComponent } from '../pages/blog/author/author.component';
import { LexiconPageComponent } from '../pages/blog/lexicon-page/lexicon-page.component';
import { LexiconListComponent } from '../pages/blog/lexicon-list/lexicon-list.component';


export function getLazyModuleRoutesRoutes(route: RoutesEnum, commonParams: Partial<Route>): Routes {
  const routes: Routes = [];

  // add default route. example: '/home'
  routes.push({
    path: route,
    ...commonParams
  });

  for (const localePrefix in LOCALE_ROUTES_CONFIG[route]) {
    if (LOCALE_ROUTES_CONFIG[route].hasOwnProperty(localePrefix)) {
      // add route with locale. example: 'en/home'
      if (localePrefix !== LocalePrefixesEnum.EN) {
        routes.push({
          path: `${localePrefix}/${LOCALE_ROUTES_CONFIG[route][localePrefix]}`,
          ...commonParams
        });
      }
    }
  }

  return routes;
}

export function getHomeRoutes(component: Type<any>): Routes {
  const defaultRoute: Route = {
    path: '',
    component,
    data:{
      metaTitle : 'IT@WORK:Startseite',
      metaDesc : 'IT@WORK:StartseiteDESC',
    },
  };

  const routes: Routes = [defaultRoute];
  Object.values(LocalePrefixesEnum).forEach((locale: LocalePrefixesEnum) => {
    if (locale !== LocalePrefixesEnum.EN) {
      routes.push({
        ...defaultRoute,
        path: locale
      });
    }
  });
  return routes;
}

export function getLexiconRoutes(): Routes {
  const defaultRoute: Route = {
    path: 'lexicon',
    component: LexiconListComponent,
    data: {
      title: 'Runple: Lexikon',
      metaTitle: 'META_TAGS.LEXICON',
      metaDesc: 'META_TAGS.LEXICON_DESCR'
    }
  };
  const routes: Routes = [defaultRoute];
  Object.values(LocalePrefixesEnum).forEach((locale: LocalePrefixesEnum) => {
    if (locale !== LocalePrefixesEnum.EN) {
      routes.push({
        ...defaultRoute,
        path: `${locale}/${LOCALE_ROUTES_CONFIG[RoutesEnum.LEXICON][locale]}`
      });
    }
  });
  return routes;
}

export function getLexiconNameRoutes(): Routes {
  const defaultRoute: Route = {
    path: 'lexicon/:name',
    component: LexiconPageComponent,
    // canActivate: [LexiconPageGuardGuard]
    data: {
      title: 'Runple: Lexikon',
      metaTitle: 'META_TAGS.LEXICON',
      metaDesc: 'META_TAGS.LEXICON_DESCR'
    }
  };
  const routes: Routes = [defaultRoute];
  Object.values(LocalePrefixesEnum).forEach((locale: LocalePrefixesEnum) => {
    if (locale !== LocalePrefixesEnum.EN) {
      routes.push({
        ...defaultRoute,
        path: `${locale}/${LOCALE_ROUTES_CONFIG[RoutesEnum.LEXICON][locale]}/:name`
      });
    }
  });
  return routes;
}

export function getBlogRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Blog',
      metaTitle: 'META_TAGS.BLOG',
      metaDesc: 'META_TAGS.BLOG_DESCR'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.BLOG, commonParams);
}

export function getBlogNameRoutes(): Routes {
  const defaultRoute: Route = {
    path: 'blog/:name',
    component: BlogPageComponent,
    // canActivate: [PostPageGuardGuard]
    data: {
      title: 'Runple: Blog',
      metaTitle: 'META_TAGS.BLOG',
      metaDesc: 'META_TAGS.BLOG_DESCR'
    }
  };
  const routes: Routes = [defaultRoute];
  Object.values(LocalePrefixesEnum).forEach((locale: LocalePrefixesEnum) => {
    if (locale !== LocalePrefixesEnum.EN) {
      routes.push({
        ...defaultRoute,
        path: `${locale}/${defaultRoute.path}`
      });
    }
  });
  return routes;
}

export function getAuthorRoutes(): Routes {
  const defaultRoute: Route = {
    path: 'author/:name',
    component: AuthorComponent,
    // canActivate: [AuthorPageGuardGuard]
    data: {
      title: 'Runple: Lexikon',
      metaTitle: 'META_TAGS.AUTHOR_TITLE',
      metaDesc: 'META_TAGS.AUTHOR'
    }
  };
  const routes: Routes = [defaultRoute];
  Object.values(LocalePrefixesEnum).forEach((locale: LocalePrefixesEnum) => {
    if (locale !== LocalePrefixesEnum.EN) {
      routes.push({
        ...defaultRoute,
        path: `${locale}/${defaultRoute.path}`
      });
    }
  });
  return routes;
}

export function getExternalRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.EXTERNAL, commonParams);
}

export function getWebshopRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.WEBSHOP, commonParams);
}

export function getTradeOfferRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.TRADE_OFFER, commonParams);
}

export function getGetStartedRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Get started',
      metaTitle: 'META_TAGS.GET_STARTED',
      metaDesc: 'META_TAGS.GET_STARTED_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.GET_STARTED, commonParams);
}

export function getPricingRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Pricing',
      metaTitle: 'META_TAGS.PRICING',
      metaDesc: 'META_TAGS.DEFAULT_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.PRICING, commonParams);
}

export function getSystemRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - System',
      metaTitle: 'META_TAGS.DEFAULT_1',
      metaDesc: 'META_TAGS.DEFAULT_1_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.SYSTEM, commonParams);
}

export function getAccountingRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Accounting',
      metaTitle: 'META_TAGS.ACCOUNTING',
      metaDesc: 'META_TAGS.ACCOUNTING_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ACCOUNTING, commonParams);
}

export function getServicesRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Services',
      metaTitle: 'META_TAGS.SERVICES',
      metaDesc: 'META_TAGS.SERVICES_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.SERVICES, commonParams);
}

export function getTradeRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Wholesale',
      metaTitle: 'META_TAGS.WHOLESALE',
      metaDesc: 'META_TAGS.WHOLESALE_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.TRADE, commonParams);
}

export function getWarehouseRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Warehouse',
      metaTitle: 'META_TAGS.WAREHOUSE',
      metaDesc: 'META_TAGS.WAREHOUSE_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.WAREHOUSE, commonParams);
}

export function getProductsRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Products',
      metaTitle: 'META_TAGS.PRODUCTS',
      metaDesc: 'META_TAGS.PRODUCTS_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.PRODUCTS, commonParams);
}

export function getAnalyticsRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Analytics',
      metaTitle: 'META_TAGS.ANALYTICS',
      metaDesc: 'META_TAGS.ANALYTICS_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ANALYTICS, commonParams);
}

export function getPartnersRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Partners',
      metaTitle: 'META_TAGS.PARTNERS',
      metaDesc: 'META_TAGS.PARTNERS_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.PARTNERS, commonParams);
}

export function getTeamRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Team',
      metaTitle: 'META_TAGS.TEAM',
      metaDesc: 'META_TAGS.TEAM_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.TEAM, commonParams);
}

export function getEcommerceRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple: E-Commerce für kleine Unternehmen 💳',
      metaTitle: 'META_TAGS.E_COMMERCE',
      metaDesc: 'META_TAGS.E_COMMERCE_DESC'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ECOMMERCE, commonParams);
}

export function getRetailRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple: Kassensystem für Einzelhandel \uD83D\uDECD',
      metaTitle: 'META_TAGS.RETAIL',
      metaDesc: 'META_TAGS.RETAIL_DESCR'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.RETAIL, commonParams);
}

export function getPrivacyPolicyRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
        title: 'Runple - Privacy policy',
        metaDesc: 'META_TAGS.PRIVACY_POLICY_DESCR',
        metaTitle: 'META_TAGS.PRIVACY_POLICY_TITLE',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.PRIVACY_POLICY, commonParams);
}

export function getTermsOfServicesRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
        title: 'Runple - Terms of service',
        metaDesc: 'META_TAGS.AGB_DESCR',
        metaTitle: 'META_TAGS.AGB_TITLE',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.TERMS_OF_SERVICE, commonParams);
}

export function getCookiePolicyRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Cookie policy',
      metaDesc: 'META_TAGS.COOKIES_DESCR',
      metaTitle: 'META_TAGS.COOKIES_TITLE',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.COOKIE_POLICY, commonParams);
}

export function getSLARoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - sla'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.SLA, commonParams);
}

export function getSubProcessorsRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Sub processors'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.SUB_PROCESSORS, commonParams);
}

export function getGDPRRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - gdpr'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.GDPR, commonParams);
}

export function getDataProcessingRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Data processing addendum'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.DATA_PROCESSING, commonParams);
}

export function getUserNoticeRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - User notice'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.USER_NOTICE, commonParams);
}

export function getServiceLevelAgreementRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Service level agreement'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.SERVICE_LEVEL_AGREEMENT, commonParams);
}

export function getAboutRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = { loadChildren };
  return getLazyModuleRoutesRoutes(RoutesEnum.ABOUT, commonParams);
}

export function getLegalRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      metaDesc: 'META_TAGS.LEGAL_DESCR',
      metaTitle: 'META_TAGS.LEGAL_TITLE',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.LEGAL, commonParams);
}

export function getErrorRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {title: 'Runple - 404'}
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ERROR, commonParams);
}

