import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExitModalComponent } from './exit-modal/exit-modal.component';



@NgModule({
  declarations: [ExitModalComponent],
  exports: [
    ExitModalComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ExitModalModule { }
