import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { EarlyAccessBannerComponent } from './early-access-banner.component';
import { ActionCardModule } from '../action-card/action-card.module';



@NgModule({
  declarations: [EarlyAccessBannerComponent],
  exports: [
    EarlyAccessBannerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ActionCardModule
  ]
})
export class EarlyAccessBannerModule { }
