<footer class="footer-wrapper">
  <div class="footer">
    <div class="footer-content-container">
      <div class="footer-content">
        <div class="footer-content-nav">
          <nav class="navigation" (clickOutside)="closeMenu()">
            <ul class="footer-content-nav-list">
              <li
                *ngFor="let item of navItems"
                class="footer-content-nav-item"
                [class.open]="isOpen && selectedItem === item.title"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <!--                <div class="footer-content-nav-item-title">-->
                <!--                  <span class="title">-->
                <!--                    {{ item?.title | translate }}-->
                <!--                  </span>-->
                <!--                  <i class="icon icon-arrow-micro-down arrow ml-auto"></i>-->
                <!--                  <div class="overlay" (click)="openSubMenu(item)"></div>-->
                <!--                </div>-->

                <ul *ngIf="item?.children" class="footer-content-nav-sub-list">
                  <ng-container *ngFor="let subItem of item?.children">
                    <li *ngIf="!subItem?.hide"
                        class="footer-content-nav-sub-item">
                      <a
                        *ngIf="subItem?.routerLink && !subItem?.disabled"
                        class="link"
                        [routerLink]="subItem?.routerLink"
                        routerLinkActive="active"
                        [attr.aria-label]="subItem?.title"
                        [routerLinkActiveOptions]="{ exact: true }" (click)="closeMenu()">

                      <span *ngIf="subItem?.img" class="img-container">
                        <img src="assets/img/footer/{{subItem?.img}}.svg" alt="" class="img">
                        <img src="assets/img/footer/{{subItem?.img}}_hover.svg" alt="" class="img hover">
                      </span>

                        {{ subItem?.title | translate }}
                      </a>

                      <a
                        *ngIf="subItem?.externalLink && !subItem?.disabled"
                        class="link"
                        target="_blank"
                        rel="noopener"
                        aria-label=""
                        [href]="subItem?.externalLink"
                        (click)="closeMenu()"
                      >

                      <span *ngIf="subItem?.img" class="img-container">
                        <img src="assets/img/footer/{{subItem?.img}}.svg" alt="" class="img">
                        <img src="assets/img/footer/{{subItem?.img}}_hover.svg" alt="" class="img hover">
                      </span>

                        {{ subItem?.title | translate }}
                      </a>

                      <span
                        *ngIf="subItem?.onClick"
                        class="link"
                        (click)="onClick(subItem.onClick)">
                      {{ subItem?.title | translate }}
                    </span>

                      <div *ngIf="subItem?.disabled" class="link disabled">
                        {{ subItem?.title | translate }}
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <div class="footer-content-control-item apps-link">
          <div class="app-link">
            <a href="https://www.teamviewer.co" target="_blank" rel="noopener" class="link" aria-label="">
              <ng-container *ngIf="translate?.currentLang !== 'en'">
                <img src="assets/img/footer/teamviewer.png" alt="" width="136" height="40">
                <!--                  <img class="hover" src="assets/img/footer/google_play_badge_blue_de.svg" alt="" width="136" height="40">-->
              </ng-container>
              <ng-container *ngIf="translate?.currentLang === 'en'">
                <img src="assets/img/footer/teamviewer.png" alt="" width="136" height="40">
                <!--                  <img class="hover" src="assets/img/footer/google_play_badge_blue_en.svg" alt="" width="136" height="40">-->
              </ng-container>
            </a>
          </div>

          <div class="app-link">
            <a href="https://www.pcvisit.de/kundem/de/download/free-nbereich/downloads/download-with-license-options/" target="_blank" rel="noopener" class="link" aria-label="">
              <img src="assets/img/footer/pcvisit.png" alt="" width="136" height="40">
            </a>
          </div>
        </div>
        <div class="footer-content-control">

          <!--          <div class="footer-content-control-item">-->
          <!--            <div class="footer-content-control-region">-->
          <!--              <rnpl-select-->
          <!--                [disabled]="false"-->
          <!--                [searchable]="false"-->
          <!--                [selectLangType]="true"-->
          <!--                [styles]="selectRegionConfig.styles"-->
          <!--                [labelIcon]="selectRegionConfig.labelIcon"-->
          <!--                [labelText]="selectRegionConfig.labelText"-->
          <!--                [selectItems]="selectRegionConfig.selectItems"-->
          <!--                [selectedValue]="selectedRegionValue$ | async"-->
          <!--                (changeSelectSourceValue)="changeRegion($event)">-->
          <!--              </rnpl-select>-->
          <!--            </div>-->
          <!--&lt;!&ndash;            <div class="footer-content-control-lang">&ndash;&gt;-->
          <!--&lt;!&ndash;              <rnpl-select&ndash;&gt;-->
          <!--&lt;!&ndash;                [searchable]="false"&ndash;&gt;-->
          <!--&lt;!&ndash;                [selectLangType]="true"&ndash;&gt;-->
          <!--&lt;!&ndash;                [styles]="selectLangConfig.styles"&ndash;&gt;-->
          <!--&lt;!&ndash;                [labelIcon]="selectLangConfig.labelIcon"&ndash;&gt;-->
          <!--&lt;!&ndash;                [labelText]="selectLangConfig.labelText"&ndash;&gt;-->
          <!--&lt;!&ndash;                [selectItems]="selectLangConfig.selectItems"&ndash;&gt;-->
          <!--&lt;!&ndash;                [selectedValue]="selectedLanguage$ | async"&ndash;&gt;-->
          <!--&lt;!&ndash;                (changeSelect)="changeLanguage($event)"&ndash;&gt;-->
          <!--&lt;!&ndash;              >&ndash;&gt;-->
          <!--&lt;!&ndash;              </rnpl-select>&ndash;&gt;-->
          <!--&lt;!&ndash;            </div>&ndash;&gt;-->
          <!--          </div>-->

          <div class="footer-content-control-item">
            <app-questions-action-block></app-questions-action-block>
          </div>



          <!--          <app-trustbox [isDarkTheme]="true" [height]="48"></app-trustbox>-->

        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-bottom-content">


        <div class="info">
          {{ 'FOOTER.ALL_RIGHTS' | translate: {year: currentYear} }}
        </div>

        <div class="logo">
          <a href="https://runple.com/at" target="_blank">
            <img src="assets/img/runple_logo_footer.svg" alt="runple logo" width="102" height="39"/>
          </a>
        </div>

        <!--        <div class="nav">-->
        <!--          <ul class="nav-list">-->
        <!--            <li *ngFor="let item of legalNavList" class="nav-item">-->
        <!--              <a [routerLink]="item?.routerLink" [attr.aria-label]="item?.title" class="link">-->
        <!--                {{ item?.title | translate }}-->
        <!--              </a>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </div>-->

      </div>
    </div>
  </div>
</footer>
