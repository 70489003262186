import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../locale/locale.service';

@Injectable({
  providedIn: 'root',
})
export class TitleService {

  private lastEvent = null;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private translateService: TranslateService,
    private meta: Meta,
    private localeService: LocaleService,
  ) {
    this.boot();

    this.translateService.onLangChange.subscribe(() => {
      this.updateMeta();
    });
  }

  boot() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this.lastEvent = event;
        this.updateMeta(event);
      });
  }

  public updateMeta(event = this.lastEvent): void {
    if (event) {
      this.lastEvent = event;
      this.meta.updateTag({
        property: 'og:url',
        content: this.document.location.href
      });
      this.updateMetaTitle(event?.metaTitle);
      this.updateMetaDescription(event?.metaDesc, event?.metaTitle);
    }
  }

  public updateMetaTitle(title: string): void {
    const newTitle = title
      ? this.translateService.instant(title)
      : this.translateService.instant('META_TAGS.DEFAULT');

    this.title.setTitle(newTitle);
    //
    // this.meta.updateTag({
    //   property: 'og:title',
    //   content: newTitle
    // });
    //
    this.meta.updateTag({
      name: 'title',
      content: newTitle
    });

    // this.meta.updateTag({
    //   name: 'twitter:title',
    //   content: newTitle
    // });
  }

  public updateMetaDescription(desc: string, title?: string): void {
    let newDesc = desc
      ? this.translateService.instant(desc)
      : this.translateService.instant('META_TAGS.DEFAULT_DESC');

    if (this.localeService.currentLocalePrefix === 'de' && title) {
      newDesc = title
        ? this.translateService.instant(title)
        : this.translateService.instant('META_TAGS.DEFAULT');
    }

    // this.meta.updateTag({
    //   property: 'og:description',
    //   content: newDesc
    // });

    this.meta.updateTag({
      name: 'description',
      content: newDesc
    });

    // this.meta.updateTag({
    //   name: 'twitter:description',
    //   content: newDesc
    // });
  }

  public addMetaRobotsFollowTag(): void {
    // this.meta.addTag({
    //   name: 'robots',
    //   property: 'robots',
    //   content: 'index, follow'
    // });
  }

  public addMetaRobotsNoFollowTag(): void {
    // this.meta.addTag({
    //   name: 'robots',
    //   property: 'robots',
    //   content: 'noindex, nofollow'
    // });
  }
}
