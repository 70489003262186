<!-- TrustBox widget - Micro Review Count -->
<div id="trustbox"
     class="trustpilot-widget"
     data-locale="de-AT"
     data-template-id="5419b6a8b0d04a076446a9ad"
     data-businessunit-id="618a84591f7e0196fd74d8d5"
     data-style-width="100%"
     [attr.data-style-height]="height + 'px'"
     [attr.data-theme]="isDarkTheme ? 'dark' : 'light'"
     data-min-review-count="10"
>
  <a href="https://at.trustpilot.com/review/runple.at" target="_blank" rel="noopener">Trustpilot</a>
</div>
<!-- End TrustBox widget -->
