<div *ngIf="!(isLoading$ | async); else skeleton" class="page">
  <div class="article-container">
    <div class="article-anchor-container">
      <div class="anchor-wrapper">
        <div class="all-articles-btn">
          <a [routerLink]="localeService.getLink(routesEnum.BLOG)" class="link">
            <i class="icon icon-arrow-right rotate-180"></i>
            <span class="title">{{ 'BLOG.ALL_ARTICLES' | translate }}</span>
          </a>
        </div>
        <ul *ngIf="postData?.anchors" class="anchor-list">
          <li *ngFor="let anchor of postData?.anchors"
              class="anchor"
              [routerLink]="'.'"
              [fragment]="anchor?.href"
          >
            {{ anchor?.title }}
          </li>
        </ul>
      </div>
    </div>

    <article class="article-body">
      <div class="article-data">
        <span class="categories-label" [style.backgroundColor]="postData?.category?.background_text">
          <span class="title" [style.color]="postData?.category?.color_text">
            {{ postData?.category?.name }}
          </span>
        </span>

        <span class="date">
          {{ postData?.createdDate | date: 'dd.MM.yyy' }}
        </span>
      </div>

      <div class="blog-title">
        <h1 class="article-title mb-0">{{postData?.title}}</h1>

        <div class="shared-container">
          <app-social-shared></app-social-shared>
        </div>

        <div *ngIf="!!postData?.post_preview" class="section-img">
          <img [src]="postData?.post_preview" alt="">
        </div>
      </div>

      <div [innerHTML]="postData?.post_description | saHtml"></div>


      <div class="blog-footer">
        <div class="author-info">
          <div class="avatar-container">
            <img [src]="postData?.author?.author_avatar" alt="">
          </div>
          <div class="name-container">
            <span class="additional-info">{{ 'BLOG.AUTHOR' | translate }}</span>
            <span class="name">
            <a [routerLink]="localeService.rootRoute + '/author/' + postData?.author?.author_url" class="link">
              {{ postData?.author?.full_name }}
            </a>
          </span>
            <span class="position">{{ postData?.author?.positions }}</span>
          </div>
        </div>

        <div class="shared-container">
          <app-social-shared></app-social-shared>
        </div>
      </div>


    </article>

  </div>

<!--  <div *ngIf="!!(readAlsoPosts$ | async)" class="also-post-container">-->
<!--    <div class="also-post-container-body">-->
<!--      <h4 class="title">{{ 'BLOG.READ_ALSO' | translate }}</h4>-->

<!--      <rnpl-posts-list-->
<!--        [articleList$]="readAlsoPosts$"-->
<!--        [categoryList]="categoryList"-->
<!--      >-->
<!--      </rnpl-posts-list>-->

<!--    </div>-->
<!--  </div>-->

  <div class="page-content-container page-action-card vertical d-block mb-40">
    <rnpl-early-access-banner
      [title]="'CTA.FINAL_CTA' | translate"
    >
    </rnpl-early-access-banner>
  </div>
</div>

<ng-template #skeleton>
  <app-blog-page-skeleton></app-blog-page-skeleton>
</ng-template>
