import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ActionCardComponent } from './action-card.component';
import { ButtonModule } from '../button/button.module';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
  declarations: [ActionCardComponent],
    imports: [CommonModule, ButtonModule, TranslateModule, PipesModule],
  exports: [ActionCardComponent],
})
export class ActionCardModule {}
