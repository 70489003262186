export enum FormNames {
  QUESTION = 'question',
  JOIN = 'join-runple-team',
  DEVELOPMENT = 'development',
  GET_STARTED_FREE = 'free',
  GET_STARTED = 'payment',
  EARLY_ACCESS = 'earlyAccess',
  SEND_EMAIL = 'sendEmail',
  DEMO = 'save-demo'
}

export interface QuestionFormModel {
  yourName: string;
  email: string;
  phone: string;
  yourQuestion: string;
}

export interface CaptchaModel {
  secret: string;
  response: string;
}

export interface JoinFormModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  desiredDepartment: string;
  position: string;
  professionalWebPresence: string;
  files: Array<File>;
}

export interface TryForFreeModel {
  companyName: string;
  workspaceUrl: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  subscribe?: string;
  agreement: boolean;
}

export interface GetStartedModel {
  plan: string;
  payment: string;
  support: boolean;
  setup: boolean;
  totalPrice: number;
  paymentDate: string;
  companyName: string;
  workspaceUrl: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  subscribe?: string;
  agreement: boolean;
}

export interface SavePriceModel {
  salesChannelPrice: number;
  perUserPrice: number;
  wholesale: boolean;
  eCommerce: boolean;
  period: string;
  services: boolean;
  support: boolean;
  totalPriceGross: number;
  usersTotalCount: number;
  propertyId?: number;
  id?: number;
}

export interface SaveGetStartedModel {
  propertyId?: number;
  companyName: string;
  workspaceUrl: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  initialSetup: boolean;
  termsAndConditions: boolean;
}
