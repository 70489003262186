import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ModalContainerComponent } from './ui-components/modal-container/modal-container.component';
import * as routesFactory from './locale/routes.factory';
import { HomeComponent } from './pages/home/home.component';
import { getTradeOfferRoutes, getWebshopRoutes } from './locale/routes.factory';

const routes: Routes = [
  ...routesFactory.getHomeRoutes(HomeComponent),
  // { path: '', component: HomeComponent },
  // { path: 'customer-care', component: CustomerCareComponent, data: {title: 'Runple - Customer care'} },
  // { path: 'einzelhandel' }, // for Retail
  // {
  //   path: 'release-notes',
  //   path: 'versionshinweise',
  //   loadChildren: () => import('./pages/release-notes/release-notes.module').then((m) => m.ReleaseNotesModule),
  //   data: {
  //     title: 'Runple - release notes',
  //     metaTitle: 'META_TAGS.RELEASE_NOTES'
  //   }
  // },
  { path: 'early-access-modal', component: ModalContainerComponent },
  ...routesFactory.getGetStartedRoutes(
    () => import('./pages/get-started/get-started.module')
      .then((m) => m.GetStartedModule)
  ),
  ...routesFactory.getPricingRoutes(
    () => import('./pages/pricing/pricing.module')
      .then((m) => m.PricingModule)
  ),
  ...routesFactory.getSystemRoutes(
    () => import('./pages/product/info/info.module')
      .then((m) => m.InfoModule)
  ),
  ...routesFactory.getAccountingRoutes(
    () => import('./pages/product/accounting/accounting.module')
      .then((m) => m.AccountingModule)
  ),
  ...routesFactory.getServicesRoutes(
    () => import('./pages/product/services/services.module')
      .then((m) => m.ServicesModule)
  ),
  ...routesFactory.getTradeRoutes(
    () => import('./pages/product/trade/trade.module')
      .then((m) => m.TradeModule)
  ),
  ...routesFactory.getWarehouseRoutes(
    () => import('./pages/product/warehouse/warehouse.module')
      .then((m) => m.WarehouseModule)
  ),
  ...routesFactory.getProductsRoutes(
    () => import('./pages/product/products/products.module')
      .then((m) => m.ProductsModule)
  ),
  ...routesFactory.getAnalyticsRoutes(
    () => import('./pages/product/analytics/analytics.module')
      .then((m) => m.AnalyticsModule)
  ),
  ...routesFactory.getPartnersRoutes(
    () => import('./pages/product/partners/partners.module')
      .then((m) => m.PartnersModule)
  ),
  ...routesFactory.getTeamRoutes(
    () => import('./pages/product/team/team.module')
      .then((m) => m.TeamModule)
  ),
  ...routesFactory.getEcommerceRoutes(
    () => import('./pages/product/shop/shop.module')
      .then((m) => m.ShopModule)
  ),
  ...routesFactory.getRetailRoutes(
    () => import('./pages/product/retail/retail.module')
      .then((m) => m.RetailModule)
  ),
  ...routesFactory.getPrivacyPolicyRoutes(
    () => import('./pages/legal/privacy-policy/privacy-policy.module')
      .then((m) => m.PrivacyPolicyModule)
  ),
  ...routesFactory.getTermsOfServicesRoutes(
    () => import('./pages/legal/terms-conditions/terms-conditions.module')
      .then((m) => m.TermsConditionsModule)
  ),
  ...routesFactory.getCookiePolicyRoutes(
    () => import('./pages/legal/cookies/cookies.module')
      .then((m) => m.CookiesModule)
  ),
  ...routesFactory.getSLARoutes(
    () => import('./pages/legal/service-level-agreement/service-level-agreement.module')
      .then((m) => m.ServiceLevelAgreementModule)
  ),
  ...routesFactory.getSubProcessorsRoutes(
    () => import('./pages/legal/sub-processors/sub-processors.module')
      .then((m) => m.SubProcessorsModule)
  ),
  ...routesFactory.getGDPRRoutes(
    () => import('./pages/legal/gdpr/gdpr.module')
      .then((m) => m.GdprModule)
  ),
  ...routesFactory.getDataProcessingRoutes(
    () => import('./pages/legal/data-processing-addendum/data-processing-addendum.module')
      .then((m) => m.DataProcessingAddendumModule)
  ),
  ...routesFactory.getUserNoticeRoutes(
    () => import('./pages/legal/user-notice/user-notice.module')
      .then((m) => m.UserNoticeModule)
  ),
  ...routesFactory.getServiceLevelAgreementRoutes(
    () => import('./pages/legal/service-level-agreement/service-level-agreement.module')
      .then((m) => m.ServiceLevelAgreementModule)
  ),
  ...routesFactory.getAboutRoutes(
    () => import('./pages/about/about.module')
      .then((m) => m.AboutModule)
  ),
  ...routesFactory.getLegalRoutes(
    () => import('./pages/legal/legal.module')
      .then((m) => m.LegalModule)
  ),
  ...routesFactory.getExternalRoutes(
    () => import('./pages/external/external.module').then((m) => m.ExternalModule)
  ),
  ...routesFactory.getWebshopRoutes(
    () => import('./pages/e-commerce-external/e-commerce-external.module').then((m) => m.ECommerceExternalModule)
  ),
  ...routesFactory.getTradeOfferRoutes(
    () => import('./pages/trade-offer-external/trade-offer-external.module').then((m) => m.TradeOfferExternalModule)
  ),
  // {
  //   path: 'dashboard',
  //   component: DashboardComponent,
  //   data: {
  //     title: 'Runple - Dashboard',
  //   }
  // },
  // {
  //   path: 'acceptable-use-policy',
  //   component: AcceptableUsePolicyComponent,
  //   data: {title: 'Runple - Acceptable use policy'}
  // },
  ...routesFactory.getBlogRoutes(() => import('./pages/blog/blog.module').then((m) => m.BlogModule)),
  ...routesFactory.getErrorRoutes(() => import('./pages/error/error.module').then((m) => m.ErrorModule)),
  // todo replace with lazy loaded modules
  ...routesFactory.getBlogNameRoutes(),
  ...routesFactory.getAuthorRoutes(),
  ...routesFactory.getLexiconNameRoutes(),
  ...routesFactory.getLexiconRoutes(),

  // @END todo replace with lazy loaded modules
  // {
  //   path: '404',
  //   loadChildren: () => import('./pages/error/error.module').then((m) => m.ErrorModule),
  //   data: {title: 'Runple - 404'}
  // },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
