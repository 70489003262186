import { Component } from '@angular/core';

import { PartnersLogoModel } from './our-certificate.model';

@Component({
  selector: 'rnpl-our-certificate',
  templateUrl: './our-certificate.component.html',
  styleUrls: ['./our-certificate.component.scss']
})

export class OurCertificateComponent {

  public items: Array <PartnersLogoModel> = [
    {
      logo: 'Microsoft.png',
      url: 'https://partner.microsoft.com/',
      name: 'Microsoft'
    },
    {
      logo: 'NFON.png',
      url: 'https://www.nfon.com/',
      name: 'NFON'
    },
    {
      logo: 'BMD.png',
      url: 'https://partner.hp.com/',
      name: 'BMD'
    },
    {
      logo: 'Lenovo.png',
      url: 'https://www.partnerwithlenovo.com/en',
      name: 'Lenovo'
    },
    {
      logo: '3CX.png',
      url: '',
      name: '3CX'
    },
    {
      logo: 'Runple.png',
      url: 'https://runple.com/at',
      name: 'Runple'
    },
  ];

}
