import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { animate, group, query, sequence, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-social-shared',
  templateUrl: './social-shared.component.html',
  styleUrls: ['./social-shared.component.scss'],
  animations: [
    trigger('copyLinkAnimation', [
      transition('* => visible', [

        group([
          query('visible, .checkmark', [
            style({
              opacity: 0,
              transform: 'scale(.3)',
            }),
            sequence( [
              animate(
                '250ms',
                style({ opacity: 1, transform: 'scale(1)'})
              ),
              animate(
                '300ms',
                style({ opacity: 1}),
              ),
              animate(
                '250ms',
                style({ opacity: 0, transform: 'scale(.3)'}),
              ),
            ]),
          ]),

          query('visible, .icon-link', [
            style({
              transform: 'scale(1)',
            }),
            sequence([
              animate(
                '250ms',
                style({
                  transform: 'scale(.3)'
                })
              ),
              animate(
                '300ms',
                style({
                  transform: 'scale(.3)'
                })
              ),
              animate(
                '250ms',
                style({
                  transform: 'scale(1)'
                })
              ),
            ]),
          ]),
        ])
      ]),
    ]),
  ]
})
export class SocialSharedComponent implements OnInit {

  public currentHref: string;

  public isCopied: boolean = false;

  public animationState: string = '';

  constructor(
    public router: Router
  ) { }

  ngOnInit() {
    this.currentHref = window.location.href;
  }

  public sharedLinkedIn(): void {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${this.currentHref}`, 'sharer', 'toolbar=0,status=0,width=620,height=390');
  }

  public showAnimation(): void {
    this.animationState = 'visible';

    setTimeout(() => {
      this.animationState = '';
    }, 1000);
  }

}
