import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from '../../locale/locale.service';

@Pipe({
  name: 'imagesByLocale'
})
export class ImagesByLocalePipe implements PipeTransform {

  constructor(public localeService: LocaleService) {
  }

  transform(imageNameByLocale?: {[key: string]: string}, optionalFilePat?: string, basicImageName?: string): string {
    const locale = this.localeService.currentLocalePrefix;
    let imageName;

    if (imageNameByLocale) {
      imageName = imageNameByLocale[locale] || imageNameByLocale['en'];
    }

    return `${!!locale ? locale + '/' : ''}assets/img/${optionalFilePat ? optionalFilePat + '/' : ''}${imageName || basicImageName}`;
  }

}
