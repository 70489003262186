import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { PrivacyAgreementComponent } from './privacy-agreement.component';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [PrivacyAgreementComponent],
  exports: [
    PrivacyAgreementComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    ButtonModule
  ]
})
export class PrivacyAgreementModule { }
