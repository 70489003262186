import {Component, OnInit, OnDestroy} from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { InputModel } from '../../input/input.model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ButtonModel } from '../../button/button.model';

import {
  E_MAIL,
  PHONE,
  YOUR_NAME,
  TEXTAREA, LAST_NAME, FIRST_NAME, COMPANY_NAME, REQUEST_ACCESS,
} from '../modal.config';
import { FormNames } from 'src/app/services/models/form.models';
import { RnplModalService } from 'src/app/services/rnpl-modal.service';
import { Router } from '@angular/router';
import { CaptchaCheckService } from '../../../services/captcha-check.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FormService } from '../../../services/forms.service';
import { LocaleService } from '../../../locale/locale.service';
import { RoutesEnum } from '../../../locale/routes.enum';


@Component({
  selector: 'app-early-access',
  templateUrl: './early-access.component.html',
  styleUrls: ['./early-access.component.scss'],
})
export class EarlyAccessComponent implements OnInit, OnDestroy {
  public name: InputModel = YOUR_NAME;
  public eMail: InputModel = E_MAIL;
  public tel: InputModel = PHONE;
  public textarea: any = TEXTAREA;
  public requestAccessButton: ButtonModel = REQUEST_ACCESS;
  public last: InputModel = LAST_NAME;
  public first: InputModel = FIRST_NAME;
  public company: InputModel = COMPANY_NAME;

  public earlyAccess = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl(''),
    companyName: new FormControl('', Validators.required),
    reCaptcha: new FormControl(''),
  });

  public activeComponentType: string;

  public formNames = FormNames;

  public ngbModalOptions: NgbModalOptions = {
    backdrop : 'static',
    keyboard : false,
  };

  constructor(
    public activeModal: NgbActiveModal,
    private modal: RnplModalService,
    private router: Router,
    private captchaService: CaptchaCheckService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private formService: FormService,
    private localeService: LocaleService,
  ) {}

  ngOnInit(): void {
    this.formService.checkOutValidForm();
  }

  public preSubmittingFormHandler(): void {
    this.formService.checkValidForm();
    this.formService.getActiveForm(FormNames.EARLY_ACCESS);

    this.recaptchaV3Service.execute('submitForm')
      .subscribe((token) => {
       this.captchaToken.setValue(token);
        this.submitForm();
      });
  }


  public submitForm() {
    if (this.earlyAccess.valid) {
      const formData = this.earlyAccess.getRawValue();
      const formName = this.activeComponentType || FormNames.EARLY_ACCESS;
      if (this.activeComponentType) {
        formData['type'] = this.activeComponentType;
      }
      this.modal.submitFormModal(formData, formName, this.activeModal, '/', this.ngbModalOptions);
    }
  }
  public redirectToHome () {
    this.router.navigate([this.localeService.rootRoute]);
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.formService.checkOutValidForm();
  }

  get firstName(): FormControl {
    return this.earlyAccess.get('firstName') as FormControl;
  }
  get lastName(): FormControl {
    return this.earlyAccess.get('lastName') as FormControl;
  }
  get email(): FormControl {
    return this.earlyAccess.get('email') as FormControl;
  }
  get phone(): FormControl {
    return this.earlyAccess.get('phone') as FormControl;
  }
  get companyName(): FormControl {
    return this.earlyAccess.get('companyName') as FormControl;
  }
  get captchaToken(): FormControl {
    return this.earlyAccess.get('reCaptcha') as FormControl;
  }
}
