import {SelectType} from '../../ui-components/select/select.model';

const CATEGORIES_LIST = [
  {
    id: 1,
    label: 'All categories',
  },
  {
    id: 2,
    label: 'Accounting',
  },
  {
    id: 3,
    label: 'Warehouse',
  },
];

export const BLOG_CATEGORIES_LIST_CONFIG = {
  defaultSelectionIndex: 0,
  styles: {
    type: SelectType.WHITE,
  },
};

