import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { InputType } from './input.model';
import { FormService } from '../../services/forms.service';

@Component({
  selector: 'rnpl-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public type: string;

  @Input()
  public domenName: string;

  @Input()
  public isOptional: boolean = false;

  @Input()
  public showPasswordIcon: boolean = false;

  @Input()
  public form?: FormGroup;

  @Input()
  public control: FormControl;

  @Input()
  public validIcon: boolean = false;

  @Input()
  public formName: string;

  @Input()
  public hideLabel: boolean;

  @Input()
  public emptyControlError: string;

  @Input()
  public isAutoFocus: boolean;

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  public errorMessage: string = 'INPUT.ERROR_REQUIRED';
  public errorConfirmdMessage: string = 'FREE.CONFIRM_ERROR';
  public emailError: string = 'FREE.EMAIL_ERROR_DEFAULT';
  public inputMask: string = null;
  public showPassword: boolean = false;

  @ViewChild('input', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    if(element && this.isAutoFocus) {
      element.nativeElement.focus()
    }
  }

  constructor(public formService: FormService) {
  }

  ngOnInit(): void {
    if (this.type === InputType.TEL) {
      this.inputMask = '+ 00 (000) 000-0000';
    }
  }

  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  get checkedValidControl(): boolean {
    return (this.formService.activeForm === this.formName && this.formService.isCheckValidForm);
  }
}
