import { Component, OnInit } from '@angular/core';
import {TOP_INFO_BLOC} from '../../pages/home/content-data/home-page.config';
import {LocaleService} from '../../locale/locale.service';

@Component({
  selector: 'app-detail-info-block',
  templateUrl: './detail-info-block.component.html',
  styleUrls: ['./detail-info-block.component.scss']
})
export class DetailInfoBlockComponent implements OnInit {

  public topInfoBlock = TOP_INFO_BLOC;

  constructor(
    public localeService: LocaleService,
  ) { }

  ngOnInit(): void {
  }

}
