<div class="skeleton-lexicon-nav-list">
  <ul class="skeleton-lexicon-list">
    <li class="skeleton-lexicon-list-item all skeleton-iem-animated"></li>
    <li *ngFor="let item of [].constructor(26)" class="skeleton-lexicon-list-item skeleton-iem-animated"></li>
  </ul>
</div>

<div class="skeleton-lexicon-body">

  <ul class="skeleton-lexicon-body-list">
    <ng-container *ngFor="let listItem of [].constructor(2)">
      <li *ngFor="let item of itemsWidthList" class="skeleton-lexicon-body-list-item">
        <div class="item title skeleton-iem-animated"></div>
        <div *ngFor="let width of item?.widthList"
             [style.width.px]="width"
             class="item skeleton-iem-animated"
        >
        </div>
      </li>
    </ng-container>
  </ul>

</div>
