import { Component } from '@angular/core';

@Component({
  selector: 'app-lexicon-list-skeleton',
  templateUrl: './lexicon-list-skeleton.component.html',
  styleUrls: ['./lexicon-list-skeleton.component.scss']
})
export class LexiconListSkeletonComponent {

  public itemsWidthList: Array<any> = [
    {
      widthList: [111, 300, 238, 156, 300, 259, 208, 254, 191]
    },
    {
      widthList: [274, 149, 188, 253, 149, 188, 243, 139, 218]
    },
    {
      widthList: [139, 233, 203, 270, 184, 203, 246, 169, 239]
    },
  ];

}
