import { FormControl } from '@angular/forms';

export interface SelectModel {
  selectItems: SelectItemModel[];
  defaultSelectionIndex: number;
  control?: FormControl;
  labelIcon?: string;
  labelText?: string;
  styles: SelectStylesModel;
  disabled?: boolean;
}

export interface SelectStylesModel {
  type: SelectType;
}

export interface SelectItemModel {
  id: number;
  label: string;
  value?: string;
  avatar?: string;
  icon?: string;
  [key: string]: any;
}

export enum SelectType {
  DARK = 'dark',
  WHITE = 'white',
}
