import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { ClickOutsideModule } from 'ng-click-outside';
import { FooterComponent } from './footer.component';
import { SelectModule } from '../select/select.module';
import { QuestionsActionBlockComponent } from './components/questions-action-block/questions-action-block.component';
import { TrustboxModule } from '../trustbox/trustbox.module';

@NgModule({
  declarations: [FooterComponent, QuestionsActionBlockComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    SelectModule,
    ClickOutsideModule,
    TrustboxModule
  ],
  exports: [FooterComponent, QuestionsActionBlockComponent],
  providers: []
})
export class FooterModule {}
