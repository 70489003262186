<div class="modal-wrapper">
  <div class="header">
    <div (click)="activeModal.dismiss('Close')" class="btn-container cursor-pointer">
      <i class="icon icon-close"></i>
    </div>
  </div>
  <h2 class="modal-title">{{ "MODAL.CONTACT_US" | translate }}</h2>

  <div class="form-container">
    <form>
      <div class="row">
        <div class="col-12">
          <rnpl-select
            [selectItems]="selectAction.selectItems"
            [defaultSelectionIndex]="selectAction.defaultSelectionIndex"
            [labelText]="selectAction.labelText"
            [styles]="selectAction.styles"
            (changeSelect)="activeModal.dismiss($event)"
            [searchable]="false"
          >
          </rnpl-select>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <rnpl-input
            [label]="first.label"
            [placeholder]="first.placeholder"
            [type]="first.type"
            [control]="firstName"
            [formName]="formNames.JOIN"
          >
          </rnpl-input>
        </div>

        <div class="col-6">
          <rnpl-input
            [label]="last.label"
            [placeholder]="last.placeholder"
            [type]="last.type"
            [control]="lastName"
            [formName]="formNames.JOIN"
          >
          </rnpl-input>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <rnpl-input
            [label]="eMail.label"
            [placeholder]="eMail.placeholder"
            [type]="eMail.type"
            [control]="email"
            [formName]="formNames.JOIN"
          >
          </rnpl-input>
        </div>

        <div class="col-6">
          <rnpl-input
            [label]="tel.label"
            [placeholder]="tel.placeholder"
            [type]="tel.type"
            [isOptional]="tel.isOptional"
            [control]="phone"
            [formName]="formNames.JOIN"
          >
          </rnpl-input>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <rnpl-select
            [selectItems]="department.selectItems"
            [labelText]="department.labelText"
            [styles]="department.styles"
            [control]="desiredDepartment"
            [searchable]="false"
            [placeholder]="'FORM.SELECT_DEPARTMENT'"
          >
          </rnpl-select>
        </div>

        <div class="col-6">
          <rnpl-select
            [selectItems]="desiredPosition.selectItems"
            [labelText]="desiredPosition.labelText"
            [styles]="desiredPosition.styles"
            [control]="position"
            [searchable]="false"
            [placeholder]="'FORM.SELECT_POSITION'"
          >
          </rnpl-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <rnpl-input
            [label]="socialPresence.label"
            [placeholder]="socialPresence.placeholder"
            [type]="socialPresence.type"
            [isOptional]="socialPresence.isOptional"
            [control]="professionalWebPresence"
            [formName]="formNames.JOIN"
          >
          </rnpl-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <rnpl-file-loader
            [label]="fileLoaderConf.label"
            [icon]="fileLoaderConf.icon"
            [control]="fileLoader">
          </rnpl-file-loader>
        </div>
      </div>
    </form>

    <div class="button">
      <rnpl-button
        [title]="submitButton.title"
        [styles]="submitButton.styles"
        [iconName]="submitButton.iconName"
        (click)="preSubmittingFormHandler()">
      </rnpl-button>
    </div>

    <div class="bottom-descr-container">
      <p class="descr">
        {{ "MODAL.YOU_CAN_ALSO_CONTACT" | translate }}<br>
        <a class="link" href="mailto:info@runple.com">info@runple.com</a>
      </p>
    </div>

    <p class="recaptcha-info">
      {{ "CAPTCHA_INFO.SITE_PROTECTED" | translate: {buttonName: 'FORM.SUBMIT' | translate} }}
      <a target="_blank" href="https://policies.google.com/privacy" rel="nofollow">{{ "CAPTCHA_INFO.PRIVACY" | translate}}</a> {{ "CAPTCHA_INFO.AND" | translate}}
      <a target="_blank" href="https://policies.google.com/terms" rel="nofollow">{{ "CAPTCHA_INFO.TERMS" | translate}}</a>.
    </p>
  </div>
</div>
