export interface ButtonStylesModel {
  reverse?: boolean;
  bgColor?: ButtonColor;
  textColor?: ButtonColor;
  width?: number;
  height?: number;
  iconCenter?: boolean;
  widthFull?: boolean;
  iconColor?: ButtonColor;
}

export enum ButtonColor {
  BLUE = 'blue',
  WHITE = 'white',
  GREEN = 'green',
  GOLD = 'gold',
  GREY = 'grey',
  BLACK = 'black',
  WHITE_BLUE = 'white-blue',
  DARK = 'dark',
}

export interface ButtonModel {
  title: string;
  iconName?: string;
  styles: ButtonStylesModel;
  disabled?: boolean;
}

export enum ButtonTitles {
  TRY_FOR_FREE = 'try for free',
  KNOW_MORE = 'know more',
  GET_EARLY_ACCESS = 'get early access',
  SEE_PRICING = 'see pricing',
}
