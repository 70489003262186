import { Component } from '@angular/core';

@Component({
  selector: 'app-blog-page-skeleton',
  templateUrl: './blog-page-skeleton.component.html',
  styleUrls: ['./blog-page-skeleton.component.scss']
})
export class BlogPageSkeletonComponent {

  public anchorSkeletonItems = [82, 222, 176, 116, 222, 192, 154, 188, 141];
  public descrSkeletonItems = [776, 422, 532, 717, 422, 532, 688, 394, 617];

}
