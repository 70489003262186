<header class="header-container"
        [class.scrolled]="isScrolled"
        [class.isOpenNav]="isOpenNav"
        [class.display-anchor]="stickyAnchorService.displayAnchorSticky"
>
<!--  <app-exit-modal></app-exit-modal>-->
  <div
    class="header"
    (clickOutside)="hideMenuHandler(); stickyAnchorService.isOpenHeadNav = false">
    <div class="header-logo" (click)="hideMenuHandler(); stickyAnchorService.isOpenHeadNav = false">
      <a class="link" [routerLink]="localeService.rootRoute" aria-label="Runple">
        <img src="assets/img/it-work.png" style="width: 100%" alt="">
      </a>
    </div>
    <div class="header-nav-container">
      <nav class="header-nav" [class.tablet]="isTablet">
        <ul class="header-nav-list">
          <ng-container *ngFor="let item of navItems">
            <li
              *ngIf="item.children"
              class="header-nav-item with-arrow"
              routerLinkActive="active"
              [class.tablet]="isTablet"
              [class.open]="!isHide"
              [class.isOpened]="isOpenSubMenu"
              (click)="openSubMenu(item)"
              (clickOutside)="isOpenSubMenu = false;"
              (mouseenter)="displayMenuHandler(); isOpenSubMenu = true; stickyAnchorService.isOpenHeadNav = true"
              (mouseleave)="isOpenSubMenu = false; stickyAnchorService.isOpenHeadNav = false"
            >
              <span class="item-title with-arrow">
                {{ item.title | translate }}
                <span class="arrow"></span>
              </span>

              <div class="header-nav-item-sub-container" [ngClass]="hideMenu ? 'hide' : 'show'" [class.tablet]="isTablet">

                <div class="header-sub-menu-container">
                  <div class="header-sub-menu-nav">
                    <div *ngFor="let subItem of subMenuItems" class="header-sub-menu-nav-item">
                      <h5 class="header-sub-menu-nav-item-title">
                        {{ subItem?.title | translate }}
                      </h5>

                      <ul class="nav-items-list">
                        <li *ngFor="let item of subItem?.children" class="nav-item" (click)="closeMenu($event); stickyAnchorService.isOpenHeadNav = false">
                          <div class="nav-icon-container" [ngClass]="item?.iconColor">
                            <i class="icon icon-{{item?.icon}}"></i>
                          </div>

                          <div class="title-container">
                            <div class="title-wrapper">
                              <h5 class="title">
                                {{item?.title | translate}}
                              </h5>

                              <span *ngIf="!!item?.label" class="label">
                                {{item?.label | translate}}
                              </span>
                            </div>
                            <p class="description">
                              {{item?.descr | translate}}
                            </p>
                          </div>

                          <i class="icon icon-arrow-right-small"></i>

                          <a *ngIf="!!item?.routerLink && !item?.disabled" [routerLink]="item?.routerLink" [attr.aria-label]="item?.title" class="link"></a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="header-sub-menu-footer">
                    <div class="nav-item" (click)="closeMenu($event); stickyAnchorService.isOpenHeadNav = false">
                      <div class="nav-icon-container">
                        <img src="assets/img/runple-icon-dark.svg" alt="" class="img">
                        <img src="assets/img/runple-logo-white.svg" alt="" class="img img-hover">
                      </div>

                      <div class="title-container">
                        <h5 class="title">
                          {{'NAV.WANT_TO_LEARN' | translate}}
                        </h5>

                        <p class="description link">
                          {{'HOME.DISCOVER_MORE' | translate}}

                          <i class="icon icon-arrow-right-small"></i>
                        </p>
                      </div>

                      <a [routerLink]="localeService.getLink(routesEnum.SYSTEM)" aria-label="system" class="link"></a>
                    </div>
                  </div>
                </div>

              </div>

            </li>

            <li
              *ngIf="!item.children"
              class="header-nav-item"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }" (click)="hideMenuHandler()">
              <ng-container *ngIf="!item?.href">
                <a *ngIf="!item?.actionModal" class="item-link" [attr.aria-label]="item?.title" [routerLink]="item.routerLink">
                  <span class="item-title">
                    {{ item.title | translate }}
                  </span>
                </a>
                <a *ngIf="item?.actionModal" [attr.aria-label]="item?.title" class="item-link" (click)="openEarlyAccessModal()">
                  <span class="item-title">
                    {{ item.title | translate }}
                  </span>
                </a>
              </ng-container>
              <a *ngIf="item?.href" class="item-link" [attr.aria-label]="item?.title" [href]="item.href">
                <span class="item-title">
                  {{ item.title | translate }}
                </span>
              </a>
            </li>
          </ng-container>

<!--          <li class="header-nav-list-button">-->
<!--            <rnpl-button-->
<!--              [title]="'NAV.TRY_FOR_FREE'"-->
<!--              [styles]="buttonConfigNav"-->
<!--              [iconName]="'rocket'"-->
<!--              (click)="goToGetStarted(); closeMenu()">-->
<!--            </rnpl-button>-->
<!--          </li>-->

        </ul>
      </nav>
    </div>

    <div class="header-button-container">
      <div class="header-nav-toggle">
        <rnpl-button
          [styles]="buttonToggleConfig"
          [iconName]="isOpenNav ? 'close' : 'menu-2'"
          (click)="openMenu(); stickyAnchorService.isOpenHeadNav = isOpenNav">
        </rnpl-button>
      </div>
<!--      <div class="header-nav-toggle-icon" (click)="openMenu()">-->
<!--        <i class="icon icon-{{isOpenNav ? 'close' : 'menu-2'}}"></i>-->
<!--      </div>-->
      <div *ngIf="displayButtonTryForFree" class="header-button">
        <rnpl-button
          [title]="'NAV.CONTACT_US1'"
          [styles]="buttonConfig"
          [iconName]="'email'"
          [typeSmall]="true"
          (click)="openAskQuestionModal()">
        </rnpl-button>
      </div>
    </div>
  </div>
</header>

<div class="header-overlay"></div>

<div class="header-sub-menu-wrapper" [class.open]="isOpenSubMenu" (mouseleave)="isOpenSubMenu = false; stickyAnchorService.isOpenHeadNav = false" (mouseenter)="isOpenSubMenu = true; stickyAnchorService.isOpenHeadNav = true">
  <div class="header-sub-menu-container">
    <div class="header-sub-menu-nav">
      <div *ngFor="let subItem of subMenuItems" class="header-sub-menu-nav-item">
        <h5 class="header-sub-menu-nav-item-title">
          {{ subItem?.title | translate }}
        </h5>

        <ul class="nav-items-list">
          <li *ngFor="let item of subItem?.children" class="nav-item">
            <div class="nav-icon-container" [ngClass]="item?.iconColor">
              <i class="icon icon-{{item?.icon}}"></i>
            </div>

            <div class="title-container">
              <div class="title-wrapper">
                <h5 class="title">
                  {{item?.title | translate}}
                </h5>

                <span *ngIf="!!item?.label" class="label">
                {{item?.label | translate}}
              </span>
              </div>
              <p class="description">
                {{item?.descr | translate}}
              </p>
            </div>

            <i class="icon icon-arrow-right-small"></i>

            <a *ngIf="!!item?.routerLink && !item?.disabled" [routerLink]="item?.routerLink" [attr.aria-label]="item?.title" class="link"></a>
          </li>
        </ul>
      </div>
    </div>

    <div class="header-sub-menu-footer">
      <div class="nav-item">
        <div class="nav-icon-container">
          <img src="assets/img/runple-icon-dark.svg" alt="" class="img">
          <img src="assets/img/runple-logo-white.svg" alt="" class="img img-hover">
        </div>

        <div class="title-container">
          <h5 class="title">
            {{'NAV.WANT_TO_LEARN' | translate}}
          </h5>

          <p class="description link">
            {{'HOME.DISCOVER_MORE' | translate}}

            <i class="icon icon-arrow-right-small"></i>
          </p>
        </div>

        <a [routerLink]="localeService.getLink(routesEnum.SYSTEM)" [attr.aria-label]="'system'" class="link"></a>
      </div>
    </div>
  </div>
</div>

