import {FileLoaderModel} from '../file-loader/file-loader.model';
import {InputModel, InputType} from '../input/input.model';
import {ButtonColor, ButtonModel} from '../button/button.model';
import {SelectModel, SelectType} from '../select/select.model';
import {FormNames} from 'src/app/services/models/form.models';

export enum ContactModalType {
  QUESTION = 'question',
  REQUEST = 'request',
  EARLY_ACCESS = 'earlyAccess',
}

export const FILE_LOADER: FileLoaderModel = {
  label: 'FORM.CV',
  icon: 'cloud-upload',
};

export const YOUR_NAME: InputModel = {
  label: 'FORM.YOUR_NAME',
  placeholder: 'FORM.FIRST_NAME',
  type: InputType.TEXT,
};

export const FIRST_NAME: InputModel = {
  label: 'FORM.FIRST_NAME',
  placeholder: 'FORM.ENTER_FIRST_NAME',
  type: InputType.TEXT,
};

export const LAST_NAME: InputModel = {
  label: 'FORM.LAST_NAME',
  placeholder: 'FORM.ENTER_LAST_NAME',
  type: InputType.TEXT,
};

export const E_MAIL: InputModel = {
  label: 'FORM.E_MAIL',
  placeholder: 'FORM.ENTER_E_MAIL',
  type: InputType.TEXT,
};

export const PHONE: InputModel = {
  label: 'FORM.PHONE_NUMBER',
  placeholder: 'FORM.ENTER_PHONE_NUMBER',
  type: InputType.NUM,
  isOptional: true,
};

export const COMPANY_NAME: InputModel = {
  label: 'FORM.COMPANY_NAME',
  placeholder: 'FORM.ENTER_COMPANY_NAME',
  type: InputType.TEXT,
};


export const PRESENCE: InputModel = {
  label: 'FORM.WEB_PRESENCE',
  placeholder: 'FORM.EXAMPLE',
  type: InputType.TEXT,
  isOptional: true,
};

export const SUBMIT_BUTTON: ButtonModel = {
  title: 'FORM.SUBMIT',
  iconName: 'email',
  styles: {
    bgColor: ButtonColor.BLUE,
    textColor: ButtonColor.WHITE,
    height: 64,
    widthFull: true,
    iconColor : ButtonColor.WHITE,
  },
};

export const REQUEST_ACCESS: ButtonModel = {
  title: 'FORM.REQUEST_ACCESS',
  iconName: 'email',
  styles: {
    bgColor: ButtonColor.BLUE,
    textColor: ButtonColor.WHITE,
    height: 64,
    widthFull: true,
  },
};

export const CONTINUE_BUTTON: ButtonModel = {
  title: 'MODAL.CONTINUE',
  styles: {
    bgColor: ButtonColor.GREY,
    textColor: ButtonColor.GREY,
    height: 64,
  },
};

export const RETRY_BUTTON: ButtonModel = {
  title: 'MODAL.RETRY',
  styles: {
    bgColor: ButtonColor.GREY,
    textColor: ButtonColor.GREY,
    height: 64,
    iconColor: ButtonColor.GREY,
  },
};

const selectItems = [
  {
    id: 1,
    label: 'FORM.ASK_QUESTIONS',
    value: FormNames.QUESTION,
  },
  {
    id: 2,
    label: 'FORM.JOIN_TEAM',
    value: FormNames.JOIN,
  },
  {
    id: 3,
    label: 'MODAL.REQUEST_DEVELOPMENT',
    value: FormNames.DEVELOPMENT,
  },
];

export const SELECT_ACTION_QUESTION = {
  selectItems: selectItems,
  defaultSelectionIndex: 0,
  labelText: 'FORM.I_WANT',
  styles: {
    type: SelectType.WHITE,
  },
};

export const SELECT_ACTION_JOIN = {
  selectItems: selectItems,
  defaultSelectionIndex: 1,
  labelText: 'FORM.I_WANT',
  styles: {
    type: SelectType.WHITE,
  },
};

export const SELECT_ACTION_REQUEST = {
  selectItems: selectItems,
  defaultSelectionIndex: 2,
  labelText: 'FORM.I_WANT',
  styles: {
    type: SelectType.WHITE,
  },
};

export const SELECT_ACTION_EARLY = {
  selectItems: selectItems,
  defaultSelectionIndex: 3,
  labelText: 'FORM.I_WANT',
  styles: {
    type: SelectType.WHITE,
  },
};


export const DEPARTMENT: SelectModel = {
  selectItems: [
    {
      id: 1,
      label: 'FORM.BACK_END',
    },
    {
      id: 2,
      label: 'FORM.FRONTEND',
    },
    {
      id: 3,
      label: 'FORM.QUALITY_ASSURANCE',
    },
    {
      id: 4,
      label: 'FORM.DESIGN',
    },
    {
      id: 5,
      label: 'FORM.MARKETING',
    },
    {
      id: 6,
      label: 'FORM.CUSTOMER_CARE',
    },
    {
      id: 7,
      label: 'FORM.MANAGEMENT',
    },
    {
      id: 8,
      label: 'FORM.SALES',
    },
    {
      id: 9,
      label: 'FORM.HUMAN_RESOURCE',
    },
  ],
  defaultSelectionIndex: 0,
  labelText: 'FORM.DEPARTMENT',
  styles: {
    type: SelectType.WHITE,
  },
};

export const POSITION: SelectModel = {
  selectItems: [
    {
      id: 1,
      label: 'FORM.SOFTWARE_ENGINEER',
    },
    {
      id: 2,
      label: 'FORM.DEVOPS',
    },
    {
      id: 3,
      label: 'FORM.QA',
    },
    {
      id: 4,
      label: 'FORM.UI_UX',
    },
    {
      id: 5,
      label: 'FORM.BA',
    },
    {
      id: 6,
      label: 'FORM.MARKETING_SPECIALIST',
    },
    {
      id: 7,
      label: 'FORM.TECHNICAL_WRITER',
    },
    {
      id: 8,
      label: 'FORM.SUPPORT_SPECIALIST',
    },
    {
      id: 9,
      label: 'FORM.PROJECT_MANAGER',
    },
    {
      id: 10,
      label: 'FORM.SALES_MANAGER',
    },
    {
      id: 11,
      label: 'FORM.HR',
    },
  ],
  defaultSelectionIndex: 0,
  labelText: 'FORM.POSITION',
  styles: {
    type: SelectType.WHITE,
  },
};

export const TEXTAREA = {
  label: 'INPUT.YOUR_QUESTION',
  placeholder: 'INPUT.YOUR_MESSAGE',
};
