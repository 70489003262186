import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

import {ACTION_CARDS} from '../../pages/home/content-data/action-card.config';
import { LocaleService } from '../../locale/locale.service';
import { RoutesEnum } from '../../locale/routes.enum';

@Component({
  selector: 'app-channels-block',
  templateUrl: './channels-block.component.html',
  styleUrls: ['./channels-block.component.scss']
})
export class ChannelsBlockComponent implements OnInit {

  public actionCards = ACTION_CARDS;
  public isDisplayNarrowScreen;
  public routesEnum = RoutesEnum;

  public imageByCardName = {
    ['INVENTORY_MANAGEMENT']: {
      en: 'inventory_management_software_for_trade.webp',
      de: 'Runple_Bestandsverwaltungssystem.webp',
      at: 'Runple_Bestandsverwaltungssystem.webp'
    },
    ['CLEAR_TODO_LIST']: {
      en: 'Fachwissen.svg',
      de: 'Fachwissen.svg',
      at: 'Fachwissen.svg'
    },
    ['WORK_CONFIDENTLY']: {
      en: 'Flexibilitaet.svg',
      de: 'Flexibilitaet.svg',
      at: 'Flexibilitaet.svg'
    },
    ['SOFTWARE_FOR_SERVICE_PROVIDERS']: {
      en: 'software_for_service_providers.webp',
      de: 'Software_für_Dienstleistungsanbieter.webp',
      at: 'Software_für_Dienstleistungsanbieter.webp'
    },
    ['ACCOUNTING']: {
      en: 'accounting.webp',
      de: 'Buchhaltungssoftware_aus_Österreich.webp',
      at: 'Buchhaltungssoftware_aus_Österreich.webp'
    },
    ['WAREHOUSE']: {
      en: 'warehouse.webp',
      de: 'Online_Lagerverwaltungssoftware.webp',
      at: 'Online_Lagerverwaltungssoftware.webp'
    },
    ['ACCOUNTING_REPORTS_IS_AS']: {
      en: 'Innovationskraft.svg',
      de: 'Innovationskraft.svg',
      at: 'Innovationskraft.svg'
    },
    ['PRODUCTS']: {
      en: 'products.webp',
      de: 'Runple_WaWi.webp',
      at: 'Runple_WaWi.webp'
    },
    ['PARTNERS']: {
      en: 'partners.webp',
      de: 'Runple_Kundenbeziehungsmanagement.webp',
      at: 'Runple_Kundenbeziehungsmanagement.webp'
    },
    ['TEAM']: {
      en: 'team.webp',
      de: 'Runple_HRM.webp',
      at: 'Runple_HRM.webp'
    },
    ['ANALYTICS']: {
      en: 'analytics.webp',
      de: 'Runple_Analytik.webp',
      at: 'Runple_Analytik.webp'
    },
    ['PROBABLY_THE_BEST_CUSTOMER']: {
      en: 'Transparenz und Kommunikation.svg',
      de: 'Transparenz und Kommunikation.svg',
      at: 'Transparenz und Kommunikation.svg'
    },
  }

  constructor (
    private router: Router,
    public localeService: LocaleService,
    private breakpointObserver: BreakpointObserver,
    ) {
    // detect screen size changes
    this.breakpointObserver.observe([
      '(max-width: 481px)'
    ]).subscribe((result: BreakpointState) => {
      this.isDisplayNarrowScreen = result.matches;
    });
  }

  ngOnInit(): void {
  }

  public gotToGetStarted() {
    this.router.navigate(['/anmelden']);
  }

}
