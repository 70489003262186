<div class="textarea-wrapper">
  <label>{{ label | translate }}</label>
  <textarea
    [formControl]="control"
    placeholder="{{ placeholder | translate }}"
    [class.error]="control.invalid && ((control.dirty && control.touched) || checkedValidControl)"
  ></textarea>
  <div
    *ngIf="control.invalid && ((control.dirty && control.touched) || checkedValidControl)"
    class="error-message"
  >
    <i class="icon icon-alert-circle"></i>
    {{ "INPUT.ERROR_MESSAGE" | translate }}
  </div>
</div>
