import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  FormNames,
  GetStartedModel,
  JoinFormModel,
  QuestionFormModel,
  SaveGetStartedModel,
  SavePriceModel,
  TryForFreeModel,
} from './models/form.models';
import { ResponseModel } from './models/response.model';
import { CheckWebshopResponse } from '../pages/e-commerce-external/web-shop/web-shop.component';
import { LocaleService } from '../locale/locale.service';
import { LocalePrefixesEnum } from '../locale/locale-prefixes.enum';
import { RoutesEnum } from '../locale/routes.enum';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private contactUsUrl = 'contact-us';
  private questionDevelopmentUrl = 'add';
  private getStartedUrl = '';

  public isCheckValidForm: boolean = false;
  public activeForm: string;

  public formData: any;

  constructor(
    private http: HttpClient,
    private localeService: LocaleService
  ) {
    this.localeService.currentLocalePrefix$.subscribe((locale: LocalePrefixesEnum) => {
      this.getStartedUrl = this.localeService.getLink(RoutesEnum.GET_STARTED);
    });
  }

  public sendRequest(formData, formName): Observable<any> {
    switch (formName) {
      case FormNames.QUESTION:
      case FormNames.DEVELOPMENT:
      case FormNames.EARLY_ACCESS:
        return this.sendQuestion(
          formData,
          `/${this.contactUsUrl}/${this.questionDevelopmentUrl}`
        );
      case FormNames.JOIN:
        return this.joinTeam(
          formData,
          `/${this.contactUsUrl}/${formName}`
        );
      case FormNames.GET_STARTED_FREE:
        return this.getStartedFree(
          formData,
          `/${this.getStartedUrl}/${formName}`
        );
      case FormNames.GET_STARTED:
        return this.getStarted(
          formData,
          `/${this.getStartedUrl}/${formName}`
        );
      case FormNames.DEMO:
        return this.getStarted(
          formData,
          `/get-started/${formName}`
        );
    }
  }

  public sendQuestion(
    questionForm: QuestionFormModel,
    url
  ): Observable<QuestionFormModel> {
    return this.http
      .post<ResponseModel<QuestionFormModel>>(url, questionForm)
      .pipe(
        map((response: ResponseModel<QuestionFormModel>) => {
          return response.data;
        }),
      );
  }

  public joinTeam(joinForm: JoinFormModel, url): Observable<JoinFormModel> {
    return this.http.post<ResponseModel<JoinFormModel>>(url, this.toFormDataWithFiles(joinForm)).pipe(
      map((response: ResponseModel<JoinFormModel>) => {
        return response.data;
      }),
      catchError(this.handleError)
    );
  }

  public getStartedFree(
    getStartedFree: TryForFreeModel,
    url
  ): Observable<TryForFreeModel> {
    return this.http
      .post<ResponseModel<TryForFreeModel>>(url, getStartedFree)
      .pipe(
        map((response: ResponseModel<TryForFreeModel>) => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  public getStarted(
    getStarted: GetStartedModel,
    url
  ): Observable<GetStartedModel> {
    return this.http.post<ResponseModel<GetStartedModel>>(url, getStarted).pipe(
      map((response: ResponseModel<GetStartedModel>) => {
        return response.data;
      }),
      // catchError(this.handleError)
    );
  }

  public savePrices(savePriceForm: SavePriceModel): Observable<SavePriceModel> {
    return this.http.post<ResponseModel<SavePriceModel>>('/get-started/save-prices', savePriceForm).pipe(
      map((response: ResponseModel<SavePriceModel>) => {
        return response.data;
      }),
      catchError(this.handleError)
    );
  }

  public saveGetStarted(getStartedForm: SaveGetStartedModel): Observable<SaveGetStartedModel> {
    this.formData = getStartedForm;

    return this.http.post<ResponseModel<SaveGetStartedModel>>('/get-started/save', getStartedForm).pipe(
      map((response: ResponseModel<SaveGetStartedModel>) => {
        return response.data;
      }),
      // catchError(this.handleError)
    );
  }

  public validateWorkspaceDomain(domain: string): Observable<any> {
    const params = new HttpParams().set('domain', domain);
    return this.http.get<ResponseModel<any>>('/hq/v1/workspace/validate', { params })
      .pipe(
        map((response: ResponseModel<any>) => {
          return response.data;
        })
      );
  }

  public getLegalTypes(countryCodeIso3 = 'AUT'): Observable<any> {
    const params = new HttpParams().set('countryCodeIso3', countryCodeIso3);
    return this.http.get<ResponseModel<any>>('/accounting/v1/utils/profile/legal-types', { params });
  }

  public tradeOfferConfirmation(token: string): Observable<any> {
    return this.http.post(`/api/v1/trade-offers/reply`,
      {
        secret: token
      }
    );
  }

  public checkWebshopOrigin(origin: string): Observable<CheckWebshopResponse> {
    const params = new HttpParams().set('Origin', origin);
    return this.http.get<CheckWebshopResponse>(`/accounting/v1/company/profile/check-webshop`, { params });
  }

  public checkValidForm() {
    this.isCheckValidForm = true;
  }

  public checkOutValidForm() {
    this.isCheckValidForm = false;
  }

  private handleError(error: HttpErrorResponse) {
    return throwError('Something bad happened; please try again later.');
  }

  public getActiveForm(formName: string = '') {
    this.activeForm = formName;
  }

  public toFormDataWithFiles<T>( formValue: any ) {
    const formData = new FormData();

    for ( const key of Object.keys(formValue) ) {
      const value = formValue[key];
      formData.append(key, value);
    }

    if (formValue?.fileLoader?.length) {
      for (let i = 0; i < formValue?.fileLoader?.length; i++) {
        formData.append('files[]', formValue?.fileLoader[i], formValue?.fileLoader[i]?.name);
      }
    }

    return formData;
  }
}
