<div *ngIf="!(isLoading$ | async); else skeleton" class="page">

  <div class="profile-container">
    <div class="profile">
      <div class="profile-avatar">
        <img [src]="authorProfile?.author_avatar" alt="Author avatar" class="">
      </div>
      <div class="profile-name-container">
        <h1 class="name">
          {{ authorProfile?.full_name }}
        </h1>
        <p class="descr">
          {{ authorProfile?.positions }}
        </p>
      </div>
    </div>

<!--    <div class="profile-content">-->

<!--      <div class="profile-description-container">-->
<!--        <p class="descr">-->
<!--          {{ authorProfile?.description }}-->
<!--        </p>-->
<!--      </div>-->

<!--      <div *ngIf="authorProfile?.linkedin_link || authorProfile?.xing_link" class="profile-social-container">-->

<!--        <ul class="social-list">-->
<!--          <li *ngIf="!!authorProfile?.linkedin_link" class="social">-->
<!--            <a [href]="authorProfile?.linkedin_link" class="link" target="_blank" rel="nofollow">-->
<!--                <span class="social-icon">-->
<!--                  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <g id="01.-Blog" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                          <g class="linkedin-icon" id="05_Runple_Author_1440" transform="translate(-320.000000, -299.000000)" fill="#9CA4BE">-->
<!--                              <g id="Author-box" transform="translate(232.000000, 124.000000)">-->
<!--                                  <g id="linkedin" transform="translate(88.000000, 172.000000)">-->
<!--                                      <g id="linkedin-box-fill" transform="translate(0.000000, 3.000000)">-->
<!--                                          <path d="M15.335,15.339 L12.67,15.339 L12.67,11.162 C12.67,10.166 12.65,8.884 11.28,8.884 C9.891,8.884 9.679,9.968 9.679,11.089 L9.679,15.339 L7.013,15.339 L7.013,6.75 L9.573,6.75 L9.573,7.92 L9.608,7.92 C9.966,7.246 10.836,6.533 12.136,6.533 C14.836,6.533 15.336,8.311 15.336,10.624 L15.336,15.339 L15.335,15.339 Z M4.003,5.57500126 C3.79956,5.57526 3.59806,5.53537 3.41006,5.45761 C3.22207,5.37984 3.05127,5.26574 2.90746,5.12184 C2.76365,4.97793 2.64965,4.80706 2.57201,4.61901 C2.49437,4.43097 2.4546,4.22944 2.45499701,4.026 C2.4552,3.71983 2.54618,3.4206 2.71644,3.16615 C2.8867,2.91169 3.12859,2.71343 3.41153,2.59645 C3.69447,2.47947 4.00574,2.44902 4.30598,2.50894 C4.60622,2.56886 4.88196,2.71648 5.09831,2.93311 C5.31466,3.14974 5.46191,3.42566 5.52145,3.72598 C5.58099,4.0263 5.55013,4.33753 5.43278,4.62032 C5.31543,4.9031 5.11687,5.14474 4.86219,5.31467 C4.60751,5.4846 4.30817,5.5752 4.002,5.57500126 L4.003,5.57500126 Z M5.339,15.339 L2.666,15.339 L2.666,6.75 L5.34,6.75 L5.34,15.339 L5.339,15.339 Z M16.67,0 L1.329,0 C0.593,0 0,0.58 0,1.297 L0,16.703 C0,17.42 0.594,18 1.328,18 L16.666,18 C17.4,18 18,17.42 18,16.703 L18,1.297 C18,0.58 17.4,0 16.666,0 L16.669,0 L16.67,0 Z" id="Shape"></path>-->
<!--                                      </g>-->
<!--                                  </g>-->
<!--                              </g>-->
<!--                          </g>-->
<!--                      </g>-->
<!--                  </svg>-->
<!--                </span>-->
<!--              <span class="social-name">-->
<!--                  LinkedIn-->
<!--                </span>-->
<!--            </a>-->
<!--          </li>-->

<!--          <li *ngIf="!!authorProfile?.xing_link" class="social">-->
<!--            <a [href]="authorProfile?.xing_link" class="link" target="_blank" rel="nofollow">-->
<!--                <span class="social-icon">-->
<!--                  <svg width="17px" height="20px" viewBox="0 0 17 20" version="1.1" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <g id="01.-Blog" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                          <g class="xing-icon" id="05_Runple_Author_1440" transform="translate(-418.000000, -298.000000)" fill="#9CA4BE">-->
<!--                              <g id="Author-box" transform="translate(232.000000, 124.000000)">-->
<!--                                  <g id="xing" transform="translate(186.000000, 172.000000)">-->
<!--                                      <g id="xing-fill" transform="translate(0.000000, 2.000000)">-->
<!--                                          <path d="M15.75086,0 C15.90386,0 16.05786,0.078 16.13486,0.155 C16.17266,0.22595 16.19246,0.30511 16.19246,0.3855 C16.19246,0.4659 16.17266,0.54506 16.13486,0.616 L10.05786,11.38599 L13.90386,18.46199 C13.94186,18.53309 13.96176,18.61239 13.96176,18.69299 C13.96176,18.77359 13.94186,18.85289 13.90386,18.92399 C13.79876,19.02019 13.66226,19.07489 13.51986,19.07799 L10.74986,19.07799 C10.36586,19.07799 10.13486,18.76999 9.98086,18.53899 L6.05786,11.38499 C6.28886,11.07799 12.21186,0.54 12.21186,0.54 C12.36586,0.233 12.59686,0.002 12.98186,0.002 L15.75086,0.002 L15.75086,0 Z M4.21186,3.77 C4.59687,3.77 4.82686,4.078 4.98186,4.308 L6.90386,7.61599 C6.75086,7.77 3.90386,12.84599 3.90386,12.84599 C3.75086,13.07799 3.51986,13.38599 3.13486,13.38599 L0.44286,13.38599 C0.30009,13.38309 0.16324,13.32839 0.05786,13.23199 C0.01987,13.16089 0,13.08159 0,13.00099 C0,12.92039 0.01987,12.84109 0.05786,12.77 L2.90386,7.61599 L1.05786,4.386 C1.01987,4.31493 1,4.23559 1,4.155 C1,4.07441 1.01987,3.99507 1.05786,3.924 C1.16324,3.82762 1.30009,3.77288 1.44286,3.77 L4.21286,3.77 L4.21186,3.77 Z" id="Shape"></path>-->
<!--                                      </g>-->
<!--                                  </g>-->
<!--                              </g>-->
<!--                          </g>-->
<!--                      </g>-->
<!--                  </svg>-->
<!--                </span>-->
<!--              <span class="social-name">-->
<!--                  XING-->
<!--                </span>-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->

    <div *ngIf="!!authorProfile?.posts && !!authorProfile?.posts?.length" class="more-post-container">
      <h2 class="title">
        {{ 'BLOG.ARTICLE_BY' | translate: {full_name: authorProfile?.full_name} }}
      </h2>

      <ul class="posts-list">
        <li *ngFor="let post of authorProfile?.posts" class="post">
          <a [routerLink]="post?.routerLink" class="link">
            <span class="date">
              {{post?.createdDate | date: 'dd.MM.yyyy'}}
            </span>
              <span class="title">
              {{ post?.title}}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>


<ng-template #skeleton>
  <app-author-skeleton></app-author-skeleton>
</ng-template>
