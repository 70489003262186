import { SelectItemModel, SelectModel, SelectType } from '../select/select.model';

export const SELECT_ITEMS_LIST_REGION: Array<SelectItemModel> = [
  {
    id: 1,
    label: 'SELECT.AUSTRIA',
    isoCode: 'AT',
    avatar: 'assets/img/countries/austria.svg',
  },
  {
    id: 2,
    label: 'SELECT.GERMANY',
    isoCode: 'DE',
    avatar: 'assets/img/countries/germany.svg',
  },
  // {
  //   id: 3,
  //   label: 'SELECT.SWITZERLAND',
  //   isoCode: 'CH',
  //   avatar: 'assets/img/countries/switzerland.svg',
  // },
  {
    id: 4,
    label: 'SELECT.GLOBAL',
    isoCode: 'other',
    avatar: 'assets/img/countries/global.svg',
  },
];

export const SELECT_ITEMS_LIST_LANG: Array<SelectItemModel> = [
  {
    id: 1,
    label: 'English',
    avatar: 'assets/img/countries/united-kingdom.svg',
    isoCode: 'en',
  },
  {
    id: 2,
    label: 'Deutsch',
    avatar: 'assets/img/countries/austria.svg',
    isoCode: 'de',
  },
];

export const SELECT_REGION_CONFIG: SelectModel = {
  selectItems: SELECT_ITEMS_LIST_REGION,
  defaultSelectionIndex: 0,
  labelText: 'SELECT.REGION',
  labelIcon: 'globe',
  styles: {
    type: SelectType.DARK,
  },
};

export const SELECT_LANG_CONFIG: SelectModel = {
  selectItems: SELECT_ITEMS_LIST_LANG,
  defaultSelectionIndex: 0,
  labelText: 'SELECT.LANGUAGE',
  labelIcon: 'translate',
  styles: {
    type: SelectType.DARK,
  },
};
