<div *ngIf="isBrowser" class="carousel-wrapper">
  <ul class="carousel-nav">
    <li
      *ngFor="let item of carouselTitle; let i = index"
      class="carousel-nav-item"
      [class.active]="selectedIndex === (i + 1)"
      [class.show-animation]="(showProgress$ | async)"
      [class.isPaused]="(animationStateIsPaused$ | async) || !isSliderScrolledIntoView"
      (click)="selectSlide(i + 1)"
    >
      <span class="title"> {{ item | translate }}</span>
      <span class="progress"></span>
    </li>
  </ul>

  <ul
    class="carousel-nav small-screen"
    [ngClass]="selectorName + '-nav'"
  >
    <li
      *ngFor="let item of carouselConfig; let i = index"
      class="carousel-nav-item"
      [class.active]="selectedIndex === item.index"
      [class.show-animation]="(showProgress$ | async)"
      [class.isPaused]="(animationStateIsPaused$ | async) || !isSliderScrolledIntoView"
      (click)="selectSlide(item.index)"
    >
      <span class="title"> {{ item?.title | translate }}</span>
      <span class="progress"></span>
    </li>
  </ul>

  <div #slider class="carousel-container" [ngClass]="selectorName" (click)="pause()">
    <div
      *ngFor="let item of carouselConfig; let i = index"
      class="slide"
    >
      <img
        class="img"
        (touchstart)="handleTouchStart($event)"
        (touchmove)="handleTouchMove($event)"
        src="{{ {} | imagesByLocale: 'home/carousel': item?.img + '.webp' }}" [alt]="item?.alt | translate"
      >
    </div>
  </div>

  <button
    class="btn pause"
    aria-label="Pause"
    (click)="pause()"
    [class.onPaused]="(animationStateIsPaused$ | async)"
    (touchstart)="handleTouchStart($event)"
    (touchmove)="handleTouchMove($event)"
  >
    <i *ngIf="!(animationStateIsPaused$ | async)" class="icon icon-pause-circle"></i>
    <i *ngIf="(animationStateIsPaused$ | async)" class="icon icon-play-circle"></i>
  </button>

</div>
