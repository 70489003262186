<button
  class="btn btn-{{ styles?.bgColor || 'primary' }}"
  [class.disabled]="disabled"
  [style.width.px]="styles?.width"
  [style.height.px]="styles?.height"
  [class.iconCenter]="styles?.iconCenter"
  [class.w-full]="styles?.widthFull"
  [class.with-icon]="!!iconName"
  [class.reverse]="styles?.reverse"
>
  <a *ngIf="isMailToType" class="link" href="mailto:info@runple.com"></a>
  <span class="btn-title btn-title-{{ styles?.textColor || 'primary' }}" [class.sm]="typeSmall">
    {{ title | translate }}
  </span>
  <i *ngIf="iconName" class="icon icon-{{ iconName }}" [ngClass]="styles?.iconColor ? styles?.iconColor : ''"></i>
  <img *ngIf="img" [src]="img" [class.with-hover]="imgHover" alt="">
  <img *ngIf="imgHover" class="hover" [src]="imgHover" alt="">
</button>
