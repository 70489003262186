<div class="modal-wrapper">
  <div class="header cursor-pointer">
    <div class="btn-container" (click)="activeModal.dismiss('Close')">
      <i class="icon icon-close"></i>
    </div>
  </div>
  <div class="content d-flex flex-column">
    <div class="modal-img">
      <img src="{{ {} | imagesByLocale: '': 'demo_cat.webp' }}" alt="modal's image" />
    </div>

    <h4 class="modal-title" [class.device-depended]="!!modalData?.title375" [innerText]="modalData?.title | translate"></h4>
    <h4 *ngIf="!!modalData?.title375" class="modal-title device-375" [innerText]="modalData?.title375 | translate"></h4>

    <p class="modal-descr" [innerText]="modalData?.descr | translate"></p>

    <div class="form-container">
      <form>
        <div class="row">
          <div class="col-12">
            <rnpl-input
              [label]="'Name'"
              [placeholder]="'Name'"
              [type]="inputType.TEXT"
              [control]="name"
              [formName]="formNames.DEMO"
            >
            </rnpl-input>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <rnpl-input
              [label]="'E-Mail-Adresse'"
              [placeholder]="'E-Mail-Adresse'"
              [type]="inputType.EMAIL"
              [control]="email"
              [formName]="formNames.DEMO"
            >
            </rnpl-input>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <rnpl-input
              [label]="'FORM.PHONE_NUMBER'"
              [placeholder]="'FORM.ENTER_PHONE_NUMBER'"
              [type]="inputType.NUM"
              [control]="phone"
              [isOptional]="true"
              [formName]="formNames.DEMO"
            >
            </rnpl-input>
          </div>
        </div>

      </form>
    </div>

    <div class="button">
      <rnpl-button
        [title]="submitButton.title"
        [styles]="submitButton.styles"
        [iconName]="submitButton.iconName"
        (click)="preSubmittingFormHandler()">
      </rnpl-button>
    </div>

    <p class="recaptcha-info">
      {{ "CAPTCHA_INFO.SITE_PROTECTED" | translate: {buttonName: 'REQUEST_DEMO.REQUEST_DEMO' | translate} }}
      <a target="_blank" href="https://policies.google.com/privacy" rel="nofollow">{{ "CAPTCHA_INFO.PRIVACY" | translate}}</a> {{ "CAPTCHA_INFO.AND" | translate}}
      <a target="_blank" href="https://policies.google.com/terms" rel="nofollow">{{ "CAPTCHA_INFO.TERMS" | translate}}</a>.
    </p>
  </div>
</div>
