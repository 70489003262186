import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoCardsBlockComponent } from './info-cards-block.component';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../../pipes/pipes.module';



@NgModule({
  declarations: [
    InfoCardsBlockComponent
  ],
  exports: [
    InfoCardsBlockComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        PipesModule
    ]
})
export class InfoCardsBlockModule { }
