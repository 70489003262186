import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header.component';
import { ButtonModule } from '../button/button.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { ExitModalModule } from '../exit-modal/exit-modal.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    ButtonModule,
    ClickOutsideModule,
    ExitModalModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
