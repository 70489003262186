<div class="page-item">
<!--  <h2 *ngIf="!isDisplayNarrowScreen" class="channel-title mt-0" [innerText]="'HOME.EASIER_ROUTINE' | translate"></h2>-->
<!--  <h2 *ngIf="isDisplayNarrowScreen" class="channel-title mt-0" [innerText]="'HOME.EASIER_ROUTINE_375' | translate"></h2>-->

  <div class="page-content-container-item channel-container">
    <div class="primary-card-container type-3-1-1">
      <div class="card card-type-2 card-1 img-animation">
        <div class="title-container">
          <div class="icon-container icon-container">
            <img src="{{ {} | imagesByLocale: 'home/it-work': 'IT-Infrastruktur & Netzwerktechnik.svg' }}">
<!--            [alt]="'IMAGES_ALT.ABOUT.ALT_18' | translate"-->
          </div>
<!--          <div class="icon-container icon-container-yellow">-->
<!--            <i class="icon icon-cash"></i>-->
<!--          </div>-->

          <h4 class="title mt-4"><!--mw-428-->
            {{ 'HOME.INVENTORY_MANAGEMENT' | translate }}
          </h4>

        </div>

        <div class="description-container">
          <p class="description">
            {{ 'HOME.INVENTORY_MANAGEMENT_DESCR' | translate }}
          </p>

<!--          <div class="content-link">-->
<!--            <a [routerLink]="localeService.getLink(routesEnum.TRADE)" class="link">-->
<!--              {{ 'HOME.DISCOVER_MORE' | translate }}-->
<!--              <i class="icon icon-arrow-right-small"></i>-->
<!--            </a>-->
<!--          </div>-->
        </div>

<!--        <div class="img mt-auto">-->
<!--          <img class=""-->
<!--               src="{{imageByCardName['INVENTORY_MANAGEMENT'] | imagesByLocale: 'home'}}"-->
<!--               [alt]="'IMAGES_ALT.HOME.ALT_21' | translate"-->
<!--               width="776"-->
<!--               height="376"-->
<!--          >-->
<!--        </div>-->
      </div>

      <div class="card card-type-2 card-2 detailed-info-card turquoise">
        <div class="title-container">
          <h4 class="title mb-0">
            {{ 'HOME.CLEAR_TODO_LIST' | translate }}
          </h4>


          <div class="img">
            <img src="{{imageByCardName['CLEAR_TODO_LIST'] | imagesByLocale: 'home/it-work'}}"
                 [alt]="'IMAGES_ALT.HOME.ALT_23' | translate"
                 width="296"
                 height="296"
            >
          </div>
          <p class="descr">
            {{'HOME.DONT_KEEP_ORDER' | translate }}
          </p>

        </div>
      </div>
    </div>

    <div class="primary-card-container type-1-3-1">
      <div class="card card-type-2 card-1 detailed-info-card black">
        <div class="title-container">
          <h4 class="title mb-0">
            {{ 'HOME.SPELL_CHECKER' | translate }}
          </h4>

          <div class="img">
            <img src="{{imageByCardName['WORK_CONFIDENTLY'] | imagesByLocale: 'home/it-work'}}"
                 [alt]="'IMAGES_ALT.HOME.ALT_18' | translate"
                 width="296"
                 height="296"
            >
          </div>
          <p class="descr">
            {{ 'HOME.WORK_CONFIDENTLY' | translate}}
          </p>


        </div>
      </div>

      <div class="card card-type-2 card-2 img-animation">
        <div class="title-container">
          <div class="icon-container icon-container">
            <img src="{{ {} | imagesByLocale: 'home/it-work': 'Managed Hardware.svg' }}">
            <!--            [alt]="'IMAGES_ALT.ABOUT.ALT_18' | translate"-->
          </div>

          <h4 class="title mt-4"><!--mw-428-->
            {{ 'PRICING.SOFTWARE_FOR_SERVICE_PROVIDERS' | translate }}
          </h4>
        </div>

        <div class="description-container">
          <p class="description">
            {{ 'HOME.SOFTWARE_FOR_SERVICE_PROVIDERS_DESCR' | translate }}
          </p>

<!--          <div class="content-link">-->
<!--            <a [routerLink]="localeService.getLink(routesEnum.SERVICES)" class="link">-->
<!--              {{ 'HOME.DISCOVER_MORE' | translate }}-->
<!--              <i class="icon icon-arrow-right-small"></i>-->
<!--            </a>-->
<!--          </div>-->
        </div>

<!--        <div class="img mt-auto">-->
<!--          <img class=""-->
<!--               src="{{imageByCardName['SOFTWARE_FOR_SERVICE_PROVIDERS'] | imagesByLocale: 'home'}}"-->
<!--               [alt]="'IMAGES_ALT.HOME.ALT_15' | translate"-->
<!--               width="776"-->
<!--               height="376"-->
<!--          >-->
<!--        </div>-->
      </div>
    </div>

    <div class="primary-card-container mb-0 type-1">
      <rnpl-action-card
        [title]="actionCards[0].title"
        [buttonConfig]="actionCards[0].buttonConfig"
        [styles]="actionCards[0].styles"
        [img]="actionCards[0].img"
        (buttonClicked)="gotToGetStarted()">
      </rnpl-action-card>
    </div>
  </div>

</div>

<div class="page-item">
<!--  <h2 *ngIf="!isDisplayNarrowScreen" class="channel-title mt-0"  [innerText]="'HOME.FRUSTRATING_FEATURES' | translate"></h2>-->
<!--  <h2 *ngIf="isDisplayNarrowScreen" class="channel-title mt-0"  [innerText]="'HOME.FRUSTRATING_FEATURES_375' | translate"></h2>-->

  <div class="page-content-container-item channel-container">
    <div class="primary-card-container type-1-1">
      <div class="card card-type-2 card-1 img-animation">
        <div class="title-container">
          <div class="icon-container icon-container">
            <img src="{{ {} | imagesByLocale: 'home/it-work': 'Projektberatung & Durchfuehrung.svg' }}">
            <!--            [alt]="'IMAGES_ALT.ABOUT.ALT_18' | translate"-->
          </div>

          <h4 class="title mt-4"><!--mw-428-->
            {{ 'HOME.ACCOUNTING' | translate }}
          </h4>

        </div>

        <div class="description-container">
          <p class="description">
            {{ 'HOME.ACCOUNTING_DESCR' | translate }}
          </p>

<!--          <div class="content-link">-->
<!--            <a [routerLink]="localeService.getLink(routesEnum.ACCOUNTING)" class="link">-->
<!--              {{ 'HOME.DISCOVER_MORE' | translate }}-->
<!--              <i class="icon icon-arrow-right-small"></i>-->
<!--            </a>-->
<!--          </div>-->
        </div>

<!--        <div class="img mt-auto">-->
<!--          <img class="" src="{{imageByCardName['ACCOUNTING'] | imagesByLocale: 'home'}}" [alt]="'IMAGES_ALT.HOME.ALT_13' | translate" width="576" height="376">-->
<!--        </div>-->
      </div>

      <div class="card card-type-2 card-2 img-animation">
        <div class="title-container">
          <div class="icon-container icon-container">
            <img src="{{ {} | imagesByLocale: 'home/it-work': 'Cloud- & On-Premise Loesungen.svg' }}">
            <!--            [alt]="'IMAGES_ALT.ABOUT.ALT_18' | translate"-->
          </div>

          <h4 class="title mt-4"><!--mw-428-->
            {{ 'HOME.WAREHOUSE' | translate }}
          </h4>

        </div>

        <div class="description-container">
          <p class="description">
            {{ 'HOME.WAREHOUSE_DESCR' | translate }}
          </p>

<!--          <div class="content-link">-->
<!--            <a [routerLink]="localeService.getLink(routesEnum.WAREHOUSE)" class="link">-->
<!--              {{ 'HOME.DISCOVER_MORE' | translate }}-->
<!--              <i class="icon icon-arrow-right-small"></i>-->
<!--            </a>-->
<!--          </div>-->
        </div>

<!--        <div class="img mt-auto">-->
<!--          <img class="" src="{{imageByCardName['WAREHOUSE'] | imagesByLocale: 'home'}}" [alt]="'IMAGES_ALT.HOME.ALT_6' | translate" width="576" height="376">-->
<!--        </div>-->
      </div>
    </div>

    <div class="primary-card-container type-1-1-1">

      <div class="card card-type-2 card-1 detailed-info-card turquoise">
        <div class="title-container">
          <h4 class="title mb-0">
            {{ 'HOME.ACCOUNTING_REPORTS_IS_AS' | translate }}
          </h4>


          <div class="img">
            <img src="{{imageByCardName['ACCOUNTING_REPORTS_IS_AS'] | imagesByLocale: 'home/it-work'}}" [alt]="'IMAGES_ALT.HOME.ALT_8' | translate" width="296" height="296">
          </div>
          <p class="descr">
            {{ 'HOME.YOU_DONT_HAVE_TO_SPEND' | translate }}
          </p>

        </div>
      </div>

      <div class="card card-type-2 card-2 img-animation">
        <div class="title-container">
          <div class="icon-container icon-container">
            <img src="{{ {} | imagesByLocale: 'home/it-work': 'Remote Workplaces.svg' }}">
            <!--            [alt]="'IMAGES_ALT.ABOUT.ALT_18' | translate"-->
          </div>

          <h4 class="title mt-4">
            {{ 'HOME.PRODUCTS' | translate }}
          </h4>

        </div>

        <div class="description-container">
          <p class="description">
            {{ 'HOME.PRODUCTS_DESCR' | translate }}
          </p>

<!--          <div class="content-link">-->
<!--            <a [routerLink]="localeService.getLink(routesEnum.PRODUCTS)" class="link">-->
<!--              {{ 'HOME.DISCOVER_MORE' | translate }}-->
<!--              <i class="icon icon-arrow-right-small"></i>-->
<!--            </a>-->
<!--          </div>-->
        </div>

<!--        <div class="img mt-auto">-->
<!--          <img class="" src="{{imageByCardName['PRODUCTS'] | imagesByLocale: 'home'}}" [alt]="'IMAGES_ALT.HOME.ALT_7' | translate" width="376" height="376">-->
<!--        </div>-->
      </div>

      <div class="card card-type-2 card-3 img-animation">
        <div class="title-container">
          <div class="icon-container icon-container">
            <img src="{{ {} | imagesByLocale: 'home/it-work': 'Spezialsoftware fuÌr Rechtsanwaelte & Steuerberater.svg' }}">
            <!--            [alt]="'IMAGES_ALT.ABOUT.ALT_18' | translate"-->
          </div>

          <h4 class="title mt-4">
            {{ 'HEADER.PARTNERS' | translate }}
          </h4>

        </div>

        <div class="description-container">
          <p class="description">
            {{ 'HOME.PARTNERS_DESCR' | translate }}
          </p>

<!--          <div class="content-link">-->
<!--            <a [routerLink]="localeService.getLink(routesEnum.PARTNERS)" class="link">-->
<!--              {{ 'HOME.DISCOVER_MORE' | translate }}-->
<!--              <i class="icon icon-arrow-right-small"></i>-->
<!--            </a>-->
<!--          </div>-->
        </div>

<!--        <div class="img mt-auto">-->
<!--          <img class="" src="{{imageByCardName['PARTNERS'] | imagesByLocale: 'home'}}" [alt]="'IMAGES_ALT.HOME.ALT_17' | translate" width="376" height="376">-->
<!--        </div>-->
      </div>

    </div>

    <div class="primary-card-container type-3-1-1">
      <div class="card card-type-2 card-1 img-animation">
        <div class="title-container">
          <div class="icon-container icon-container">
            <img src="{{ {} | imagesByLocale: 'home/it-work': 'Voice-over-IP-Telefonie.svg' }}">
            <!--            [alt]="'IMAGES_ALT.ABOUT.ALT_18' | translate"-->
          </div>

          <h4 class="title mt-4"><!--mw-428-->
            {{ 'HOME.TEAM' | translate }}
          </h4>

        </div>

        <div class="description-container">
          <p class="description">
            {{ 'HOME.TEAM_DESCR' | translate }}
          </p>

          <!--          <div class="content-link">-->
          <!--            <a [routerLink]="localeService.getLink(routesEnum.TRADE)" class="link">-->
          <!--              {{ 'HOME.DISCOVER_MORE' | translate }}-->
          <!--              <i class="icon icon-arrow-right-small"></i>-->
          <!--            </a>-->
          <!--          </div>-->
        </div>

        <!--        <div class="img mt-auto">-->
        <!--          <img class=""-->
        <!--               src="{{imageByCardName['INVENTORY_MANAGEMENT'] | imagesByLocale: 'home'}}"-->
        <!--               [alt]="'IMAGES_ALT.HOME.ALT_21' | translate"-->
        <!--               width="776"-->
        <!--               height="376"-->
        <!--          >-->
        <!--        </div>-->
      </div>

      <div class="card card-type-2 card-2 detailed-info-card black">
        <div class="title-container">
          <h4 class="title mb-0">
            {{ 'HOME.PROBABLY_THE_BEST_CUSTOMER' | translate }}
          </h4>


          <div class="img">
                <img src="{{imageByCardName['PROBABLY_THE_BEST_CUSTOMER'] | imagesByLocale: 'home/it-work'}}" [alt]="'IMAGES_ALT.HOME.ALT_3' | translate" width="296" height="296">
          </div>
          <p class="descr">
            {{'HOME.YOU_WANT_BE_CONFUSED' | translate }}
          </p>

        </div>
      </div>
    </div>

<!--    <div class="primary-card-container type-1-1-1">-->

<!--      <div class="card card-type-2 card-1 img-animation">-->
<!--        <div class="title-container">-->
<!--          <div class="icon-container icon-container-sky">-->
<!--            <i class="icon icon-people"></i>-->
<!--          </div>-->

<!--          <h4 class="title">-->
<!--            {{ 'HOME.TEAM' | translate }}-->
<!--          </h4>-->

<!--        </div>-->

<!--        <div class="description-container">-->
<!--          <p class="description">-->
<!--            {{ 'HOME.TEAM_DESCR' | translate }}-->
<!--          </p>-->

<!--          <div class="content-link">-->
<!--            <a [routerLink]="localeService.getLink(routesEnum.TEAM)" class="link">-->
<!--              {{ 'HOME.DISCOVER_MORE' | translate }}-->
<!--              <i class="icon icon-arrow-right-small"></i>-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="img mt-auto">-->
<!--          <img class="" src="{{imageByCardName['TEAM'] | imagesByLocale: 'home'}}" [alt]="'IMAGES_ALT.HOME.ALT_33' | translate" width="376" height="376">-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="card card-type-2 card-2 img-animation">-->
<!--        <div class="title-container">-->
<!--          <div class="icon-container icon-container-mint">-->
<!--            <i class="icon icon-business-intelligence"></i>-->
<!--          </div>-->

<!--          <h4 class="title">-->
<!--            {{ 'FOOTER.ANALYTICS' | translate }}-->
<!--          </h4>-->

<!--        </div>-->

<!--        <div class="description-container">-->
<!--          <p class="description">-->
<!--            {{ 'HOME.ANALYTICS_DESCR' | translate }}-->
<!--          </p>-->

<!--          <div class="content-link">-->
<!--            <a [routerLink]="localeService.getLink(routesEnum.ANALYTICS)" class="link">-->
<!--              {{ 'HOME.DISCOVER_MORE' | translate }}-->
<!--              <i class="icon icon-arrow-right-small"></i>-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="img mt-auto">-->
<!--          <img class="" src="{{imageByCardName['ANALYTICS'] | imagesByLocale: 'home'}}" [alt]="'IMAGES_ALT.HOME.ALT_14' | translate" width="376" height="376">-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="card card-type-2 card-3 detailed-info-card red">-->
<!--        <div class="title-container">-->
<!--          <p class="descr">-->
<!--            {{ 'HOME.YOU_WANT_BE_CONFUSED' | translate }}-->
<!--          </p>-->

<!--          <div class="img">-->
<!--            <img src="{{imageByCardName['PROBABLY_THE_BEST_CUSTOMER'] | imagesByLocale: 'home'}}" [alt]="'IMAGES_ALT.HOME.ALT_3' | translate" width="296" height="296">-->
<!--          </div>-->

<!--          <h4 class="title mb-0">-->
<!--            {{ 'HOME.PROBABLY_THE_BEST_CUSTOMER' | translate }}-->
<!--          </h4>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
