export interface RectangleModel {
  bgColor?: InfoRectangleColor;
  textColor?: InfoRectangleColor;
  title?: string;
  iconName?: string;
  img?: string;
  index?: number;
  small?: boolean;
}

export enum InfoRectangleColor {
  PINK = 'pink',
  SKY = 'sky',
  ORANGE = 'orange',
  GREEN = 'green',
  VIOLET = 'violet',
  MINT = 'mint',
  PURPLE = 'purple',
  GREY = 'grey',
  GREY_DARK = 'grey-dark',
  GREY_LIGHT = 'grey-light',
  BROWN = 'brown',
  YELLOW = 'yellow',
  RED = 'red',
  BLUE = 'blue',
  VIOLET_DARK = 'violet-dark',
  WHITE = 'white',
  BLUE_600 = 'blue-600',
  BLUE_100 = 'blue-100',
  RED_500 = 'red-500',
  RED_100 = 'red-100',
}
