import {InfoRectangleColor} from '../../../ui-components/info-rectangle/info-rectangle.model';

export function getBusinessDirectionInfo(
  retailLink: string,
  tradeLink: string,
  ecoLink: string,
  servicesLink: string,
) {
  return [
    {
      backgroundImage: {
        en: 'illustration_retail.webp',
        de: 'ERP-System_für_Einzelhandel.webp',
        at: 'ERP-System_für_Einzelhandel.webp',
      },
      title: 'HOME.RETAIL',
      link: retailLink,
      disabled: false,
      class: 'blue',
      infoRectangleData: {
        bgColor: InfoRectangleColor.BLUE_100,
        textColor: InfoRectangleColor.WHITE,
        title: 'HOME.AVAILABLE_IN_2023',
        small: true,
      },
      alt: 'IMAGES_ALT.HOME.ALT_26'
    },
    {
      backgroundImage: {
        en: 'illustration_wholesale.webp',
        de: 'ERP_für_Großhandel.webp',
        at: 'ERP_für_Großhandel.webp',
      },
      title: 'HOME.WHOLESALE',
      link: tradeLink,
      disabled: false,
      class: 'orange',
      alt: 'IMAGES_ALT.HOME.ALT_20'
    },
    {
      backgroundImage: {
        en: 'illustration_e-commerce.webp',
        de: 'Runple_Onlinehandel.webp',
        at: 'Runple_Onlinehandel.webp',
      },
      title: 'HOME.E_COMMERCE',
      link: ecoLink,
      disabled: false,
      class: 'red',
      alt: 'IMAGES_ALT.HOME.ALT_1',
    },
    {
      backgroundImage: {
        en: 'illustration_services.webp',
        de: 'Runple_für_Dienstleister_auf_einen_Blick.webp',
        at: 'Runple_für_Dienstleister_auf_einen_Blick.webp',
      },
      title: 'HOME.SERVICES_AS_COMPANY',
      link: servicesLink,
      disabled: false,
      class: 'green',
      alt: 'IMAGES_ALT.HOME.ALT_31'
    },
  ];
}

export const TOP_INFO_BLOC = [
  {
    img: {
      en: 'icon_deployment.svg',
      de: 'icon_deployment.svg',
      at: 'icon_deployment.svg',
    },
    description: 'HOME.ONE_DAY_IMPLEMENTATION',
    description375: 'HOME.ONE_DAY_IMPLEMENTATION',
    alt: 'IMAGES_ALT.HOME.ALT_25'
  },
  {
    img: {
      en: 'icon_trial.svg',
      de: 'icon_trial.svg',
      at: 'icon_trial.svg',
    },
    description: 'HOME.FREE_TRIAL',
    description375: 'HOME.FREE_TRIAL_375',
    alt: ''
  },
  {
    img: {
      en: 'icon_binding.svg',
      de: 'icon_binding.svg',
      at: 'icon_binding.svg',
    },
    description: 'HOME.NO_BINDING',
    description375: 'HOME.NO_BINDING_375',
    alt: ''
  },
  {
    img: {
      en: 'icon_regulation.svg',
      de: 'icon_regulation.svg',
      at: 'icon_regulation.svg',
    },
    description: 'HOME.AUSTRIAN_REGULATIONS',
    description375: 'HOME.AUSTRIAN_REGULATIONS_375',
    alt: 'IMAGES_ALT.HOME.ALT_10',
  },
  {
    img: {
      en: 'icon_gdpr.svg',
      de: 'icon_gdpr.svg',
      at: 'icon_gdpr.svg',
    },
    description: 'HOME.GDPR_READY',
    description375: 'HOME.GDPR_READY_375',
    alt: 'IMAGES_ALT.HOME.ALT_16'
  },
  {
    img: {
      en: 'icon_iso.svg',
      de: 'icon_iso.svg',
      at: 'icon_iso.svg',
    },
    description: 'HOME.CERTIFIED',
    description375: 'HOME.CERTIFIED_375',
    alt: 'IMAGES_ALT.HOME.ALT_34'
  },
];

export const ERP_SYSTEMS_INFO = [
  {
    img: 'illustration_entry.png',
    title: 'HOME.TRESHOLD'
  },
  {
    img: 'illustration_processes.png',
    title: 'HOME.CONFUSING'
  },
  {
    img: 'illustration_effort.png',
    title: 'HOME.MANUAL_WORK'
  },
  {
    img: 'illustration_ux.png',
    title: 'HOME.USER_EXPERIENCE'
  },
  {
    img: 'illustration_tech.png',
    title: 'HOME.OUTDATED'
  }
];

export const START_STEPS = [
  {
    count: '1',
    title: 'HOME.SIGN_UP_ONLINE',
    title_375: 'HOME.SIGN_UP_ONLINE_375',
    icon: 'clock',
    additionalInfo: 'HOME.2MIN',
    img: {
      en: 'screenshot_1-sign-up.png',
      de: 'screenshot_1-sign-up.png',
      at: 'screenshot_1-sign-up.png',
    },
    stepImg: '1.png',
    alt: 'IMAGES_ALT.HOME.ALT_5'
  },
  {
    count: '2',
    title: 'HOME.INITIAL_SETUP',
    icon: 'clock',
    additionalInfo: 'HOME.10MIN',
    img: {
      en: 'screenshot_2-initial_setup.png',
      de: 'screenshot_2-initial_setup.png',
      at: 'screenshot_2-initial_setup.png',
    },
    stepImg: '2.png',
    alt: 'IMAGES_ALT.HOME.ALT_24'
  },
  {
    count: '3',
    title: 'HOME.ONBOARDING',
    icon: 'clock',
    additionalInfo: 'HOME.FROM_30_MIN',
    img: {
      en: 'Step_3_Complete_onboarding.png',
      de: 'Step_3_Complete_onboarding.png',
      at: 'Step_3_Complete_onboarding.png',
    },
    stepImg: '3.png',
    class: 'bottom',
    alt: 'IMAGES_ALT.HOME.ALT_22'
  },
  {
    count: '4',
    title: 'HOME.FIRS_SALE',
    img: {
      en: 'illustration_4-start.webp',
      de: 'illustration_4-start.webp',
      at: 'illustration_4-start.webp',
    },
    stepImg: '4.png',
    alt: 'IMAGES_ALT.HOME.ALT_29'
  },
];

export const SLIDER_DATA = [
  {
    starCount: 4,
    message: 'REVIEWS.REVIEW_1',
    personName: 'Stefan P.',
    descr: 'Accounting Manager',
    img: {
      en: 'emoji_1.png',
      at: 'emoji_1.png',
      de: 'emoji_1.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_28'
  },
  {
    starCount: 5,
    message: 'REVIEWS.REVIEW_2',
    personName: 'Michael P.',
    descr: 'HOME.SALES_MANAGER',
    img: {
      en: 'emoji_2.png',
      at: 'emoji_2.png',
      de: 'emoji_2.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_19'
  },
  {
    starCount: 4,
    message: 'REVIEWS.REVIEW_3',
    personName: 'Matthias N.',
    descr: 'HOME.TAX_CONSULTANT',
    img: {
      en: 'emoji_5.png',
      at: 'emoji_5.png',
      de: 'emoji_5.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_12'
  },
  {
    starCount: 5,
    message: 'REVIEWS.REVIEW_4',
    personName: 'Markus W.',
    descr: 'HOME.ACCOUNTANT',
    img: {
      en: 'emoji_3.png',
      at: 'emoji_3.png',
      de: 'emoji_3.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_30'
  },
  {
    starCount: 4,
    message: 'REVIEWS.REVIEW_5',
    personName: 'Marie B.',
    descr: 'HOME.SALES_REPRESENTATIVE',
    img: {
      en: 'emoji_4.png',
      at: 'emoji_4.png',
      de: 'emoji_4.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_4',
  },
  {
    starCount: 5,
    message: 'REVIEWS.REVIEW_6',
    personName: 'Sebastian N.',
    descr: 'HOME.HEAD_OF_DEPARTMENT',
    img: {
      en: 'emoji_1.png',
      at: 'emoji_1.png',
      de: 'emoji_1.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_28'
  },
  {
    starCount: 4,
    message: 'REVIEWS.REVIEW_7',
    personName: 'Gerhard R.',
    descr: 'HOME.SOLE_PROPRIETORSHIP',
    img: {
      en: 'emoji_6.png',
      at: 'emoji_6.png',
      de: 'emoji_6.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_9'
  },
  {
    starCount: 5,
    message: 'REVIEWS.REVIEW_8',
    personName: 'Anna S.',
    descr: 'HOME.SOLE_PROPRIETORSHIP',
    img: {
      en: 'emoji_2.png',
      at: 'emoji_2.png',
      de: 'emoji_2.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_19'
  },
  {
    starCount: 4,
    message: 'REVIEWS.REVIEW_9',
    personName: 'Peter M.',
    descr: 'HOME.PHOTOGRAPHER',
    img: {
      en: 'emoji_7.png',
      at: 'emoji_7.png',
      de: 'emoji_7.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_27'
  },
  {
    starCount: 4,
    message: 'REVIEWS.REVIEW_10',
    personName: 'Andreas T.',
    descr: 'HOME.MANAGING_DIRECTOR',
    img: {
      en: 'emoji_6.png',
      at: 'emoji_6.png',
      de: 'emoji_6.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_9'
  },
  {
    starCount: 5,
    message: 'REVIEWS.REVIEW_11',
    personName: 'Thomas E.',
    descr: 'FORM.SALES_MANAGER',
    img: {
      en: 'emoji_2.png',
      at: 'emoji_2.png',
      de: 'emoji_2.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_19'
  },
  {
    starCount: 4,
    message: 'REVIEWS.REVIEW_12',
    personName: 'Wolfgang J.',
    descr: 'HOME.SOLE_PROPRIETORSHIP',
    img: {
      en: 'emoji_8.png',
      at: 'emoji_8.png',
      de: 'emoji_8.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_32'
  },
  {
    starCount: 4,
    message: 'REVIEWS.REVIEW_13',
    personName: 'Sabrina O.',
    descr: 'HOME.MANAGER',
    img: {
      en: 'emoji_6.png',
      at: 'emoji_6.png',
      de: 'emoji_6.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_9'
  },
  {
    starCount: 5,
    message: 'REVIEWS.REVIEW_14',
    personName: 'Michael B.',
    descr: 'HOME.IT_TECHNICIAN',
    img: {
      en: 'emoji_1.png',
      at: 'emoji_1.png',
      de: 'emoji_1.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_28'
  },
  {
    starCount: 5,
    message: 'REVIEWS.REVIEW_15',
    personName: 'Franz S.',
    descr: 'HOME.SOLE_PROPRIETORSHIP',
    img: {
      en: 'emoji_3.png',
      at: 'emoji_3.png',
      de: 'emoji_3.png',
    },
    alt: 'IMAGES_ALT.HOME.ALT_30'
  },
];
