import { RoutesEnum } from './routes.enum';
import { LocalePrefixesEnum } from './locale-prefixes.enum';

export const LOCALE_ROUTES_CONFIG: {[key in RoutesEnum]?: {[localeKey in LocalePrefixesEnum]: string}} = {
  [RoutesEnum.GET_STARTED]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.GET_STARTED,
    [LocalePrefixesEnum.AT]: 'anmelden',
    [LocalePrefixesEnum.DE]: 'anmelden',
    // [LocalePrefixesEnum.CH]: 'anmelden',
  },
  [RoutesEnum.PRICING]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.PRICING,
    [LocalePrefixesEnum.AT]: 'preise',
    [LocalePrefixesEnum.DE]: 'preise',
    // [LocalePrefixesEnum.CH]: 'preise',
  },
  [RoutesEnum.SYSTEM]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.SYSTEM,
    [LocalePrefixesEnum.AT]: RoutesEnum.SYSTEM,
    [LocalePrefixesEnum.DE]: RoutesEnum.SYSTEM,
    // [LocalePrefixesEnum.CH]: RoutesEnum.SYSTEM,
  },
  [RoutesEnum.ACCOUNTING]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ACCOUNTING,
    [LocalePrefixesEnum.AT]: 'buchhaltungssoftware',
    [LocalePrefixesEnum.DE]: 'buchhaltungssoftware',
    // [LocalePrefixesEnum.CH]: 'buchhaltungssoftware',
  },
  [RoutesEnum.SERVICES]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.SERVICES,
    [LocalePrefixesEnum.AT]: 'dienstleister',
    [LocalePrefixesEnum.DE]: 'dienstleister',
    // [LocalePrefixesEnum.CH]: 'dienstleister',
  },
  [RoutesEnum.TRADE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.TRADE,
    [LocalePrefixesEnum.AT]: 'warenwirtschaftssoftware',
    [LocalePrefixesEnum.DE]: 'warenwirtschaftssoftware',
    // [LocalePrefixesEnum.CH]: 'warenwirtschaftssoftware',
  },
  [RoutesEnum.WAREHOUSE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.WAREHOUSE,
    [LocalePrefixesEnum.AT]: 'lagerverwaltung',
    [LocalePrefixesEnum.DE]: 'lagerverwaltung',
    // [LocalePrefixesEnum.CH]: 'lagerverwaltung',
  },
  [RoutesEnum.PRODUCTS]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.PRODUCTS,
    [LocalePrefixesEnum.AT]: 'artikelverwaltung',
    [LocalePrefixesEnum.DE]: 'artikelverwaltung',
    // [LocalePrefixesEnum.CH]: 'artikelverwaltung',
  },
  [RoutesEnum.ANALYTICS]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ANALYTICS,
    [LocalePrefixesEnum.AT]: 'business-intelligence',
    [LocalePrefixesEnum.DE]: 'business-intelligence',
    // [LocalePrefixesEnum.CH]: 'business-intelligence',
  },
  [RoutesEnum.PARTNERS]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.PARTNERS,
    [LocalePrefixesEnum.AT]: 'kundenverwaltung',
    [LocalePrefixesEnum.DE]: 'kundenverwaltung',
    // [LocalePrefixesEnum.CH]: 'kundenverwaltung',
  },
  [RoutesEnum.TEAM]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.TEAM,
    [LocalePrefixesEnum.AT]: 'personalwesen',
    [LocalePrefixesEnum.DE]: 'personalwesen',
    // [LocalePrefixesEnum.CH]: 'personalwesen',
  },
  [RoutesEnum.ECOMMERCE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ECOMMERCE,
    [LocalePrefixesEnum.AT]: RoutesEnum.ECOMMERCE,
    [LocalePrefixesEnum.DE]: RoutesEnum.ECOMMERCE,
    // [LocalePrefixesEnum.CH]: RoutesEnum.ECOMMERCE,
  },
  [RoutesEnum.RETAIL]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.RETAIL,
    [LocalePrefixesEnum.AT]: 'kassensystem',
    [LocalePrefixesEnum.DE]: 'kassensystem',
    // [LocalePrefixesEnum.CH]: 'kassensystem',
  },
  [RoutesEnum.PRIVACY_POLICY]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.PRIVACY_POLICY,
    [LocalePrefixesEnum.AT]: 'datenschutzerklaerung',
    [LocalePrefixesEnum.DE]: 'datenschutzerklaerung',
    // [LocalePrefixesEnum.CH]: 'datenschutzerklaerung',
  },
  [RoutesEnum.TERMS_OF_SERVICE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.TERMS_OF_SERVICE,
    [LocalePrefixesEnum.AT]: 'agb',
    [LocalePrefixesEnum.DE]: 'agb',
    // [LocalePrefixesEnum.CH]: 'agb',
  },
  [RoutesEnum.COOKIE_POLICY]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.COOKIE_POLICY,
    [LocalePrefixesEnum.AT]: 'cookie-erklaerung',
    [LocalePrefixesEnum.DE]: 'cookie-erklaerung',
    // [LocalePrefixesEnum.CH]: 'cookie-erklaerung',
  },
  [RoutesEnum.SLA]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.SLA,
    [LocalePrefixesEnum.AT]: RoutesEnum.SLA,
    [LocalePrefixesEnum.DE]: RoutesEnum.SLA,
    // [LocalePrefixesEnum.CH]: RoutesEnum.SLA,
  },
  [RoutesEnum.SUB_PROCESSORS]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.SUB_PROCESSORS,
    [LocalePrefixesEnum.AT]: RoutesEnum.SUB_PROCESSORS,
    [LocalePrefixesEnum.DE]: RoutesEnum.SUB_PROCESSORS,
    // [LocalePrefixesEnum.CH]: RoutesEnum.SUB_PROCESSORS,
  },
  [RoutesEnum.GDPR]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.GDPR,
    [LocalePrefixesEnum.AT]: RoutesEnum.GDPR,
    [LocalePrefixesEnum.DE]: RoutesEnum.GDPR,
    // [LocalePrefixesEnum.CH]: RoutesEnum.GDPR,
  },
  [RoutesEnum.DATA_PROCESSING]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.DATA_PROCESSING,
    [LocalePrefixesEnum.AT]: RoutesEnum.DATA_PROCESSING,
    [LocalePrefixesEnum.DE]: RoutesEnum.DATA_PROCESSING,
    // [LocalePrefixesEnum.CH]: RoutesEnum.DATA_PROCESSING,
  },
  [RoutesEnum.USER_NOTICE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.USER_NOTICE,
    [LocalePrefixesEnum.AT]: RoutesEnum.USER_NOTICE,
    [LocalePrefixesEnum.DE]: RoutesEnum.USER_NOTICE,
    // [LocalePrefixesEnum.CH]: RoutesEnum.USER_NOTICE,
  },
  [RoutesEnum.SERVICE_LEVEL_AGREEMENT]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.SERVICE_LEVEL_AGREEMENT,
    [LocalePrefixesEnum.AT]: RoutesEnum.SERVICE_LEVEL_AGREEMENT,
    [LocalePrefixesEnum.DE]: RoutesEnum.SERVICE_LEVEL_AGREEMENT,
    // [LocalePrefixesEnum.CH]: RoutesEnum.SERVICE_LEVEL_AGREEMENT,
  },
  [RoutesEnum.ABOUT]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ABOUT,
    [LocalePrefixesEnum.AT]: 'ueber-it-work',
    [LocalePrefixesEnum.DE]: 'ueber-it-work',
    // [LocalePrefixesEnum.CH]: 'ueber-runple',
  },
  [RoutesEnum.LEGAL]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.LEGAL,
    [LocalePrefixesEnum.AT]: 'impressum',
    [LocalePrefixesEnum.DE]: 'impressum',
    // [LocalePrefixesEnum.CH]: 'impressum',
  },
  [RoutesEnum.LEXICON]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.LEXICON,
    [LocalePrefixesEnum.AT]: 'lexikon',
    [LocalePrefixesEnum.DE]: 'lexikon',
    // [LocalePrefixesEnum.CH]: 'lexikon',
  },
  [RoutesEnum.BLOG]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.BLOG,
    [LocalePrefixesEnum.AT]: RoutesEnum.BLOG,
    [LocalePrefixesEnum.DE]: RoutesEnum.BLOG,
    // [LocalePrefixesEnum.CH]: RoutesEnum.BLOG,
  },
  [RoutesEnum.EXTERNAL]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.EXTERNAL,
    [LocalePrefixesEnum.AT]: RoutesEnum.EXTERNAL,
    [LocalePrefixesEnum.DE]: RoutesEnum.EXTERNAL,
    // [LocalePrefixesEnum.CH]: RoutesEnum.BLOG,
  },
  [RoutesEnum.WEBSHOP]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.WEBSHOP,
    [LocalePrefixesEnum.AT]: RoutesEnum.WEBSHOP,
    [LocalePrefixesEnum.DE]: RoutesEnum.WEBSHOP,
    // [LocalePrefixesEnum.CH]: RoutesEnum.WEBSHOP,
  },
  [RoutesEnum.TRADE_OFFER]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.TRADE_OFFER,
    [LocalePrefixesEnum.AT]: RoutesEnum.TRADE_OFFER,
    [LocalePrefixesEnum.DE]: RoutesEnum.TRADE_OFFER,
    // [LocalePrefixesEnum.CH]: RoutesEnum.TRADE_OFFER,
  },
  [RoutesEnum.ERROR]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ERROR,
    [LocalePrefixesEnum.AT]: RoutesEnum.ERROR,
    [LocalePrefixesEnum.DE]: RoutesEnum.ERROR,
    // [LocalePrefixesEnum.CH]: RoutesEnum.ERROR,
  },
};
