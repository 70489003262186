import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { ButtonColor, ButtonStylesModel } from '../button/button.model';
import { LocaleService } from '../../locale/locale.service';
import { RoutesEnum } from '../../locale/routes.enum';

@Component({
  selector: 'rnpl-privacy-agreement',
  templateUrl: './privacy-agreement.component.html',
  styleUrls: ['./privacy-agreement.component.scss']
})
export class PrivacyAgreementComponent {

  public buttonConfig: ButtonStylesModel = {
    height: 48,
    reverse: true,
    iconCenter: true
  };

  public buttonConfigDark: ButtonStylesModel = {
    height: 48,
    reverse: true,
    bgColor: ButtonColor.BLACK,
    iconCenter: true
  };

  @Output() public isCookiesAgree: EventEmitter<void> = new EventEmitter<void>();
  @Output() public isCookiesDecline: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private router: Router,
    public localeService: LocaleService,
  ) {
  }

  public cookiesAgree(): void {
    this.isCookiesAgree.emit();
  }

  public cookiesDecline(): void {
    this.isCookiesDecline.emit();
  }

  public goToCookiesPage(): void {
    this.router.navigate([this.localeService.getLink(RoutesEnum.COOKIE_POLICY)]);
  }

}
