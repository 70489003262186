import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CONTINUE_BUTTON } from '../modal.config';

import { ButtonModel } from '../../button/button.model';
import { Router } from '@angular/router';
import {FormService} from '../../../services/forms.service';

@Component({
  selector: 'app-request-accepted',
  templateUrl: './request-accepted-primary.component.html',
  styleUrls: ['./request-accepted-primary.component.scss'],
})
export class RequestAcceptedPrimaryComponent {
  public continueButton: ButtonModel = CONTINUE_BUTTON;
  private redirection: string;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
  ) {}

  public closeModal() {
    this.activeModal.dismiss('Close');
    if (this.redirection) {
      this.router.navigate([this.redirection]);
    }
  }
}
