<h2 class="review-us-title">
  {{ 'HOME.WHAT_PEOPLE_SAY' | translate }}
</h2>

<div class="rnpl-slider-wrapper">
  <div class="rnpl-slider-container">
    <div #rnplSlider class="rnpl-slider">
      <div #slideElement *ngFor="let item of sliderData"
           class="rnpl-slider-item"
           (touchstart)="handleTouchStart($event)"
           (touchmove)="handleTouchMove($event)"
      >
        <div class="message-container">
          <div class="star-list">
            <ng-container *ngIf="item?.starCount">
              <i *ngFor="let start of getCountStar(item?.starCount)" class="icon icon-star"></i>
              <i *ngFor="let start of getCountStar(5 - item?.starCount)" class="icon icon-star grey"></i>
            </ng-container>
          </div>

          <div class="message">
            <p>{{item?.message | translate}}</p>
          </div>

          <div class="person-name mt-auto">
            <span class="name-container">
              {{item?.personName}} <br>
              <span class="descr">{{item?.descr | translate}}</span>
            </span>

            <span class="img ml-auto">
                <img src="{{item?.img | imagesByLocale: 'home/emoji'}}" [alt]="item?.alt | translate">
              </span>
          </div>

        </div>
      </div>
    </div>

    <div class="rnpl-slider-actions">

      <ng-content select="[trustbox]"></ng-content>

<!--      <app-trustbox></app-trustbox>-->

      <button class="btn btn-white btn-slider-action prev" aria-label="Prev" (click)="prevSlide()">
        <i class="icon icon-arrow-right"></i>
      </button>

      <!--          <div class="indicator">-->
      <!--            <div *ngFor="let item of sliderIndicatorsList; let i = index" class="point" [class.active]="(i + 1) === activeSlideIndex"></div>-->
      <!--          </div>-->

      <button id="next-slide"
              aria-label="Next"
              class="btn btn-white btn-slider-action"
              [class.show-animation]="showNextSlideProgress"
              (click)="nextSlide()"
      >
        <i class="icon icon-arrow-right"></i>
        <span class="progress"></span>
      </button>
    </div>
  </div>
</div>
