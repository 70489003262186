import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';

import { BlogApiService } from '../services/blog-api.service';
import { TitleService } from '../../../services/title.service';
import { PostModel } from '../../../models/blog-list.model';
import { LocaleService } from '../../../locale/locale.service';
import { RoutesEnum } from '../../../locale/routes.enum';

@Component({
  selector: 'app-lexicon-page',
  templateUrl: './lexicon-page.component.html',
  styleUrls: ['./lexicon-page.component.scss']
})
export class LexiconPageComponent implements OnInit, OnDestroy {

  public postData: PostModel;
  public routesEnum = RoutesEnum;

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly destroyed$: Subject<boolean> = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private blogApiServices: BlogApiService,
    private titleService: TitleService,
    private router: Router,
    private meta: Meta,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.currentLocalePrefix$.subscribe(data => {
      if (data === null) {
        this.meta.updateTag({
          property: 'robots',
          content: 'noindex, nofollow'
        });
      }
    });

    this.isLoading$.next(true);

    const name = this.activatedRoute.snapshot.paramMap.get('name');

    this.blogApiServices.getLexicon(name)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(post => {
        this.postData = this.preparePostData(post);

        this.titleService.updateMetaTitle(post.seo_title);
        this.titleService.updateMetaDescription(post.meta_description);
        this.isLoading$.next(false);
      }, (error) => {
        if (error.status === 404) {
          this.router.navigate([this.localeService.rootRoute + '/404']);
        } else {
          this.router.navigate([this.localeService.rootRoute]);
        }
      });
  }

  private preparePostData(post: PostModel): PostModel {
    return {
      ...post,
      createdDate: post.created_at * 1000
    };
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.meta.removeTag('name="robots"');
  }

}
