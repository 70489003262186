import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileFormat } from './file-loader.model';

@Component({
  selector: 'rnpl-file-loader',
  templateUrl: './file-loader.component.html',
  styleUrls: ['./file-loader.component.scss'],
})
export class FileLoaderComponent implements OnInit {
  @Input()
  public label: string;

  @Input()
  public icon: string;

  @Input()
  public control?: FormControl;

  @Output()
  filesChange: EventEmitter<any> = new EventEmitter<any>();

  public files: Array<File> = [];
  public description: string;
  public errorMessage: string;
  public format = 'FILE_LOADER.FORMAT';

  private maxFilesLenth = 3;
  private maxFileSize = 10;

  ngOnInit(): void {
    const isTouchDevice =
      'ontouchstart' in window || 'onmsgesturechange' in window ? true : false;
    this.description = isTouchDevice
      ? 'FILE_LOADER.MOBILE_DESC'
      : 'FILE_LOADER.DESCTOP_DESC';
  }

  public onFileDropped(files: Array<File>): void {
    this.prepareFilesList(files);
  }

  public fileBrowseHandler(files: Array<File>): void {
    this.prepareFilesList(files);
  }

  public deleteFile($event, index: number): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.files.splice(index, 1);
    this.filesChange.next(this.files);
    this.control.setValue(this.files);
  }

  private prepareFilesList(files: Array<File>): void {
    if (this.files.length < this.maxFilesLenth) {
      for (const item of files) {
        if (this.isPermittedFormat(item) && this.isPermittedSize(item)) {
          this.errorMessage = undefined;
          this.files.push(item);
        } else {
          this.errorMessage = this.getErrorMessage(item);
        }
      }
      this.control.setValue(this.files);
      this.filesChange.next(this.files);
    }
  }

  private isPermittedFormat(file: File): boolean {
    return (
      file.type === FileFormat.PDF ||
      file.type === FileFormat.DOC ||
      file.type === FileFormat.DOCX ||
      file.type === FileFormat.TXT
    );
  }

  private isPermittedSize(file: File): boolean {
    return (
      parseFloat((file.size / (1024 * 1024)).toFixed(2)) <= this.maxFileSize
    );
  }

  private getErrorMessage(file: File): string {
    if (!this.isPermittedFormat(file)) {
      return 'FILE_LOADER.ERROR_FILE_FORMAT';
    }
    if (!this.isPermittedSize(file)) {
      return 'FILE_LOADER.ERROR_FILE_SIZE';
    }
  }
}
