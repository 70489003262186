<div class="our-partners-container">
  <h2 class="page-text-title mt-0 mb-0">
  {{ 'HOME.OUR_KUNDEN' | translate }}
  </h2>

  <p class="page-text-descr">
    {{ 'HOME.OUR_PARTNERS_DESC' | translate }}
  </p>

  <div class="our-partners">
    <div *ngFor="let item of items" class="our-partners-item">
      <img *ngIf="!item.url" src="assets/img/kunden/{{item.logo}}" alt="{{item.name}}" class="our-partners-item-img">

      <a *ngIf="!!item.url" href="{{item.url}}" target="_blank" rel="nofollow">
        <img src="assets/img/kunden/{{item.logo}}" alt="{{item.name}}" class="our-partners-item-img">
      </a>
    </div>
  </div>
</div>
