import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { LocalePrefixesEnum } from './locale-prefixes.enum';
import { RoutesEnum } from './routes.enum';
import { LOCALE_ROUTES_CONFIG } from './locale-routes.config';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {

  public currentLocalePrefix$: BehaviorSubject<LocalePrefixesEnum> = new BehaviorSubject<LocalePrefixesEnum>(null);

  constructor(
    private router: Router,
  ) {

  }

  public setCurrentLocalePrefix(locale: LocalePrefixesEnum): void {
    this.currentLocalePrefix$.next(locale);
  }

  public getLink(route: RoutesEnum): string {
    return this.currentLocalePrefix
      ? `/${this.currentLocalePrefix}/${LOCALE_ROUTES_CONFIG[route][this.currentLocalePrefix]}`
      : `/${route}`;
  }

  public get currentLocalePrefix(): LocalePrefixesEnum {
    return this.currentLocalePrefix$.getValue();
  }

  public goToGetStarted(): void {
    this.router.navigate([this.getLink(RoutesEnum.GET_STARTED)]);
  }

  public get rootRoute(): string {
    return this.currentLocalePrefix
      ? `/${this.currentLocalePrefix}`
      : '/';
  }

  public get isLocaleAT(): boolean {
    return this.currentLocalePrefix === LocalePrefixesEnum.AT;
  }

  public get isLocaleDE(): boolean {
    return this.currentLocalePrefix === LocalePrefixesEnum.DE;
  }

  public changeLocale(locale?: LocalePrefixesEnum): void {
    const splitedRoute: string[] = this.router.url.split('/').filter(f => !!f);

    // if locale already in route ( runple.com/at/ueber-runple )
    if ((<any>Object).values(LocalePrefixesEnum).includes(splitedRoute[0])) {
      splitedRoute[0] = locale || '';
      // if route without locale ( runple.com/about-runple )
    } else {
      splitedRoute.unshift(locale || '');
    }

    if (splitedRoute[1]) {
      const routesConfig = Object.values(LOCALE_ROUTES_CONFIG)
        .find(config => Object.values(config).includes(splitedRoute[1]));
      for (const key in LOCALE_ROUTES_CONFIG) {
        if (LOCALE_ROUTES_CONFIG.hasOwnProperty(key)) {
          if (JSON.stringify(LOCALE_ROUTES_CONFIG[key]) === JSON.stringify(routesConfig)) {
            splitedRoute[1] = LOCALE_ROUTES_CONFIG[key][locale] || key;
          }
        }
      }
    }

    this.router.navigate(splitedRoute);
  }
}
