import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChannelsBlockComponent } from './channels-block.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActionCardModule } from '../action-card/action-card.module';
import { RouterModule } from '@angular/router';
import {PipesModule} from '../../pipes/pipes.module';


@NgModule({
  declarations: [
    ChannelsBlockComponent
  ],
  exports: [
    ChannelsBlockComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        ActionCardModule,
        RouterModule,
        PipesModule,
    ]
})
export class ChannelsBlockModule { }
