import { NgModule } from '@angular/core';

import { CurrencyFormat } from './currency-format/currency-format.pipe';
import { ImagesByLocalePipe } from './images-by-locale/images-by-locale.pipe';


@NgModule({
  declarations: [
    CurrencyFormat,
    ImagesByLocalePipe,
  ],
  imports: [],
  providers: [],
  exports: [
    CurrencyFormat,
    ImagesByLocalePipe
  ],
})
export class PipesModule {}
