import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurPartnersComponent } from './our-partners.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [OurPartnersComponent],
  exports: [
    OurPartnersComponent
  ],
    imports: [
        CommonModule,
        TranslateModule
    ]
})
export class OurPartnersModule { }
