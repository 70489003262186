import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';
import { LocaleService } from './locale/locale.service';


@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private translateService: TranslateService, private localeService: LocaleService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('https:') && request.url.includes('s3')) {
      return next.handle(request);
    }

    let url: string;

    // weak checking (need to find better way)
    if (request.url.includes(environment.javaApiVersion) || request.url.includes(environment.javaAccountingApiVersion)) {
      url = environment.javaApiUrl + request.url;
    } else {
      url = environment.apiUrl + request.url;
    }

    if (!request.url.includes('i18n')) {
      const headers = {
        Accept: 'application/json',
      };

      if (!request.url.includes(environment.javaApiVersion) && !request.url.includes(environment.javaAccountingApiVersion)) {
        const browserLang = localStorage.getItem('lang') ||
          (this.translateService.getBrowserLang().match(/en|de/) ? this.translateService.getBrowserLang() : 'en');
        headers['language'] = browserLang;
        headers['localization'] = this.localeService.currentLocalePrefix || 'global';
      }

      request = request.clone({
        url,
        setHeaders: headers,
      });
    }

    return next.handle(request);
  }
}
