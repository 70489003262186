import { FormControl } from '@angular/forms';

export interface InputModel {
  label: string;
  placeholder: string;
  type: string;
  domenName?: string;
  isOptional?: boolean;
  control?: FormControl;
}

export enum InputType {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  URL = 'url',
  TEL = 'tel',
  NUM = 'number',
}
