import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { BlogApiService } from '../services/blog-api.service';
import { CategoryModel, PostModel } from '../../../models/blog-list.model';
import { TitleService } from '../../../services/title.service';
import { LocaleService } from '../../../locale/locale.service';
import { RoutesEnum } from '../../../locale/routes.enum';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss']
})
export class BlogPageComponent implements OnInit, OnDestroy {

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly destroyed$: Subject<boolean> = new Subject();

  public postData: PostModel;

  public routesEnum = RoutesEnum;

  public readAlsoPosts$: BehaviorSubject<PostModel[]> = new BehaviorSubject<PostModel[]>([]);

  public categoryList: {[key: number]: CategoryModel};

  constructor(
    private activatedRoute: ActivatedRoute,
    private blogApiServices: BlogApiService,
    private titleService: TitleService,
    private router: Router,
    private meta: Meta,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.currentLocalePrefix$.subscribe(data => {
      if (data === null) {
        this.meta.updateTag({
          property: 'robots',
          content: 'noindex, nofollow'
        });
      }
    });

    this.isLoading$.next(true);
    const name = this.activatedRoute.snapshot.paramMap.get('name');

    this.blogApiServices.getPost(name)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(post => {
        this.postData = this.preparePostData(post);
        // this.getReadAlsoPosts(+post.id);

        this.titleService.updateMetaTitle(post.seo_title);
        this.titleService.updateMetaDescription(post.meta_description);
        this.isLoading$.next(false);
      }, (error) => {
        if (error.status === 404) {
          this.router.navigate([this.localeService.rootRoute + '/404']);
        } else {
          this.router.navigate([this.localeService.rootRoute]);
        }
      });
  }

  private preparePostData(post: PostModel): PostModel {
    return {
      ...post,
      createdDate: post.created_at * 1000
    };
  }

  private getReadAlsoPosts(postId: number): void {
    this.blogApiServices.getReadAlso(postId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.readAlsoPosts$.next((this.preparePostsList(data.posts)));
        this.categoryList = data.category;
      });
  }

  public preparePostsList(posts: PostModel[]): PostModel[] {
    if (!posts) { return null; }
    const localePrefix = this.localeService.currentLocalePrefix
      ? '/' + this.localeService.currentLocalePrefix
      : '';
    return posts.map(post => {
      return {
        ...post,
        createdDate: post.created_at * 1000,
        routerLink: localePrefix + `/blog/${post.post_url}`,
      };
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.meta.removeTag('name="robots"');
  }

}
