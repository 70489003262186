<div class="social-shared-container">

  <button class="btn">
    <a [href]="'https://www.facebook.com/sharer.php?u=' + currentHref" target="_blank" rel="nofollow" class="social-shared-link">
      <i class="icon icon-facebook"></i>
    </a>
  </button>

  <button class="btn">
    <a [href]="'https://twitter.com/intent/tweet?text=' + currentHref" target="_blank" rel="nofollow" class="social-shared-link">
      <i class="icon icon-twitter"></i>
    </a>
  </button>

  <button class="btn" (click)="sharedLinkedIn()">
    <i class="icon icon-linkedin"></i>
  </button>

  <button class="btn" [copy-clipboard]="currentHref" (copied)="isCopied = true" [@copyLinkAnimation]="animationState" (click)="showAnimation()">
    <i class="icon icon-link"></i>
    <i class="icon icon-checkmark-circle checkmark"></i>
  </button>

</div>
