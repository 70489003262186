import { FormNames } from './models/form.models';
import { JoinTeamComponent } from '../ui-components/modal/join-team/join-team.component';
import { AskQuestionComponent } from '../ui-components/modal/ask-question/ask-question.component';
import { EarlyAccessComponent } from '../ui-components/modal/early-access/early-access.component';

export const FREE_USERS_COUNT: number = 2;
export const PRIORITY_SUPPORT: number = 9.9;
export const VAT: number = 0.2;
export const MONTH_NUMBER = 12;
export const YEARS_SALE = 0.2;

export const BASIC_SUBSCRIPTION: number = 99.80;
export const BASIC_SUBSCRIPTION_SALE: number = 0.5;
export const BASIC_SUBSCRIPTION_SALE_TO_DATE: string | Date = '01.07.2023';
export const SALES_CHANNEL: number = 19.90;
export const SALES_CHANNEL_ANNUALLY: number = 191.00;
export const USER_PRICE: number = 9.90;
export const USER_PRICE_ANNUALLY: number = 95.00;

export const MODALS = {
  [FormNames.QUESTION]: AskQuestionComponent,
  [FormNames.DEVELOPMENT]: AskQuestionComponent,
  [FormNames.JOIN]: JoinTeamComponent,
  [FormNames.EARLY_ACCESS]: EarlyAccessComponent,
};

export enum Period {
  YEAR = 'Annually',
  MONTH = 'Monthly',
}
