<div class="modal-wrapper">
  <div class="header cursor-pointer">
    <div class="btn-container" (click)="closeModal()">
      <i class="icon icon-close"></i>
    </div>
  </div>
  <div class="content d-flex flex-column">
    <div class="icon-container">
      <i class="icon icon-alert-triangle"></i>
    </div>
    <h4 class="modal-title">{{ "MODAL.ERROR" | translate }}</h4>
    <p class="modal-descr">{{ "MODAL.WITH_RUNPLE" | translate }}</p>
    <rnpl-button
      class="continue-btn"
      [title]="retryButton.title"
      [styles]="retryButton.styles"
      iconName="arrow-circle"
      (click)="retry()">
    </rnpl-button>
  </div>
</div>
