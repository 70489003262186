import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SelectItemModel, SelectStylesModel } from './select.model';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rnpl-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input()
  public selectItems: SelectItemModel[];

  @Input()
  public defaultSelectionIndex: number;

  @Input()
  public labelIcon: string;

  @Input()
  public labelText: string;

  @Input()
  public styles: SelectStylesModel;

  @Input()
  public disabled: boolean;

  @Input()
  public selectLangType: boolean = false;

  @Input()
  public searchable: boolean = true;

  @Input()
  public control: FormControl;

  @Input()
  public placeholder: string;

  @Input()
  public bindValue: string;

  @Input() set selectedValue(selectedValue: any) {
    this.updateSelectedValue(selectedValue);
  }

  @Output()
  changeSelect: EventEmitter<SelectItemModel> = new EventEmitter<
    SelectItemModel
  >();

  @Output()
  changeSelectSourceValue: EventEmitter<any> = new EventEmitter<any>();

  public selectedItem: string;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    if ((this.defaultSelectionIndex || this.defaultSelectionIndex === 0) && this.selectItems[this.defaultSelectionIndex]) {
      this.selectedItem = this.selectItems[this.defaultSelectionIndex].label;
    }

    if (this.selectedItem) {
      this.control?.setValue(this.translate.instant(this.selectedItem));
    }
  }

  onSelectChange(item) {
    this.changeSelectSourceValue.emit(item);
    this.selectedItem = item;
    if (this.control) {
      if (!this.bindValue) {
        this.control.setValue(this.translate.instant(item.value || item.label));
      } else {
        this.control.setValue(item[this.bindValue]);
      }
      // this.control.setValue(item.isoCode);
    } else {
      if (!this.bindValue) {
        this.changeSelect.next(this.translate.instant(item.value || item.label));
      } else {
        this.changeSelect.next(item[this.bindValue]);
      }
      // this.changeSelect.next(item.isoCode);
    }
  }

  updateSelectedValue(value: any) {
    if (value) {
      this.selectedItem = value;
    }
  }
}
