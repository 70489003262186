import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurCertificateComponent } from './our-certificate.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [OurCertificateComponent],
  exports: [
    OurCertificateComponent
  ],
    imports: [
        CommonModule,
        TranslateModule
    ]
})
export class OurCertificateModule { }
