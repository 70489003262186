import { FormControl } from '@angular/forms';

export interface FileLoaderModel {
  label: string;
  icon: string;
  control?: FormControl;
}

export enum FileFormat {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  TXT = 'application/pdf',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}
