import { Component, OnDestroy, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InputModel } from '../../input/input.model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ButtonModel } from '../../button/button.model';
import { SelectModel } from '../../select/select.model';
import {
  E_MAIL,
  PHONE,
  SELECT_ACTION_QUESTION,
  SUBMIT_BUTTON,
  TEXTAREA,
  SELECT_ACTION_REQUEST, FIRST_NAME, LAST_NAME, COMPANY_NAME,
} from '../modal.config';
import { FormNames } from 'src/app/services/models/form.models';
import { RnplModalService } from 'src/app/services/rnpl-modal.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FormService } from '../../../services/forms.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ask-question',
  templateUrl: './ask-question.component.html',
  styleUrls: ['./ask-question.component.scss'],
})
export class AskQuestionComponent implements OnInit, OnDestroy {
  public last: InputModel = LAST_NAME;
  public first: InputModel = FIRST_NAME;
  public eMail: InputModel = E_MAIL;
  public tel: InputModel = PHONE;
  public compName: InputModel = COMPANY_NAME;
  public textarea: any = TEXTAREA;
  public submitButton: ButtonModel = SUBMIT_BUTTON;
  public selectActionQuestion: SelectModel = SELECT_ACTION_QUESTION;
  public selectActionRequest: SelectModel = SELECT_ACTION_REQUEST;

  public askQuestion = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl(''),
    companyName: new FormControl(''),
    yourQuestion: new FormControl('', Validators.required),
    reCaptcha: new FormControl(''),
  });

  public activeComponentType: string;
  public formNames = FormNames;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public activeModal: NgbActiveModal,
    private modal: RnplModalService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private formService: FormService,
  ) {}

  ngOnInit(): void {
    this.setAskQuestionFormValidators();
    this.formService.checkOutValidForm();
  }

  public setAskQuestionFormValidators(): void {
    if (this.activeComponentType === this.formNames.DEVELOPMENT) {
      this.companyName.setValidators([Validators.required]);
    }
  }

  public preSubmittingFormHandler(): void {
    this.formService.checkValidForm();
    this.formService.getActiveForm(FormNames.QUESTION);

    this.recaptchaV3Service.execute('submitForm')
      .pipe(takeUntil(this.destroy$))
      .subscribe((token) => {
        this.captchaToken.setValue(token);
        this.submitForm();
      });
  }

  public submitForm() {
    if (this.askQuestion.valid) {
      const formData = this.askQuestion.getRawValue();
      const formName = this.activeComponentType || FormNames.QUESTION;
      if (this.activeComponentType) {
        formData['type'] = this.activeComponentType;
      }
      this.modal.submitFormModal(formData, formName, this.activeModal);
    }
  }

  // public changeSelection(value) {
  //   this.activeModal.dismiss(value);
  // }

  ngOnDestroy() {
    this.formService.checkOutValidForm();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get firstName(): FormControl {
    return this.askQuestion.get('firstName') as FormControl;
  }
  get lastName(): FormControl {
    return this.askQuestion.get('lastName') as FormControl;
  }
  get email(): FormControl {
    return this.askQuestion.get('email') as FormControl;
  }
  get phone(): FormControl {
    return this.askQuestion.get('phone') as FormControl;
  }
  get companyName(): FormControl {
    return this.askQuestion.get('companyName') as FormControl;
  }
  get yourQuestion(): FormControl {
    return this.askQuestion.get('yourQuestion') as FormControl;
  }
  get captchaToken(): FormControl {
    return this.askQuestion.get('reCaptcha') as FormControl;
  }
}
