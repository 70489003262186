import { Component, HostListener } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ButtonColor, ButtonModel } from '../../button/button.model';
import { RequestDemoModalComponent } from '../../modal/request-demo-modal/request-demo-modal.component';
import { RnplModalService } from '../../../services/rnpl-modal.service';

@Component({
  selector: 'app-exit-modal',
  templateUrl: './exit-modal.component.html',
  styleUrls: ['./exit-modal.component.scss']
})
export class ExitModalComponent {

  public isShowModal = false;
  public modalData = {
    title: 'REQUEST_DEMO.AUTOMATIC_DEMO_MODAL_TITLE',
    title375: 'REQUEST_DEMO.AUTOMATIC_DEMO_MODAL_TITLE_375',
    descr: 'REQUEST_DEMO.AUTOMATIC_DEMO_MODAL_DESCR'
  };

  private ngbModalOptions: NgbModalOptions = {
    backdrop : true,
    keyboard : false,
  };

  public button: ButtonModel = {
    title: 'REQUEST_DEMO.REQUEST_DEMO',
    iconName: 'support',
    styles: {
      height: 64,
      bgColor: ButtonColor.WHITE,
      textColor: ButtonColor.BLUE
    }
  };

  constructor(
    private modalService: NgbModal,
    private modal: RnplModalService,
  ) { }

  @HostListener('document:mouseout', ['$event'])
  onMousemove(event: MouseEvent) {
    if (
      event.clientY <= 0 &&
      !this.isShowModal &&
      !this.modalService.hasOpenModals() &&
      !localStorage.getItem('demoModal')
    ) {
      this.isShowModal = true;
      localStorage.setItem('demoModal', 'show');
      setTimeout(() => {
        this.modal.setModalData(this.modalData);
        this.modalService.open(RequestDemoModalComponent, this.ngbModalOptions);
      }, 750)
    }
  }
}
