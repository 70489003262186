import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';

import { ButtonModel } from '../button/button.model';
import { ActionCardStylesModel } from './action-card.model';
import {LocaleService} from '../../locale/locale.service';
import {FormNames} from "../../services/models/form.models";
import {RnplModalService} from '../../services/rnpl-modal.service';
import { MODALS } from 'src/app/services/constants';

@Component({
  selector: 'rnpl-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCardComponent {
  private modalsList = MODALS;

  constructor(
    private rnplModalService: RnplModalService,
    public localeService: LocaleService,
  ) {
  }

  @Input()
  public title: string;

  @Input()
  public title_375: string;

  @Input()
  public description: string;

  @Input()
  public styles: ActionCardStylesModel = {};

  @Input()
  public buttonConfig: ButtonModel;

  @Output()
  buttonClicked: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public img: string;

  @Input()
  public imgRight: string;

  @Input()
  public imgAlt: string;

  public onClick() {
    this.buttonClicked.next(true);
  }

  public openAskQuestionModal() {
    this.rnplModalService.openContactUsModal(
      this.modalsList,
      FormNames.QUESTION
    );
  }
}
