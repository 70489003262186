import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';

import { ButtonStylesModel } from './button.model';

@Component({
  selector: 'rnpl-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public iconName: string;

  @Input()
  public img: string;

  @Input()
  public imgHover: string;

  @Input()
  public disabled: boolean;

  @Input()
  public styles: ButtonStylesModel;

  @Input()
  public typeSmall: boolean = false;

  @Input()
  public isMailToType: boolean = false;

  constructor() {}

  ngOnInit() {}
}
