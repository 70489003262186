import { Component } from '@angular/core';

import { PartnersLogoModel } from './our-partners.model';

@Component({
  selector: 'rnpl-our-partners',
  templateUrl: './our-partners.component.html',
  styleUrls: ['./our-partners.component.scss']
})

export class OurPartnersComponent {

  public items: Array <PartnersLogoModel> = [
    {
      logo: 'Runple.png',
      url: 'https://runple.com/at',
      name: 'Runple'
    },
    {
      logo: 'Microsoft.png',
      url: 'https://partner.microsoft.com/',
      name: 'Microsoft'
    },
    {
      logo: 'BMD.png',
      url: 'https://partner.hp.com/',
      name: 'BMD'
    },
    {
      logo: 'NFON.png',
      url: 'https://www.nfon.com/',
      name: 'NFON'
    },
    {
      logo: '3CX.png',
      url: '',
      name: '3CX'
    },
    {
      logo: 'Terra.png',
      url: 'https://terra.at',
      name: 'Terra'
    },
  ];

}
