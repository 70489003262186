import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ButtonModel } from '../../button/button.model';
import { CONTINUE_BUTTON } from '../modal.config';

@Component({
  selector: 'app-request-demo-accepted',
  templateUrl: './request-demo-accepted.component.html',
  styleUrls: ['./request-demo-accepted.component.scss']
})
export class RequestDemoAcceptedComponent implements OnInit {

  public continueButton: ButtonModel = CONTINUE_BUTTON;
  private redirection: string;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  public closeModal() {
    this.activeModal.dismiss('Close');
    if (this.redirection) {
      this.router.navigate([this.redirection]);
    }
  }

}
