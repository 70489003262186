import { Component, OnInit } from '@angular/core';
import { CONTINUE_BUTTON, RETRY_BUTTON } from '../modal.config';
import { ButtonModel } from '../../button/button.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.modal.component.html',
  styleUrls: ['./error.modal.component.scss'],
})
export class ErrorModalComponent {
  public continueButton: ButtonModel = CONTINUE_BUTTON;
  public retryButton: ButtonModel = RETRY_BUTTON;
  public activeModalRef: any;
  private redirection: string;

  constructor(public activeModal: NgbActiveModal, private router: Router) {}

  public retry() {
    this.activeModal.close('Retry');
  }

  public closeModal() {
    this.activeModal.dismiss('Close');
    if (this.redirection) {
      this.router.navigate([this.redirection]);
    }
  }
}
