<div class="modal-wrapper" #modal>
  <div class="header">
    <div
      class="btn-container cursor-pointer"
      (click)="activeModal.dismiss('Close')">
      <i class="icon icon-close"></i>
    </div>

  </div>
  <h2 class="modal-title">{{ "MODAL.REQ_EARLY_ACCESS" | translate }}</h2>

  <div class="form-container">
    <form id="fom-early-access">
      <div class="row">
        <div class="col-6">
          <rnpl-input
            [label]="first.label"
            [placeholder]="first.placeholder"
            [type]="first.type"
            [formName]="formNames.EARLY_ACCESS"
            [control]="firstName">
          </rnpl-input>
        </div>

        <div class="col-6">
          <rnpl-input
            [label]="last.label"
            [placeholder]="last.placeholder"
            [type]="last.type"
            [formName]="formNames.EARLY_ACCESS"
            [control]="lastName">
          </rnpl-input>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <rnpl-input
            [label]="eMail.label"
            [placeholder]="eMail.placeholder"
            [type]="eMail.type"
            [formName]="formNames.EARLY_ACCESS"
            [control]="email">
          </rnpl-input>
        </div>

        <div class="col-6">
          <rnpl-input
            [label]="tel.label"
            [placeholder]="tel.placeholder"
            [type]="tel.type"
            [isOptional]="tel.isOptional"
            [formName]="formNames.EARLY_ACCESS"
            [control]="phone">
          </rnpl-input>
        </div>
      </div>

      <div class="row">

        <div class="col-12">
          <rnpl-input
            [label]="company.label"
            [placeholder]="company.placeholder"
            [type]="company.type"
            [isOptional]="company.isOptional"
            [formName]="formNames.EARLY_ACCESS"
            [control]="companyName">
          </rnpl-input>
        </div>
      </div>
    </form>

    <div class="button">
      <rnpl-button
        [title]="requestAccessButton.title"
        [styles]="requestAccessButton.styles"
        (click)="preSubmittingFormHandler()">
      </rnpl-button>
    </div>

    <div class="bottom-descr-container">
      <p class="descr">
        {{ "MODAL.YOU_CAN_ALSO_CONTACT" | translate }}<br>
        <a class="link" href="mailto:info@runple.com">info@runple.com</a>
      </p>
    </div>

    <p class="recaptcha-info">
      {{ "CAPTCHA_INFO.SITE_PROTECTED" | translate: {buttonName: 'FORM.REQUEST_ACCESS' | translate} }}
      <a target="_blank" href="https://policies.google.com/privacy" rel="nofollow">{{ "CAPTCHA_INFO.PRIVACY" | translate}}</a> {{ "CAPTCHA_INFO.AND" | translate}}
      <a target="_blank" href="https://policies.google.com/terms" rel="nofollow">{{ "CAPTCHA_INFO.TERMS" | translate}}</a>.
    </p>
  </div>
</div>
