import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-slider-carousel',
  templateUrl: './slider-carousel.component.html',
  styleUrls: ['./slider-carousel.component.scss']
})
export class SliderCarouselComponent implements AfterViewInit {

  private xDown = null;
  private yDown = null;

  @Input() selectorName: string;

  private card_container: any;
  private nav_container: any;
  private total_number_of_cards_by_index: number;
  private animation_in_progress = false;

  public countdownTimer;

  public animationStateIsPaused$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isBrowser = false;
  public selectedIndex = 1;
  public currentTimer: number = 0;

  public carouselTitle = [
    'HOME.BUY_SLIDE',
    'HOME.SELL',
    'HOME.DELIVER',
    'HOME.PROVIDE_SERVICES',
    'HOME.BILL',
    'HOME.REPORT_TAXES',
    'HOME.ANALYZE'
  ];

  public carouselConfig = [
    {
      img: 'bill',
      alt: 'IMAGES_ALT.HOME.ALT_39',
      title: 'HOME.BILL',
      index: 5,
    },
    {
      img: 'report-taxes',
      alt: 'IMAGES_ALT.HOME.ALT_40',
      title: 'HOME.REPORT_TAXES',
      index: 6,
    },
    {
      img: 'analyze',
      alt: 'IMAGES_ALT.HOME.ALT_41',
      title: 'HOME.ANALYZE',
      index: 7,
    },
    {
      img: 'buy',
      alt: 'IMAGES_ALT.HOME.ALT_35',
      title: 'HOME.BUY_SLIDE',
      index: 1,
    },
    {
      img: 'sell',
      alt: 'IMAGES_ALT.HOME.ALT_36',
      title: 'HOME.SELL',
      index: 2,
    },
    {
      img: 'deliver',
      alt: 'IMAGES_ALT.HOME.ALT_37',
      title: 'HOME.DELIVER',
      index: 3,
    },
    {
      img: 'provide-service',
      alt: 'IMAGES_ALT.HOME.ALT_38',
      title: 'HOME.PROVIDE_SERVICES',
      index: 4,
    },
  ];

  @ViewChild('slider', { static: false })
  private slider: ElementRef<HTMLDivElement>;
  isSliderScrolledIntoView: boolean = true;

  @HostListener('document:keydown.arrowRight', ['$event']) onKeyRightHandler() {
    if (this.isSliderScrolledIntoView) {
      this.next_card();
    }
  }
  @HostListener('document:keydown.arrowLeft', ['$event']) onKeyLeftHandler() {
    if (this.isSliderScrolledIntoView) {
      this.previous_card();
    }
  }

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.slider && this.isBrowser) {
      const rect = this.slider.nativeElement.getBoundingClientRect();
      this.isSliderScrolledIntoView = rect.top >= -400;
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public initCarousel() {
    if (!this.isBrowser) { return; }
    this.card_container = this.document.querySelector(`.${this.selectorName}`);
    this.nav_container = this.document.querySelector(`.${this.selectorName + '-nav'}`);
    this.total_number_of_cards_by_index = this.card_container?.children?.length - 1;
  }

  public next_card() {
    if (!this.isBrowser) { return; }

    this.showProgress$.next(false);
    this.setDefaultStates();

    this.selectedIndex < this.carouselConfig.length ? this.selectedIndex ++ : this.selectedIndex = 1;
    if (!this.animation_in_progress) {
      this.animation_in_progress = true;
      this.card_container.append(this.card_container.children[0]);
      this.nav_container.append(this.nav_container.children[0]);
      this.animation_in_progress = false;
    }

    this.startTimer();
  }

  public previous_card() {
    if (!this.isBrowser) { return; }

    this.showProgress$.next(false);
    this.setDefaultStates();

    this.selectedIndex > 1 ? this.selectedIndex-- : this.selectedIndex = this.carouselConfig.length;
    if (!this.animation_in_progress) {
      this.animation_in_progress = true;
      this.card_container.insertBefore(this.card_container.children[this.total_number_of_cards_by_index], this.card_container.children[0]);
      this.nav_container.insertBefore(this.nav_container.children[this.total_number_of_cards_by_index], this.nav_container.children[0]);
      this.animation_in_progress = false;
    }

    this.startTimer();
  }

  public selectSlide(slideIndex) {
    if (this.selectedIndex === slideIndex) { return; }
    if (this.selectedIndex < slideIndex) {
      const step = (slideIndex - this.selectedIndex) - 1;
      for (let i = 0; i <= step; i++) { this.next_card(); }
    } else  {
      const step = (this.selectedIndex - slideIndex) - 1;
      for (let i = 0; i <= step; i++) { this.previous_card(); }
    }
  }

  public startTimer(): void {
    if (!this.isBrowser) { return; }
    clearInterval(this.countdownTimer);
    let sliderTimer = 70;

    this.countdownTimer = setInterval( () => {
      if (!this.isSliderScrolledIntoView && !this.animationStateIsPaused$.value) {
        this.currentTimer = 0;
      }

      if (!this.animationStateIsPaused$.value && this.isSliderScrolledIntoView) {
        sliderTimer -= 1;
      }

      if (!this.showProgress$.value && this.isSliderScrolledIntoView) {
        setTimeout(() => {
          this.showProgress$.next(true);
        }, 10);
      }

      this.currentTimer = sliderTimer;

      if (sliderTimer <= 0) {
        clearInterval(this.countdownTimer);
        this.next_card();
        this.showProgress$.next(false);
      }
    }, 100);
  }

  public pause(): void {
    if (!this.isBrowser) { return; }
    if (!this.animationStateIsPaused$.value) {
      this.animationStateIsPaused$.next(true);
      clearInterval(this.currentTimer);
    } else {
      this.animationStateIsPaused$.next(false);
    }
  }

  public setDefaultStates(): void {
    this.animationStateIsPaused$.next(false);
    this.showProgress$.next(false);
    this.currentTimer = 0;
  }

  public handleTouchStart(evt): void {
    if (!this.isBrowser) { return; }
    const firstTouch = this.getTouches(evt)[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  }

  public handleTouchMove(evt): void {
    if (!this.isBrowser) { return; }
    if ( !this.xDown || !this.yDown ) { return; }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    const xDiff = this.xDown - xUp;
    const yDiff = this.yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
      xDiff > 0  ? this.next_card() : this.previous_card();
    }

    this.xDown = null;
    this.yDown = null;
  }

  public getTouches(evt): TouchList {
    return evt.touches;
  }

  ngAfterViewInit() {
    this.initCarousel();
    this.startTimer();
  }
}
