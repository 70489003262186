import { ButtonColor, ButtonModel } from '../../../ui-components/button/button.model';

export const BUTTONS: Array<ButtonModel> = [
  {
    title: 'ACTION_CARD.CONTACT_US',
    iconName: 'email',
    styles: {
      bgColor: ButtonColor.BLACK,
      textColor: ButtonColor.WHITE,
      height: 64,
    },
  },
  {
    title: 'ACTION_CARD.REQUEST_ASSISTANCE',
    styles: {
      bgColor: ButtonColor.WHITE,
      textColor: ButtonColor.GREY,
      height: 64,
    },
  },
  {
    title: 'BUTTON.GET_STARTED_FOR_FREE',
    iconName: 'rocket',
    styles: {
      bgColor: ButtonColor.WHITE,
      textColor: ButtonColor.BLUE,
      height: 64,
      reverse: true,
    },
  },
  {
    title: 'HOME.TRY_FOR_FREE',
    iconName: 'rocket',
    styles: {
      height: 64,
      bgColor: ButtonColor.BLUE,
      textColor: ButtonColor.WHITE,
    }
  },
  {
    title: 'REQUEST_DEMO.REQUEST_DEMO',
    iconName: 'phone-call',
    styles: {
      height: 64,
      bgColor: ButtonColor.WHITE,
      textColor: ButtonColor.GREY
    }
  },
];
