import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CaptchaModel } from './models/form.models';
import { ResponseModel } from './models/response.model';
@Injectable({
  providedIn: 'root',
})
export class CaptchaCheckService {
  private apiUrl = 'https://www.google.com/recaptcha/api/siteverify';
  private siteKey = '6LfvRK4ZAAAAALUuLU-88GCI-ptm7fNIoeFICK8s';

  constructor(private http: HttpClient) {}

  public checkCaptchaResponse (token: string): Observable<any> {
    const captchaData: CaptchaModel = {
      secret: this.siteKey,
      response: token,
    };

    if (!!token) {
      return this.sendCaptchaRequest(captchaData);
    }
    return of(false);
  }

  public sendCaptchaRequest ( captchaData: CaptchaModel): Observable<CaptchaModel> {
    return this.http
      .post<ResponseModel<CaptchaModel>>(this.apiUrl, captchaData)
      .pipe(
        map((responce: ResponseModel<CaptchaModel>) => {
          return responce.data;
        }),
        catchError(this.handleError)
      );
  }


  private handleError(error: HttpErrorResponse) {
    return throwError('Something bad happened; please try again later.');
  }
}
