<div class="page">
  <div class="article-container">
    <div class="article-anchor-container">
      <div *ngFor="let anchorItemWidth of anchorSkeletonItems"
           [style.width.px]="anchorItemWidth"
           class="anchor-item-skeleton skeleton"
      >
      </div>
    </div>

    <div class="article-body">
      <div class="type-skeleton skeleton"></div>

      <div class="title-skeleton skeleton"></div>
      <div class="title-skeleton skeleton"></div>
      <div class="title-skeleton skeleton"></div>

      <div class="img-container-skeleton skeleton"></div>

      <div class="title-skeleton skeleton"></div>

      <div *ngFor="let descrSkeletonWidth of descrSkeletonItems" [style.maxWidth.px]="descrSkeletonWidth" class="descr-skeleton skeleton"></div>


    </div>

  </div>
</div>
