import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InfoRectangleComponent } from './info-rectangle.component';

@NgModule({
  declarations: [InfoRectangleComponent],
  imports: [CommonModule, TranslateModule],
  exports: [InfoRectangleComponent],
})
export class InfoRectangleModule {}
