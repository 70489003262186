import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderCarouselComponent } from './slider-carousel.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../../pipes/pipes.module';



@NgModule({
  declarations: [
    SliderCarouselComponent
  ],
  exports: [
    SliderCarouselComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        PipesModule
    ]
})
export class SliderCarouselModule { }
