import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'currencyFormat'})
export class CurrencyFormat implements PipeTransform {
  transform(value: number|string,
    currencySign: string = '€ ',
    decimalLength: number = 2,
    chunkDelimiter: string = '.',
    decimalDelimiter: string = ',',
    chunkLength: number = 3,
    realValue: boolean = false, // display original value from response with delimiter
  ): string {

    if (!value && value !== 0) { return '-'; }

    value = (isFloat(value) || realValue) ? Number(value) : Number(value) / 100;

    // if (realValue && isFloat(value)) {
    //   decimalLength = decimalCount(value);
    // }

    const result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    const num = value.toFixed(Math.max(0, ~~decimalLength));

    const replacedDelimiter = (decimalDelimiter ? num.replace('.', decimalDelimiter) : num);

    const replacedChunk = replacedDelimiter.replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);

    return currencySign + replacedChunk;
  }
}

function isFloat(n: number | string): boolean {
  return String(n).includes('.');
}

function decimalCount (number) {
  const numberAsString = number.toString();
  return numberAsString.split('.')[1].length;
}
