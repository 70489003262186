<div class="modal-wrapper">
  <div class="header cursor-pointer">
    <div class="btn-container" (click)="closeModal()">
      <i class="icon icon-close"></i>
    </div>
  </div>
  <div class="content d-flex flex-column">
    <div class="modal-img">
      <img src="assets/img/accepted-request.png" alt="modal's image" />
    </div>
    <h4 class="modal-title">{{ "MODAL.SUBMITED" | translate }} {{formService?.formData?.firstName}}!</h4>
    <p class="modal-descr mb-32" [innerText]="'MODAL.CHECK_INBOX' | translate"></p>

    <h5 class="modal-descr-additional title" [innerText]="'MODAL.CANNOT_FIND_OUR_EMAIL' | translate"></h5>
    <p class="modal-descr-additional" [innerText]="'MODAL.CANNOT_FIND_OUR_EMAIL_DESCR' | translate"></p>

    <rnpl-button
      class="continue-btn"
      [title]="resendEmailButton.title"
      [iconName]="resendEmailButton.iconName"
      [styles]="resendEmailButton.styles"
      (click)="resendEmail()"
    >
    </rnpl-button>
  </div>
</div>
