import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewUsBlockComponent } from './review-us-block.component';
import {TranslateModule} from '@ngx-translate/core';
import {TrustboxModule} from '../trustbox/trustbox.module';
import {PipesModule} from '../../pipes/pipes.module';



@NgModule({
    declarations: [
        ReviewUsBlockComponent
    ],
    exports: [
        ReviewUsBlockComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        TrustboxModule,
        PipesModule
    ]
})
export class ReviewUsModule { }
