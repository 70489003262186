<div class="modal-wrapper" #modal>
  <div class="header">
    <div
      class="btn-container cursor-pointer"
      (click)="activeModal.dismiss('Close')">
      <i class="icon icon-close"></i>
    </div>
  </div>
  <h2 class="modal-title">{{ "MODAL.CONTACT_US" | translate }}</h2>

  <div class="form-container">
    <form>
<!--      <div class="row">-->
<!--        <div class="col-12">-->
<!--          <rnpl-select-->
<!--            *ngIf="activeComponentType === formNames.QUESTION"-->
<!--            [selectItems]="selectActionQuestion.selectItems"-->
<!--            [defaultSelectionIndex]="selectActionQuestion.defaultSelectionIndex"-->
<!--            [labelText]="selectActionQuestion.labelText"-->
<!--            [styles]="selectActionQuestion.styles"-->
<!--            (changeSelect)="changeSelection($event)"-->
<!--            [searchable]="false">-->
<!--          </rnpl-select>-->
<!--          <rnpl-select-->
<!--            *ngIf="activeComponentType === formNames.DEVELOPMENT"-->
<!--            [selectItems]="selectActionRequest.selectItems"-->
<!--            [defaultSelectionIndex]="selectActionRequest.defaultSelectionIndex"-->
<!--            [labelText]="selectActionRequest.labelText"-->
<!--            [styles]="selectActionRequest.styles"-->
<!--            (changeSelect)="changeSelection($event)"-->
<!--            [searchable]="false">-->
<!--          </rnpl-select>-->
<!--        </div>-->
<!--      </div>-->

      <div class="row">
        <div class="col-6">
          <rnpl-input
            [label]="first.label"
            [placeholder]="first.placeholder"
            [type]="first.type"
            [formName]="formNames.QUESTION"
            [control]="firstName">
          </rnpl-input>
        </div>

        <div class="col-6">
          <rnpl-input
            [label]="last.label"
            [placeholder]="last.placeholder"
            [type]="last.type"
            [formName]="formNames.QUESTION"
            [control]="lastName">
          </rnpl-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <rnpl-input
            [label]="eMail.label"
            [placeholder]="eMail.placeholder"
            [type]="eMail.type"
            [formName]="formNames.QUESTION"
            [control]="email">
          </rnpl-input>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <rnpl-input
            [label]="tel.label"
            [placeholder]="tel.placeholder"
            [type]="tel.type"
            [isOptional]="tel.isOptional"
            [formName]="formNames.QUESTION"
            [control]="phone">
          </rnpl-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <rnpl-input
            [label]="compName.label"
            [placeholder]="compName.placeholder"
            [type]="compName.type"
            [isOptional]="activeComponentType !== formNames.DEVELOPMENT"
            [formName]="formNames.QUESTION"
            [control]="companyName">
          </rnpl-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <rnpl-textarea
            [label]="textarea.label"
            [placeholder]="textarea.placeholder"
            [formName]="formNames.QUESTION"
            [control]="yourQuestion">
          </rnpl-textarea>
        </div>
      </div>
    </form>

    <div class="button">
      <rnpl-button
        [title]="submitButton.title"
        [styles]="submitButton.styles"
        [iconName]="submitButton.iconName"
        (click)="preSubmittingFormHandler()">
      </rnpl-button>
    </div>

    <div class="bottom-descr-container">
      <p class="descr">
        {{ "MODAL.YOU_CAN_ALSO_CONTACT" | translate }}<br>
        <a class="link" href="mailto:support@it-work.at">support@it-work.at</a>
      </p>
    </div>

    <p class="recaptcha-info">
      {{ "CAPTCHA_INFO.SITE_PROTECTED" | translate: {buttonName: 'FORM.SUBMIT' | translate} }}
      <a target="_blank" href="https://policies.google.com/privacy" rel="nofollow">{{ "CAPTCHA_INFO.PRIVACY" | translate}}</a> {{ "CAPTCHA_INFO.AND" | translate}}
      <a target="_blank" href="https://policies.google.com/terms" rel="nofollow">{{ "CAPTCHA_INFO.TERMS" | translate}}</a>.
    </p>
  </div>
</div>
