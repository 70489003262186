import {AfterViewInit, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-trustbox',
  templateUrl: './trustbox.component.html',
  styleUrls: ['./trustbox.component.scss']
})
export class TrustboxComponent implements OnInit, AfterViewInit {

  @Input() public isDarkTheme: boolean = false;
  @Input() public height: number = 24;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const windowObj: any = window.window;
    const trustboxRef = document.getElementById('trustbox');

    windowObj?.Trustpilot?.loadFromElement(trustboxRef);
  }


}
