import { Component, OnInit } from '@angular/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {START_STEPS} from '../../pages/home/content-data/home-page.config';
import {LocaleService} from '../../locale/locale.service';

@Component({
  selector: 'app-start-steps-block',
  templateUrl: './start-steps-block.component.html',
  styleUrls: ['./start-steps-block.component.scss']
})
export class StartStepsBlockComponent implements OnInit {

  public isDisplayNarrowScreen;
  public startSteps = START_STEPS;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public localeService: LocaleService,
  ) {
    // detect screen size changes
    this.breakpointObserver.observe([
      '(max-width: 481px)'
    ]).subscribe((result: BreakpointState) => {
      this.isDisplayNarrowScreen = result.matches;
    });
  }

  ngOnInit(): void {
  }

}
