import {Component, OnDestroy, OnInit} from '@angular/core';
import { InputType } from '../../input/input.model';
import { FormNames } from '../../../services/models/form.models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { RnplModalService } from '../../../services/rnpl-modal.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FormService } from '../../../services/forms.service';
import { ButtonColor, ButtonModel } from '../../button/button.model';

@Component({
  selector: 'app-request-demo-modal',
  templateUrl: './request-demo-modal.component.html',
  styleUrls: ['./request-demo-modal.component.scss']
})
export class RequestDemoModalComponent implements OnInit, OnDestroy {

  public inputType: typeof InputType = InputType;
  public formNames: typeof FormNames = FormNames;

  private ngbModalOptionsErrorSuccess: NgbModalOptions = {
    backdrop : true,
    keyboard : false,
  };

  public modalData: {[jey: string]: string} = {
    title: '',
    descr: ''
  };

  public submitButton: ButtonModel = {
    title: 'REQUEST_DEMO.REQUEST_DEMO',
    iconName: 'phone-call',
    styles: {
      bgColor: ButtonColor.BLUE,
      textColor: ButtonColor.WHITE,
      height: 64,
      widthFull: true,
    },
  };

  public requestDemoForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl(''),
    // reCaptcha: new FormControl(''),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private recaptchaV3Service: ReCaptchaV3Service,
    private formService: FormService,
    public modalServices: RnplModalService,
    private modal: NgbModal,
    private http: FormService,
  ) { }

  ngOnInit(): void {
    this.modalData = this.modalServices.getModalData();
    this.formService.checkOutValidForm();
  }

  public preSubmittingFormHandler(): void {
    this.formService.checkValidForm();
    this.formService.getActiveForm(FormNames.DEMO);
    this.submitForm();

    // this.recaptchaV3Service.execute('submitForm')
    //   .subscribe((token) => {
    //     this.captchaToken.setValue(token);
    //     this.submitForm();
    //   });
  }

  public submitForm() {
    if (this.requestDemoForm.valid) {
      this.submitFormModal(
        this.requestDemoForm.getRawValue(),
        FormNames.DEMO,
        this.activeModal
      );
    }
  }

  public submitFormModal(formData, formName, activeModalRef?) {
    this.http.sendRequest(formData, formName).subscribe(
      () => {
        if (activeModalRef) {
          activeModalRef.close('submited');
        } else {
          this.modal.dismissAll();
        }
        this.modalServices.openAcceptedModal(null, this.ngbModalOptionsErrorSuccess, formName);
      },
      (error) => {
        if (error?.error?.data) {
          for (const controlName in error.error.data) {
            if (error.error.data.hasOwnProperty(controlName)) {
              if (this.requestDemoForm.get(controlName)) {
                this.requestDemoForm.get(controlName).setErrors({ error: error.error.data[controlName] });
              }
            } else {
              if (activeModalRef) {
                activeModalRef.close('submited');
              } else {
                this.modal.dismissAll();
              }
              this.modalServices.openErrorModal(formData, formName, activeModalRef);
            }
          }
        } else {
          if (activeModalRef) {
            activeModalRef.close('submited');
          } else {
            this.modal.dismissAll();
          }
          this.modalServices.openErrorModal(formData, formName, activeModalRef);
        }
      }
    );
  }

  ngOnDestroy() {
    this.formService.checkOutValidForm();
  }

  get phone(): FormControl { return this.requestDemoForm.get('phone') as FormControl; }
  get email(): FormControl { return this.requestDemoForm.get('email') as FormControl; }
  get name(): FormControl { return this.requestDemoForm.get('name') as FormControl; }
  // get captchaToken(): FormControl { return this.requestDemo.get('reCaptcha') as FormControl; }

}
