<div class="privacy-agreement">

  <div class="privacy-agreement-head">

    <div class="privacy-agreement-icon">
      <img src="assets/img/runple-logo-white.svg" alt="">
    </div>

    <div class="privacy-agreement-icon">
      <i class="icon icon-shield"></i>
    </div>

    {{ 'COOKIES.WE_USE_COOKIES' | translate }}
  </div>

  <div class="privacy-agreement-body">
    {{ 'COOKIES.COOKIES_DESCR' | translate }}
<!--    <a class="link" [routerLink]="['privacy-policy']" [routerLinkActive]="['disabled']">{{ 'NAV.PRIVACY_POLICY' | translate }}</a>-->
<!--    <a class="link" [routerLink]="['datenschutzerklaerung']" [routerLinkActive]="['disabled']">{{ 'NAV.PRIVACY_POLICY' | translate }}</a>-->
  </div>

  <div class="buttons-container">
    <rnpl-button
      [title]="'BUTTON.LEARN_MORE'"
      [styles]="buttonConfigDark"
      [typeSmall]="true"
      (click)="goToCookiesPage()">
    </rnpl-button>

    <rnpl-button
      [title]="'BUTTON.ACCEPT'"
      [styles]="buttonConfig"
      [typeSmall]="true"
      (click)="cookiesAgree()">
    </rnpl-button>
  </div>

</div>
