<rnpl-header *ngIf="!isExternalPage"></rnpl-header>
<router-outlet></router-outlet>

<app-request-demo *ngIf="!isExternalPage && !isGetStartedPage"></app-request-demo>

<rnpl-footer *ngIf="!isExternalPage"></rnpl-footer>

<rnpl-privacy-agreement
  *ngIf="!isCookiesAgree && !isExternalPage"
  (isCookiesDecline)="isCookiesAgree = true"
  (isCookiesAgree)="policyAgreement()">
</rnpl-privacy-agreement>
